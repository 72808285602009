import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
// config
import config from '../../config';
// util
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
// components
import { ModalInMobile, Button, NamedLink } from '../../components';
import { BookingDatesForm, ValuationForm, BuyNowForm } from '../../forms';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';
import SectionHostMaybe from '../../containers/ListingPage/SectionHostMaybe';
// css
import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const getFormatId = (
  listingTypeMain = '',
  isOwnListing = false,
  lmctUnverified = false,
  buyerStatus = '',
  bidAccepted = false,
  id = '',
  currentUserId = ''
) => {
  let formatID = null;
  switch (listingTypeMain) {
    case 'bid':
      if (isOwnListing) {
        formatID = 'BookingDatesForm.ownListing';
      } else if (lmctUnverified) {
        formatID = 'BookingDatesForm.lmctUnverified';
      } else if (bidAccepted) {
        formatID = 'BookingDatesForm.youCantBidAnymore';
      } else if (buyerStatus) {
        formatID = 'BookingDatesForm.youWontBeChargedInfoBuyNowSold';
      } else if (!isOwnListing && buyerStatus && buyerStatus != 3) {
        if (currentUserId === id) {
          formatID = 'BookingDatesForm.paymentPending';
        } else {
          formatID = 'BookingDatesForm.youWontBeChargedInfoBuyNowSold';
        }
      }
      break;
    case 'valuation':
      if (isOwnListing) {
        formatID = 'BookingDatesForm.ownListingValuation';
      } else if (lmctUnverified) {
        formatID = 'BookingDatesForm.lmctUnverifiedValuation';
      }
      break;
    default:
  }
  return formatID;
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    buyNowTitle,
    hostingTitle,
    authorDisplayName,
    authorId,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    currentUser,
    highestBid,
    placedBy,
    geolocation,
    publicData,
    listingId,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    priceValue,
    formattedInstantPrice,
    listingTypeMain,
    onSubmitEnquiry,
    sendEnquiryError,
    sendEnquiryInProgress,
    isAuthenticated,
    buyNowData,
    instantPrice,
    match,
    transaction,
    hasDefaultPaymentMethod,
    emailUnverified,
    stripeAccountMissing,
    showVerificationNeeded,
    exisitingTxDeclined,
    onLoadData,
    hasInstantPrice,
  } = props;

  const price = listing.attributes.price;
  const metadata = listing.attributes.metadata;
  const bidAccepted =
    metadata && metadata.biddingStatus && metadata.biddingStatus == 2 ? true : false;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed && isAuthenticated;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const lmctUnverified =
    !!currentUser &&
    !!currentUser.id &&
    !!currentUser.attributes.profile &&
    !!currentUser.attributes.profile.publicData &&
    !currentUser.attributes.profile.publicData.lmctVerified;
  const user = currentUser ? ensureCurrentUser(currentUser) : {};
  const currentUserId = user.id && user.id.uuid ? user.id.uuid : '';
  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const { buyerStatus, id } = buyNowData ?? {};
  const formatIDMain = getFormatId(
    listingTypeMain,
    isOwnListing,
    lmctUnverified,
    buyerStatus,
    bidAccepted,
    id,
    currentUserId
  );
  const renderMobileButton = (
    listingTypeMain,
    isOwnListing,
    isClosed,
    history,
    location,
    lmctUnverified,
    buyerStatus,
    bidAccepted
  ) => {
    let showBtn = false,
      formatId = '';
    switch (listingTypeMain) {
      case 'bid':
        formatId = 'BookingPanel.ctaButtonMessageBid';
        if (!isOwnListing && !lmctUnverified && !buyerStatus && !bidAccepted) {
          showBtn = true;
        } else if (
          (isOwnListing && buyerStatus && buyerStatus === 3) ||
          (!isOwnListing && buyerStatus && buyerStatus === 1 && currentUserId === id)
        ) {
          showBtn = true;
          formatId = 'BookingDatesForm.pay';
        }
        break;
      case 'valuation':
        formatId = 'BookingPanel.ctaButtonMessageValuate';
        showBtn = !isOwnListing && !lmctUnverified;
        break;
      default:
    }

    if (showBtn && formatId) {
      return (
        <Button
          rootClassName={css.bookButton}
          onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
        >
          <FormattedMessage id={formatId} />
        </Button>
      );
    } else {
      return null;
    }
  };
  return (
    <div className={classes}>
      {listingTypeMain ? (
        <>
          {showBookingDatesForm ? (
            <ModalInMobile
              containerClassName={css.modalContainer}
              id="BookingDatesFormInModal"
              isModalOpenOnMobile={isBook}
              onClose={() => closeBookModal(history, location)}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              {listingTypeMain === 'bid' ? (
                <>
                  {hasInstantPrice ? (
                    <>
                      <div className={css.modalHeading}>
                        <h1 className={css.title}>{buyNowTitle}</h1>
                      </div>
                      <div className={css.bookingHeading}>
                        <h2 className={titleClasses}>{buyNowTitle}</h2>
                      </div>
                      <BuyNowForm
                        transaction={transaction}
                        className={css.bookingForm}
                        formId="BuyNowPanel"
                        submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                        onSubmit={onSubmit}
                        redirectToCheckout={props.redirectToCheckout}
                        listingId={listing.id}
                        isOwnListing={isOwnListing}
                        onFetchTransactionLineItems={onFetchTransactionLineItems}
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        currentUser={currentUser}
                        placedBy={placedBy}
                        formattedPrice={formattedPrice}
                        price={priceValue}
                        formattedInstantPrice={formattedInstantPrice}
                        title={hostingTitle}
                        metadata={metadata}
                        buyNowData={buyNowData}
                        instantPrice={instantPrice}
                        lmctUnverified={lmctUnverified}
                        match={match}
                        metadata={metadata}
                        hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                        emailUnverified={emailUnverified}
                        stripeAccountMissing={stripeAccountMissing}
                        showVerificationNeeded={showVerificationNeeded}
                        exisitingTxDeclined={exisitingTxDeclined}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
              <div className={css.modalHeading}>
                <h1 className={css.title}>{title}</h1>
                {/* <div className={css.author}>
              <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
            </div> */}
              </div>

              <div className={css.bookingHeading}>
                <h2 className={titleClasses}>{title}</h2>
              </div>
              {listingTypeMain === 'bid' ? (
                <BookingDatesForm
                  className={css.bookingForm}
                  formId="BookingPanel"
                  submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                  unitType={unitType}
                  onSubmit={onSubmit}
                  price={price}
                  listingId={listing.id}
                  isOwnListing={isOwnListing}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  authorId={authorId}
                  hostingTitle={hostingTitle}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  buyNowData={buyNowData}
                  placedBy={placedBy}
                  priceValue={priceValue}
                  match={match}
                  metadata={metadata}
                  highestBid={highestBid}
                  lmctUnverified={lmctUnverified}
                  hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                  emailUnverified={emailUnverified}
                  stripeAccountMissing={stripeAccountMissing}
                  showVerificationNeeded={showVerificationNeeded}
                  exisitingTxDeclined={exisitingTxDeclined}
                  onLoadData={onLoadData}
                />
              ) : listingTypeMain === 'valuation' ? (
                <ValuationForm
                  className={css.bookingForm}
                  formId="ValuationPanel"
                  submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                  onSubmit={onSubmit}
                  listingId={listing.id}
                  isOwnListing={isOwnListing}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  match={match}
                  lmctUnverified={lmctUnverified}
                  metadata={metadata}
                  hasDefaultPaymentMethod={hasDefaultPaymentMethod}
                  emailUnverified={emailUnverified}
                  stripeAccountMissing={stripeAccountMissing}
                  showVerificationNeeded={showVerificationNeeded}
                  exisitingTxDeclined={exisitingTxDeclined}
                />
              ) : null}
            </ModalInMobile>
          ) : null}
          <div className={css.openBookingForm}>
            {showBookingDatesForm &&
            listingTypeMain &&
            listingTypeMain != 'valuation' &&
            !lmctUnverified &&
            !isOwnListing ? (
              <div className={css.priceContainer}>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
              </div>
            ) : null}
            {showBookingDatesForm ? (
              renderMobileButton(
                listingTypeMain,
                isOwnListing,
                isClosed,
                history,
                location,
                lmctUnverified,
                buyerStatus,
                bidAccepted
              )
            ) : isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="BookingPanel.closedListingButtonText" />
              </div>
            ) : null}
            {showBookingDatesForm && formatIDMain ? (
              <p className={css.smallPrint}>
                <FormattedMessage id={formatIDMain} />
              </p>
            ) : null}
            <NamedLink
              className={css.seeValuationListLink}
              name="CommonList"
              params={{
                id: match.params.id,
                slug: match.params.slug,
                userid: match.params.userid,
                listType: listingTypeMain === 'valuation' ? 'valuations' : 'bids',
              }}
            >
              <span>
                <FormattedMessage
                  id={
                    listingTypeMain === 'valuation'
                      ? 'BookingPanel.seeValuationsList'
                      : 'BookingPanel.seeBiddingList'
                  }
                />
              </span>
            </NamedLink>
          </div>
        </>
      ) : (
        `Something went wrong. We couldn't get the listing type.`
      )}

      <SectionMapMaybe geolocation={geolocation} publicData={publicData} listingId={listingId} />

      <SectionHostMaybe
        title={hostingTitle}
        listing={listing}
        authorDisplayName={authorDisplayName}
        onContactUser={onContactUser}
        isEnquiryModalOpen={isEnquiryModalOpen}
        onCloseEnquiryModal={onCloseEnquiryModal}
        sendEnquiryError={sendEnquiryError}
        sendEnquiryInProgress={sendEnquiryInProgress}
        onSubmitEnquiry={onSubmitEnquiry}
        currentUser={currentUser}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
  isAuthenticated: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  hostingTitle: oneOfType([node, string]).isRequired,
  authorDisplayName: oneOfType([node, string]).isRequired,
  authorId: string.isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  isEnquiryModalOpen: bool.isRequired,
  onContactUser: func.isRequired,
  onSubmitEnquiry: func.isRequired,
  onCloseEnquiryModal: func.isRequired,
  sendEnquiryError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  isAuthenticated: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  hasDefaultPaymentMethod: bool,
  emailUnverified: bool,
  stripeAccountMissing: bool,
  showVerificationNeeded: bool,
  exisitingTxDeclined: bool,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
