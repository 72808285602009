import React from 'react';
import classNames from 'classnames';
// util
import { ensureCurrentUser } from '../../util/data';
// component
import { AvatarMedium, ResponsiveImage } from '../../components';
import { HEADING_ACCEPTED, HEADING_DELIVERED } from './PanelHeading';
// css
import css from './TransactionPanel.module.css';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    listingTitle,
    image,
    provider,
    isCustomer,
    stateData,
  } = props;
  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);
  const headingState = stateData && stateData.headingState;
  const anonymousUser = ensureCurrentUser(null);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={listingTitle}
            image={image}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
        </div>
      </div>
      {isCustomer ? (
        <div className={avatarWrapperClassName || css.avatarWrapper}>
          {(headingState === HEADING_ACCEPTED) ||
          headingState === HEADING_DELIVERED ? (
            <AvatarMedium user={provider} />
          ) : <AvatarMedium user={anonymousUser} />}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DetailCardImage;
