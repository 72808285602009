import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput } from "../../components";
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { Form } from '../../components';
import css from './DistanceFilterForm.module.css';

// DistanceFilterForm component
const DistanceFilterFormComponent = props => {
  const { onChange, onSubmit, onCancel, onClear, ...rest } = props;

  const handleSubmit = values => {
    const { fromDistance, toDistance, ...restValues } = values;
    return onSubmit({
      fromDistance: fromDistance === '' ? '' : fromDistance,
      toDistance: toDistance === '' ? '' : toDistance,
      ...restValues,
    });
  };

  const formCallbacks = { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
        } = formRenderProps;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              <span className={css.label}>
                <FormattedMessage id="PriceFilterForm.label" />
              </span>
              <div className={css.inputsWrapper}>
                <FieldTextInput
                  id={`${id}.fromDistance`}
                  name="fromDistance"
                  className={css.distance}
                  type="number"
                  min="0"
                  label={"From"}
                  placeholder={"From KM"}
                />
                <FieldTextInput
                  id={`${id}.toDistance`}
                  name="toDistance"
                  className={css.distance}
                  type="number"
                  min="0"
                  label={"To"}
                  placeholder={"To KM"}
                />
              </div>
            </div>

            <div className={css.buttonsWrapper}>
              <button className={css.clearButton} type="button" onClick={onClear}>
                {clear}
              </button>
              <div className={css.btns_wrapper}>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

DistanceFilterFormComponent.defaultProps = {
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

DistanceFilterFormComponent.propTypes = {
  id: string.isRequired,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,

  // form injectIntl
  intl: intlShape.isRequired,
};

const DistanceFilterForm = injectIntl(DistanceFilterFormComponent);

export default DistanceFilterForm;
