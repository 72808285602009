import React, { Component, useEffect } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError } from '../../components';
import StarRatings from 'react-star-ratings';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import css from './EditListingConditionForm.module.css';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ACCEPT_IMAGES = 'image/*';

export class EditListingConditionFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, showToast: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
    this.handleClose = this.handleClose.bind(this);
  }

  onImageUploadHandler(file, type) {
    if (file) {
      let fileName = file.name.split('.').slice(0,-1).join('.') || file.name + ""
      let fileExtention = `.${file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)}`
      let fileNameWithoutSpecialChars = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        this.setState({ imageUploadRequested: true });
        this.props
          .onImageUpload({ id: `${fileNameWithoutSpecialChars.concat(fileExtention)}_${Date.now()}_${type}`, file })
          .then(() => {
            this.setState({ imageUploadRequested: false });
          })
          .catch(() => {
            this.setState({ imageUploadRequested: false });
          });
      }
    }
  }

  handleClose = () => {
    this.setState({ showToast: false });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{
          frontWheelImage: this.props.frontWheelImage,
          rearWheelImage: this.props.rearWheelImage,
          panelWorkImage: this.props.panelWorkImage,
          paintWorkImage: this.props.paintWorkImage,
          interiorImage: this.props.interiorImage,
          windscreenImage: this.props.windscreenImage,
          mechanicalImage: this.props.mechanicalImage,
        }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            frontWheelImage,
            rearWheelImage,
            panelWorkImage,
            paintWorkImage,
            interiorImage,
            windscreenImage,
            mechanicalImage,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const {
            publishListingError,
            showListingsError,
            updateListingError,
            uploadImageError,
          } = fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          // const submittedOnce = this.submittedImages.length > 0;
          // // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          // const arrayOfImgIds = imgs =>
          //   imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          // const imageIdsFromProps = arrayOfImgIds(frontWheelImage);
          // const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          // const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          // const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = updated || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready;

          const classes = classNames(css.root, className);

          useEffect(() => {
            if (updated) {
              this.setState({ showToast: true });
            }
          }, [updated]);

          return (
            <>
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedImages = [
                    ...frontWheelImage,
                    ...rearWheelImage,
                    ...panelWorkImage,
                    ...paintWorkImage,
                    ...interiorImage,
                    ...windscreenImage,
                    ...mechanicalImage,
                  ];
                  handleSubmit(e);
                }}
              >
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                  </p>
                ) : null}
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Front Wheel</h3>
                  <StarRatings
                    rating={this.props.ratings.frontWheel}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="frontWheel"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={frontWheelImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {frontWheelImage && frontWheelImage.length < 2 ? (
                    <Field
                      id="frontWheelImage"
                      name="frontWheelImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`frontWheelImage`, file);
                          form.blur(`frontWheelImage`);
                          onImageUploadHandler(file, 'frontWheelImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="frontWheelImage"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Rear Wheel</h3>
                  <StarRatings
                    rating={this.props.ratings.rearWheel}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="rearWheel"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={rearWheelImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {rearWheelImage && rearWheelImage.length < 2 ? (
                    <Field
                      id="rearWheelImage"
                      name="rearWheelImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`rearWheelImage`, file);
                          form.blur(`rearWheelImage`);
                          onImageUploadHandler(file, 'rearWheelImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="rearWheelImage"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Paint and Panel work</h3>
                  <StarRatings
                    rating={this.props.ratings.panelWork}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="panelWork"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={panelWorkImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {panelWorkImage && panelWorkImage.length < 2 ? (
                    <Field
                      id="panelWorkImage"
                      name="panelWorkImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`panelWorkImage`, file);
                          form.blur(`panelWorkImage`);
                          onImageUploadHandler(file, 'panelWorkImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="panelWorkImage"
                    type="hidden"
                    // validate={composeValidators(
                    //   nonEmptyArray(imageRequiredMessage)
                    // )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Instrument cluster</h3>
                  <StarRatings
                    rating={this.props.ratings.paintWork}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="paintWork"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={paintWorkImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {paintWorkImage && paintWorkImage.length < 2 ? (
                    <Field
                      id="paintWorkImage"
                      name="paintWorkImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`paintWorkImage`, file);
                          form.blur(`paintWorkImage`);
                          onImageUploadHandler(file, 'paintWorkImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="paintWorkImage"
                    type="hidden"
                    // validate={composeValidators(
                    //   nonEmptyArray(imageRequiredMessage)
                    // )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Interior</h3>
                  <StarRatings
                    rating={this.props.ratings.interior}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="interior"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={interiorImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {interiorImage && interiorImage.length < 2 ? (
                    <Field
                      id="interiorImage"
                      name="interiorImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`interiorImage`, file);
                          form.blur(`interiorImage`);
                          onImageUploadHandler(file, 'interiorImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="interiorImage"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Wind Screen</h3>
                  <StarRatings
                    rating={this.props.ratings.windScreen}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="windScreen"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={windscreenImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {windscreenImage && windscreenImage.length < 2 ? (
                    <Field
                      id="windscreenImage"
                      name="windscreenImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`windscreenImage`, file);
                          form.blur(`windscreenImage`);
                          onImageUploadHandler(file, 'windscreenImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="windscreenImage"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                <div className={css.starRating}>
                  <h3 className={css.conditionHeading}>Engine Bay</h3>
                  <StarRatings
                    rating={this.props.ratings.mechanical}
                    changeRating={this.props.onRatingChange}
                    starRatedColor="rgb(50,155,223)"
                    starHoverColor="rgb(50,155,223)"
                    starDimension="40px"
                    starSpacing="2px"
                    numberOfStars={5}
                    name="mechanical"
                  />
                </div>
                <AddImages
                  className={css.imagesField}
                  images={mechanicalImage}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  {mechanicalImage && mechanicalImage.length < 2 ? (
                    <Field
                      id="mechanicalImage"
                      name="mechanicalImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      label={chooseImageText}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          label,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`mechanicalImage`, file);
                          form.blur(`mechanicalImage`);
                          onImageUploadHandler(file, 'mechanicalImage');
                        };
                        const inputProps = {
                          accept,
                          id: name,
                          name,
                          onChange,
                          type,
                        };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input
                                  {...inputProps}
                                  className={css.addImageInput}
                                />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  ) : null}
                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="mechanicalImage"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                {uploadImageFailed}
                {publishListingFailed}
                {showListingFailed}

                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </Form>
              <Snackbar
                open={this.state.showToast}
                autoHideDuration={3000}
                onClose={this.handleClose}
              >
                <Alert onClose={this.handleClose} severity="success">
                  Updated!
                </Alert>
              </Snackbar>
            </>
          );
        }}
      />
    );
  }
}

EditListingConditionFormComponent.defaultProps = {
  fetchErrors: null,
  frontWheelImage: [],
  rearWheelImage: [],
  panelWorkImage: [],
  paintWorkImage: [],
  interiorImage: [],
  windscreenImage: [],
  mechanicalImage: [],
};

EditListingConditionFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  frontWheelImage: array,
  rearWheelImage: array,
  panelWorkImage: array,
  paintWorkImage: array,
  interiorImage: array,
  windscreenImage: array,
  mechanicalImage: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onRatingChange: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingConditionFormComponent);
