import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: April 10, 2021</p>


      <ol className={classNames(css.orderedList, css.levelOne)}>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Privacy Policy
          <p>
            Wholesale It built the Wholesale It website as a Commercial website.
            This SERVICE is provided by Wholesale It and is intended for use as
            is.This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection and
            use of information in relation to this policy. The Personal Information
            that we collect is used for providing and improving the Service. We will
            not use or share your information with anyone except as described in
            this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in our
            Terms and Conditions, which is accessible at Wholesale It unless
            otherwise defined in this Privacy Policy.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Information Collection and Use
          <p>
            For a better experience, while using our Service, we may require you to
            provide us with certain personally identifiable information, including
            but not limited to Name, Business name, Business Address, Phone, Email,
            LMCT number. The information that we request will be retained by us and
            used as described in this privacy policy.
          </p>
          <p>
            The website does use third party services that may collect information
            used to identify you.
          </p>
          <p>
            Link to privacy policy of third-party service providers used by the site
          </p>
          <ul className={classNames(css.unorderedList, css.privacyLinks)}>
            <li>
              <a href="https://www.google.com/policies/privacy/" target="_blank">
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/about/privacy/update/printable"
                target="_blank"
              >
                Facebook
              </a>
            </li>
          </ul>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Log Data
          <p>
            We want to inform you that whenever you use our Service, in a case of an
            error in the website we collect data and information (through third
            party products) on your phone called Log Data. This Log Data may include
            information such as your device Internet Protocol (“IP”) address, device
            name, operating system version, the configuration of the app when
            utilizing our Service, the time and date of your use of the Service, and
            other statistics.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Cookies
          <p>
            Cookies are files with a small amount of data that are commonly used as
            anonymous unique identifiers. These are sent to your browser from the
            websites that you visit and are stored on your device's internal
            memory.This Service does not use these “cookies” explicitly. However,
            the websitemay use third party code and libraries that use “cookies” to
            collect information and improve their services. You have the option to
            either accept or refuse these cookies and know when a cookie is being
            sent to your device. If you choose to refuse our cookies, you may not be
            able to use some portions of this Service.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Service Providers
          <p>
            We may employ third-party companies and individuals due to the following
            reasons:
          </p>
          <ul className={css.unorderedList}>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analysing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties have
            access to your Personal Information. The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Security
          <p>
            We value your trust in providing us your Personal Information, thus we
            arestriving to use commercially acceptable means of protecting it. But
            remember that no method of transmission over the internet, or method of
            electronic storage is 100% secure and reliable, and we cannot guarantee
            its absolute security.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Links to Other Sites
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are notoperated by us. Therefore, we strongly advise you
            to review the Privacy Policy of these websites. We have no control over
            and assume no responsibility for the content, privacy policies, or
            practices of any third-party sites or services.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Children’s Privacy
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13 has
            provided us with personal information, we immediately delete this from
            our servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us so
            that we will be able to do necessary actions.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Changes to this Privacy Policy
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.This
            policy is effective as of 22 April 2021.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Contact Us
          <p>
            If you have any questions or suggestions about our Privacy Policy, do
            not hesitate to contact us at{' '}
            <a href="mailto:tom.busler@wholesaleitauto.com.au">
              tom.busler@wholesaleitauto.com.au
            </a>
            .
          </p>
          <p>
            This privacy policy page was created at{' '}
            <a href="https://privacypolicytemplate.net/" target="_blank">
              privacypolicytemplate.net
            </a>{' '}
            and modified/generated by{' '}
            <a
              href="https://app-privacy-policy-generator.nisrulz.com/"
              target="_blank"
            >
              App Privacy Policy Generator
            </a>
          </p>
        </li>
      </ol>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
