// ================ Action types ================ //

import { getNotificationSuccess } from "../../ducks/Auth.duck";

export const SET_NOTIFICATIONS = 'app/Notifications/SET_NOTIFICATIONS';
export const GET_NOTIFICATIONS = 'app/Notifications/GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS =
  'app/Notifications/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR =
  'app/Notifications/GET_NOTIFICATIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  notificationData: null,
  fetchingNotifications: false,
  fetchingNotificationsError: false,
};

export default function notificationsReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTIFICATIONS:
      return { ...state, notificationData: { ...payload } };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        fetchingNotifications: true,
        fetchingNotificationsError: null,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetchingNotificationsError: false,
        fetchingNotifications: false,
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        fetchingNotifications: false,
        fetchingNotificationsError: payload.error
          ? payload.error
          : 'Something went wrong while fetching notifications',
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const settingNotifications = notifications => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

const gettingNotifications = () => ({
  type: GET_NOTIFICATIONS,
});

export const getNotification = () => (dispatch, getState, sdk) => {
  dispatch(gettingNotifications());
};

export const setNotification = notifications => (dispatch, getState, sdk) => {
  dispatch(settingNotifications(notifications));
};
