import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { maxLength, required, composeValidators } from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form, FieldTextInput } from '../../components';
import CustomRadioInput from '../../components/Custom/CustomRadioInput';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import css from './EditListingFeaturesForm.module.css';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const TITLE_MAX_LENGTH = 60;

const EditListingFeaturesFormComponent = props => {
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => {
    setShowToast(false);
  };
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          name,
          handleSubmit,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          intl,
          onFormChange,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;

        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const fieldLabel = name => {
          return intl.formatMessage({ id: `EditListingFeaturesForm.${name}` });
        };
        const fieldPlaceholderMessage = name => {
          return intl.formatMessage({
            id: `EditListingFeaturesForm.${name}Placeholder`,
          });
        };
        const fieldRequiredMessage = name => {
          return intl.formatMessage({
            id: `EditListingFeaturesForm.${name}Required`,
          });
        };
        const maxLength60Message = maxLength(
          maxLengthMessage,
          TITLE_MAX_LENGTH
        );
        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
          </p>
        ) : null;

        useEffect(() => {
          if (updated) {
            setShowToast(true);
          }
        }, [updated]);

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}

              <FormSpy onChange={onFormChange} />
              <CustomRadioInput
                id="tintedWindow"
                name="tintedWindow"
                formName="Features"
                value={initialValues.tintedWindow}
                intl={intl}
              />

              <CustomRadioInput
                id="aftermarketWheels"
                name="aftermarketWheels"
                formName="Features"
                value={initialValues.aftermarketWheels}
                intl={intl}
              />

              <CustomRadioInput
                id="towBar"
                name="towBar"
                formName="Features"
                value={initialValues.towBar}
                intl={intl}
              />

              <CustomRadioInput
                id="bluetooth"
                name="bluetooth"
                formName="Features"
                value={initialValues.bluetooth}
                intl={intl}
              />

              <CustomRadioInput
                id="cargoBlind"
                name="cargoBlind"
                formName="Features"
                value={initialValues.cargoBlind}
                intl={intl}
              />

              <CustomRadioInput
                id="heatedSeats"
                name="heatedSeats"
                formName="Features"
                value={initialValues.heatedSeats}
                intl={intl}
              />

              <CustomRadioInput
                id="bullBar"
                name="bullBar"
                formName="Features"
                value={initialValues.bullBar}
                noMarginBottom={true}
                intl={intl}
              />

              <FieldTextInput
                id="numberOfKeys"
                name="numberOfKeys"
                className={css.features}
                type="number"
                label={fieldLabel('numberOfKeys')}
                placeholder={fieldPlaceholderMessage('numberOfKeys')}
                maxLength={TITLE_MAX_LENGTH}
                min="0"
                validate={required(fieldRequiredMessage('numberOfKeys'))}
              />

              {/* <FieldDateInput
            id = 'registrationDate'
            name = 'registrationDate'
            useMobileMargins = {false}
            className={css.features}
            label = {fieldLabel('registrationDate')}
            placeholderText = {moment().format('ddd, MMMM D')}
            format = {identity}
            validate = {composeValidators(required(fieldRequiredMessage('registrationDate')), bookingDateRequired('Date is not valid'))}
            onBlur = {() => {}}
            onFocus = {() => {}}
            maxDate={new Date()}
         />

          <FieldTextInput
            id="enginesize"
            name="enginesize"
            className={css.features}
            type="text"
            label={fieldLabel('enginesize')}
            placeholder={fieldPlaceholderMessage('enginesize')}
            maxLength={TITLE_MAX_LENGTH}
          />

          <FieldTextInput
            id="kilowatts"
            name="kilowatts"
            className={css.features}
            type="text"
            label={fieldLabel('kilowatts')}
            placeholder={fieldPlaceholderMessage('kilowatts')}
            maxLength={TITLE_MAX_LENGTH}
          />

          <FieldTextInput
            id="numberOfDoors"
            name="numberOfDoors"
            className={css.features}
            type="number"
            label={fieldLabel('numberOfDoors')}
            placeholder={fieldPlaceholderMessage('numberOfDoors')}
            maxLength={TITLE_MAX_LENGTH}
          />

          <FieldTextInput
            id="numberOfSeats"
            name="numberOfSeats"
            className={css.features}
            type="number"
            label={fieldLabel('numberOfSeats')}
            placeholder={fieldPlaceholderMessage('numberOfSeats')}
            maxLength={TITLE_MAX_LENGTH}
          />


          <CustomRadioInput
            id="sunRoof"
            name="sunRoof"
            formName="Features"
            intl={intl}
          />

          <CustomRadioInput
            id="keylessEntry"
            name="keylessEntry"
            formName="Features"
            intl={intl}
          /> */}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
            <Snackbar
              open={showToast}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                Updated!
              </Alert>
            </Snackbar>
          </>
        );
      }}
    />
  );
};

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
