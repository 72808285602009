import { updatedEntities, denormalisedEntities } from '../util/data';

// ================ Action types ================ //

export const ADD_MARKETPLACE_ENTITIES =
  'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';
export const FETCH_CAR_DATA = 'app/marketplaceData/FETCH_CAR_DATA';
export const FETCH_STOLEN_CAR_CHECK_DATA =
  'app/marketplaceData/FETCH_STOLEN_CAR_CHECK_DATA';
export const CLEAR_CAR_DATA = 'app/marketplaceData/CLEAR_CAR_DATA';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  entities: {},
  carData: {},
  stolenCarCheckData: {},
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

export default function marketplaceDataReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MARKETPLACE_ENTITIES:
      return merge(state, payload);
    case FETCH_CAR_DATA:
      return {
        ...state,
        carData: payload.result && payload.result[0] ? payload.result[0] : {},
      };
    case FETCH_STOLEN_CAR_CHECK_DATA:
      return {
        ...state,
        stolenCarCheckData:
          payload.result && payload.result[0] ? payload.result[0] : {},
      };
    case CLEAR_CAR_DATA:
      return {
        ...state,
        stolenCarCheckData: {},
        carData: {},
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.marketplaceData;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
  const { entities } = state.marketplaceData;
  const throwIfNotFound = false;
  return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addMarketplaceEntities = sdkResponse => ({
  type: ADD_MARKETPLACE_ENTITIES,
  payload: sdkResponse,
});

export const addCarData = response => ({
  type: FETCH_CAR_DATA,
  payload: response,
});

export const addStolenCheckData = response => ({
  type: FETCH_STOLEN_CAR_CHECK_DATA,
  payload: response,
});

export const clearCarDataMain = () => ({
  type: CLEAR_CAR_DATA,
  payload: {},
});
