import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const DatePickers = props => {
  const {
    id,
    date,
    onDateChange,
    onFocusChange,
    screenReaderInputMessage,
    focused,
    placeholder,
    minDate,
    maxDate,
    views,
    labelFormat,
    ...rest
  } = props;
  const [selectedDate, handleDateChange] = useState(
    date ? new Date(date) : null
  );
  const handleChange = date => {
    handleDateChange(date.toDate());
    onDateChange(date.toDate());
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        format={labelFormat ? labelFormat : 'DD-MM-yyyy'}
        placeholder={placeholder}
        views={views}
        value={selectedDate}
        onChange={handleChange}
        focused={focused}
        onBlur={onFocusChange}
        aria-required={true}
        aria-label={screenReaderInputMessage}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickers;
