import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// css
import css from './IconEmailSent.module.css';

const IconEmailSent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    // <svg
    //   className={classes}
    //   width="70"
    //   height="33"
    //   viewBox="0 0 70 33"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g
    //     strokeWidth="3"
    //     fill="none"
    //     fillRule="evenodd"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   >
    //     <path d="M59.592 26.65c-.958 2.4-3.67 4.35-6.056 4.35H18.93c-2.387 0-3.552-1.95-2.595-4.35l8.075-20.3C25.364 3.95 28.078 2 30.466 2H65.07c2.39 0 3.55 1.95 2.596 4.35l-8.074 20.3z" />
    //     <path d="M62 8L41.345 19 30 8M22 26l10-7M54 26l-4.5-5.5M17 5H2M6.528 25H2M11.513 15.5H2" />
    //   </g>
    // </svg>

    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="45"
      viewBox="0 0 52 45"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 30.5C50 37.4062 44.4062 43 37.5 43C30.5938 43 25 37.4062 25 30.5C25 23.5958 30.5938 18 37.5 18C44.4062 18 50 23.5958 50 30.5V30.5Z"
        stroke="#FFAA00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 28L36 36L32 32"
        stroke="#FFAA00"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4286 32H5.07143C3.376 32 2 30.5579 2 28.7857V5.21429C2 3.43786 3.376 2 5.07143 2H41.9286C43.624 2 45 3.43786 45 5.21429V14.8571"
        stroke="#329BDF"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 4.02579L23.0149 17L3 4"
        stroke="#329BDF"
        strokeWidth="2.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconEmailSent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconEmailSent.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmailSent;
