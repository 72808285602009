import React from 'react';
import { useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// components
import NoImageIcon from '../ResponsiveImage/NoImageIcon';
// utils
import { FormattedMessage } from '../../util/reactIntl';
// assets
import image from '../../assets/background-1440.jpg';
// css
import css from './BlogDetailPage.module.css';

const BlogDetailPage = props => {
  const { className, rootClassName, alt, noImageMessage, blogData, ...rest } = props;
  const classes = classNames(rootClassName || css.root, css.container);
  let noImageComponent = null;
  const history = useHistory();

  let publishDate = null;

  try {
    publishDate = blogData.pubDate
      ? moment(new Date(blogData.pubDate)).format('MMM DD, yyyy')
      : moment(new Date()).format('MMM DD, yyyy');
  } catch (err) {
    publishDate = moment(+new Date()).format('MMM DD, yyyy');
  }

  if (!blogData.articleImg) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    noImageComponent = (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const src = blogData.articleImg ? blogData.articleImg : image;

  const imgProps = {
    src,
    ...rest,
  };
  return (
    <section className={classes}>
      <button
        className={css.backBtn}
        onClick={() => {
          if (history && history.action !== 'REPLACE') {
            history.goBack();
          } else {
            history.push('/');
          }
        }}
      >
        {' '}
        <NavigateBeforeIcon className={css.backIcon} />
        {' Back'}
      </button>
      <section className={css.mainContent}>
        <h3>{blogData.title ? blogData.title : ''}</h3>
        <section className={css.author}>
          <span>{publishDate} by </span>
          <span className={css.authorName}>
            {blogData.authorName ? blogData.authorName : 'Anonymous'}
          </span>
        </section>
      </section>
      <section className={css.imageWrapper}>
        <img
          alt={'Blog Post'}
          {...imgProps}
          onError={e => {
            e.target.src = image;
          }}
        />
      </section>
      <section
        className={css.description}
        dangerouslySetInnerHTML={{ __html: blogData.description }}
      ></section>
    </section>
  );
};

BlogDetailPage.defaultProps = {
  blogData: {},
};

BlogDetailPage.propType = {
  blogData: object.isRequired,
};

export default BlogDetailPage;
