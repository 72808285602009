import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
// components
import NoImageIcon from '../ResponsiveImage/NoImageIcon';
import { Button } from '..';
import ExternalLink from '../ExternalLink/ExternalLink';
// assets
import image from '../../assets/background-1440.jpg';
// util
import { FormattedMessage } from '../../util/reactIntl';
// css
import css from './BlogHeaderComponent.module.css';

const BlogHeaderComponent = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    blogs,
    sortVal,
    firstBlog,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  let noImageComponent = null;

  const blogData =
    blogs && typeof blogs === 'object' && Object.values(blogs).length > 0
      ? Object.values(blogs)[0]
      : {};

  let publishDate = null;

  try {
    publishDate = blogData.pubDate
      ? moment(new Date(blogData.pubDate)).format('MMM DD, yyyy')
      : moment(new Date()).format('MMM DD, yyyy');
  } catch (err) {
    publishDate = moment(+new Date()).format('MMM DD, yyyy');
  }

  if (!blogData.articleImg) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    noImageComponent = (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const src = blogData.articleImg ? blogData.articleImg : image;

  const imgProps = {
    className: classes,
    src,
    ...rest,
  };
  return (
    <section className={css.container}>
      <section className={css.mainContent}>
        {blogData.authorName && !blogData.authorName.includes('nonym') ? (
          <section className={css.author}>
            <span>{publishDate} by </span>
            <span className={css.authorName}>{blogData.authorName}</span>
          </section>
        ) : null}
        <section className={css.description}>
          <h3>{blogData.title ? blogData.title : ''}</h3>
          <p dangerouslySetInnerHTML={{ __html: blogData.description }}></p>
        </section>
        <ExternalLink
          className={css.blogHeaderButtonLink}
          // params={{ id: blog.id ? blog.id : '245029456123999' }}
          // name="BlogDetailPage"
          href={blogData && blogData.link}
        >
          {/* <NamedLink
          className={css.blogHeaderButtonLink}
          params={{ id: blogData.id ? blogData.id : '245029456123999' }}
          name="BlogDetailPage"
        > */}
          <Button className={css.readBtn}>Read More</Button>
          {/* </NamedLink> */}
        </ExternalLink>
      </section>
      <section className={css.imageSection}>
        {noImageComponent ? (
          noImageComponent
        ) : (
          <img
            alt={alt}
            onError={e => {
              e.target.src = image;
            }}
            {...imgProps}
          />
        )}
      </section>
    </section>
  );
};

export default BlogHeaderComponent;
