import React, { useState, useEffect } from 'react';
// component
import BlogsSearchResultsPanel from '../BlogsSearchResultsPanel/BlogsSearchResultsPanel';

const BlogMainContent = props => {
  const { blogs, handleOnClickSort, sortVal } = props;
  const [blogsData, setBlogsData] = useState([]);
  const [modifiedBlogs, setModifiedBlogs] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const handleShowMore = () => {
    const toModifyBlogs = [...modifiedBlogs];
    setBlogsData([...blogsData, ...toModifyBlogs.splice(0, 6)]);
    setModifiedBlogs(toModifyBlogs);
    if (toModifyBlogs.length > 0) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
    return null;
  };

  useEffect(() => {
    if (blogs && typeof blogs === 'object' && Object.values(blogs).length > 0) {
      let toModifyBlogs = Object.values(blogs);
      toModifyBlogs.shift();

      setTotalLength(toModifyBlogs.length);
      setBlogsData([...toModifyBlogs.splice(0, 6)]);
      setModifiedBlogs(toModifyBlogs);
      if (toModifyBlogs.length > 0) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    }
  }, [blogs, sortVal]);
  const sortBlogsByTime = () => {
    handleOnClickSort();
  };
  return blogsData && blogsData.length > 0 ? (
    <BlogsSearchResultsPanel
      blogs={blogsData}
      showMore={showMore}
      handleShowMore={handleShowMore}
      totalLength={totalLength}
      sortBlogsByTime={sortBlogsByTime}
      sort={sortVal}
    />
  ) : (
    <div>No data</div>
  );
};

export default BlogMainContent;
