import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEmailAttention } from '../../components';

import css from './ModalMissingInformation.module.css';

const LmctVerification = props => {
  const { className } = props;

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.lmctNotVerifiedTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.lmctNotVerifiedText" />
      </p>
    </div>
  );
};

export default LmctVerification;
