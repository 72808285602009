import React, { Component } from 'react';
import { string, bool, arrayOf, array, func, number } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { required, composeValidators, valuationValue } from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import css from './ValuationForm.module.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class ValuationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      openDialog: false,
      valuationPlaced: false,
      valuation: '',
      userId: '',
      valuationError: null,
      closeForm: false,
      showConfirm: true,
      requestValuation: false,
      confirmValuation: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.valuationModalContent = this.valuationModalContent.bind(this);
    this.handleValuationSubmit = this.handleValuationSubmit.bind(this);
  }

  setUser = () => {
    const user = ensureCurrentUser(this.props.currentUser);
    if (user) {
      const userId = user.id && user.id.uuid ? user.id.uuid : '';
      if (userId) this.setState({ userId });
    }
  };

  componentDidUpdate = () => {
    if (!this.state.userId) {
      this.setUser();
    }
  };

  handleDialogOpen = () => {
    this.setState({ openDialog: true });
  };

  handleDialogClose = type => {
    if (type === 'okay') {
      this.setState({
        openDialog: false,
        valuationPlaced: false,
        showConfirm: true,
        valuationError: false,
      });
    } else {
      this.setState({
        openDialog: false,
        valuationPlaced: false,
        valuationError: false,
      });
    }
  };

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    this.setState({ valuationPlaced: false, requestValuation: true, confirmValuation: false });
    const valuation = e.valuation || '';

    if (!valuation) {
      return false;
    } else {
      this.setState(
        {
          valuation,
        },
        () => {
          this.handleDialogOpen();
        }
      );
    }
  }

  handleValuationSubmit(form) {
    this.setState({ confirmValuation: true, requestValuation: false });
    if (!this.state.userId || !this.state.valuation) {
      return false;
    }

    const valuationObj = {
      userId: this.state.userId,
      userName: this.state.userName,
      valuation: this.state.valuation,
      time: +new Date(),
    };

    this.props.onSubmit(
      valuationObj,
      (error = false) => {
        if (!error) {
          this.setState({ valuationPlaced: true, closeForm: true, valuationError: null });
        } else {
          this.setState({ valuationError: error, valuationPlaced: false });
        }
        form.change('valuation', undefined);
        form.resetFieldState('valuation');
      },
      'valuation'
    );
  }

  valuationModalContent = (valuationAmount = '') => {
    if (this.state.requestValuation) {
      return (
        <h2 className={css.valuationConfirmation}>{`Confirm the valuation amount - ${
          valuationAmount ? valuationAmount : ''
        }?`}</h2>
      );
    }
    if (this.state.confirmValuation || this.state.valuationPlaced) {
      if (this.state.valuationPlaced === false) {
        return (
          <div className={css.valuationPlacedDialogContent}>
            <h4>Great!</h4>
            <p>Wait for the response...</p>
          </div>
        );
      }
      return (
        <div className={css.valuationPlacedDialogContent}>
          <h4>Great!</h4>
          <p>Thank you for submitting a valuation for this vehicle!</p>
        </div>
      );
    }
  };

  render() {
    const { rootClassName, className, metadata, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            values,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            form,
            lmctUnverified,
          } = fieldRenderProps;

          const currentUser = this.props.currentUser;

          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredValuation',
          });

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const formateValuationAmount = value => {
            if (value === undefined) return '';
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, '');
            return new Intl.NumberFormat('en-AU', {
              style: 'currency',
              currency: 'AUD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(onlyNums);
          };
          const parseValuationAmount = value => {
            if (value === undefined) return '';
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, '');
            return onlyNums;
          };
          return (
            <>
              <Form
                onSubmit={handleSubmit}
                className={classes}
                enforcePagePreloadFor="CheckoutPage"
              >
                {loadingSpinnerMaybe}
                {bookingInfoErrorMaybe}
                {!isOwnListing && !lmctUnverified ? (
                  <>
                    <FieldTextInput
                      className={css.valuationInput}
                      type="text"
                      id={`${formId}.valuation`}
                      name="valuation"
                      placeholder="$"
                      onKeyDown={e =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189 ||
                          e.keyCode === 187) &&
                        e.preventDefault()
                      }
                      validate={composeValidators(required(requiredMessage), valuationValue())}
                      parse={parseValuationAmount}
                      format={formateValuationAmount}
                    />

                    <div className={css.submitButtonClasses}>
                      <PrimaryButton type="submit" className={css.valuationNowButton}>
                        <FormattedMessage id="BookingDatesForm.requestToBookValuate" />
                      </PrimaryButton>
                    </div>
                  </>
                ) : null}
                {isOwnListing || lmctUnverified ? (
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingDatesForm.ownListingValuation'
                          : lmctUnverified
                          ? 'BookingDatesForm.lmctUnverifiedValuation'
                          : 'BookingDatesForm.youWontBeChargedInfoValuation'
                      }
                    />
                  </p>
                ) : null}
                <NamedLink
                  className={css.seeValuationListLink}
                  name="CommonList"
                  params={{
                    id: this.props.match.params.id,
                    slug: this.props.match.params.slug,
                    userid: this.props.match.params.userid,
                    listType: 'valuations',
                  }}
                >
                  <span>
                    <FormattedMessage id="BookingPanel.seeValuationsList" />
                  </span>
                </NamedLink>
              </Form>
              <Dialog
                open={this.state.openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleDialogClose('none')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  {this.valuationModalContent(
                    new Intl.NumberFormat('en-AU', {
                      style: 'currency',
                      currency: 'AUD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(this.state.valuation)
                  )}
                  {this.state.valuationError ? (
                    <p className={css.valuationError}>Something went wrong.</p>
                  ) : null}
                </DialogContent>
                <DialogActions className={css.dialogActionButtonsCenter}>
                  {this.state.confirmValuation || this.state.valuationPlaced ? (
                    this.state.confirmValuation && this.state.valuationPlaced === false ? null : (
                      <Button
                        className={classNames(css.actionBtn, css.primaryActionBtn)}
                        onClick={() => this.handleDialogClose('okay')}
                        color="primary"
                      >
                        Okay
                      </Button>
                    )
                  ) : null}
                  {this.state.requestValuation ? (
                    <>
                      <Button
                        onClick={() => this.handleDialogClose('cancel')}
                        color="primary"
                        className={classNames(css.actionBtn, css.cancelActionBtn)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classNames(css.actionBtn, css.primaryActionBtn)}
                        onClick={() => this.handleValuationSubmit(form)}
                        color="primary"
                      >
                        Yes, I'm sure
                      </Button>
                    </>
                  ) : null}
                  {/* {!this.state.valuationPlaced ? (
                    <>
                      <Button
                        onClick={() => this.handleDialogClose('cancel')}
                        color="primary"
                        className={classNames(css.actionBtn, css.cancelActionBtn)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classNames(css.actionBtn, css.primaryActionBtn)}
                        onClick={() => this.handleValuationSubmit(form)}
                        color="primary"
                      >
                        Yes, I'm sure
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={classNames(css.actionBtn, css.primaryActionBtn)}
                      onClick={() => this.handleDialogClose('okay')}
                      color="primary"
                    >
                      Okay
                    </Button>
                  )} */}
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    );
  }
}

ValuationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ValuationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  isOwnListing: bool,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const ValuationForm = compose(injectIntl)(ValuationFormComponent);
ValuationForm.displayName = 'ValuationForm';

export default ValuationForm;
