import React, {useEffect, useState} from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureListing, ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import NotificationsHandlers from '../../util/notifications';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';

import { Topbar } from '../../components';

import { setNotification } from '../../components/Notifications/Notifications.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

const { UUID } = sdkTypes;
export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    stripeAccount,
    stripeAccountFetched,
    stripeCustomerFetched,
    match,
    getOwnListing,
    getListing,
    notificationCount,
    getNotificationError,
    getNotificationInProgress,
    notifications,
    notificationCountFirebase,
    unSeenNotifications,
    allNotifications,
    allNotificationsCount,
    setNotification,
    seenIds,
    ...rest
  } = props;
  const dispatch = useDispatch()
  const [hasGenericErrorState, sethasGenericErrorState] = useState(hasGenericError);
  const manageNotifications = new NotificationsHandlers({ dispatch });
  const user = ensureCurrentUser(currentUser);
  const userId = user.id && user.id.uuid ? user.id.uuid : '';
  useEffect(() => {
    if (userId && seenIds.length === 0 && allNotificationsCount === null) {
      manageNotifications.getNotifications(userId, setNotification)
    }
    // return () => {
    //   effect
    // };
  }, [userId]);
  let currentListing = null;
  if (match.path === '/l/:slug/:userid/:id') {
    const listingId = new UUID(match.params.id);
    const isPendingApprovalVariant = match.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = match.params.variant === LISTING_PAGE_DRAFT_VARIANT;
    currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
  }
  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      showGenericError={hasGenericError}
      hasGenericErrorState={hasGenericErrorState}
      sethasGenericErrorState={() => sethasGenericErrorState(!hasGenericErrorState)}
      stripeAccountFetched={stripeAccountFetched}
      stripeAccount={stripeAccount}
      stripeCustomerFetched={stripeCustomerFetched}
      match={match}
      listing={currentListing}
      getNotificationError={getNotificationError}
      getNotificationInProgress={getNotificationInProgress}
      notifications={notifications}
      notificationCountFirebase={notificationCountFirebase}
      unSeenNotifications={unSeenNotifications}
      allNotifications={allNotifications}
      allNotificationsCount={allNotificationsCount}
      seenIds={seenIds}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  notificationCountFirebase: 0,
  sendVerificationEmailError: null,
  authScopes: null,
  showVerificationNeeded: false,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  notificationCountFirebase: number.isRequired,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,
  showVerificationNeeded: bool,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const {
    isAuthenticated,
    logoutError,
    authScopes,
    getNotificationError,
    getNotificationInProgress,
    notifications,
    notificationCountFirebase,
    unSeenNotifications,
    allNotifications,
    allNotificationsCount,
    seenIds
  } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  console.log("currentUser",currentUser);
  const { stripeAccountFetched, stripeAccount } = state.stripeConnectAccount;
  const { stripeCustomerFetched } = state.CheckoutPage;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    stripeAccountFetched,
    stripeAccount,
    stripeCustomerFetched,
    getOwnListing,
    getListing,
    notificationCount,
    getNotificationError,
    getNotificationInProgress,
    notifications,
    notificationCountFirebase,
    unSeenNotifications,
    allNotifications,
    allNotificationsCount,
    seenIds
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  setNotification: (notifications = {}) => dispatch(setNotification(notifications)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
