export const make = [
    {
        "key": "bmw",
        "id": 0,
        "label": "BMW"
    },
    {
        "key": "ford",
        "id": 1,
        "label": "Ford"
    },
    {
        "key": "holden",
        "id": 2,
        "label": "Holden"
    },
    {
        "key": "hyundai",
        "id": 3,
        "label": "Hyundai"
    },
    {
        "key": "mazda",
        "id": 4,
        "label": "Mazda"
    },
    {
        "key": "mercedes-benz",
        "id": 5,
        "label": "Mercedes-Benz"
    },
    {
        "key": "mitsubishi",
        "id": 6,
        "label": "Mitsubishi"
    },
    {
        "key": "nissan",
        "id": 7,
        "label": "Nissan"
    },
    {
        "key": "toyota",
        "id": 8,
        "label": "Toyota"
    },
    {
        "key": "volkswagen",
        "id": 9,
        "label": "Volkswagen"
    },
    {
        "key": "abarth",
        "id": 10,
        "label": "Abarth"
    },
    {
        "key": "ac",
        "id": 11,
        "label": "AC"
    },
    {
        "key": "alfaromeo",
        "id": 12,
        "label": "Alfa Romeo"
    },
    {
        "key": "alpina",
        "id": 13,
        "label": "Alpina"
    },
    {
        "key": "alpine",
        "id": 14,
        "label": "Alpine"
    },
    {
        "key": "alpine-renault",
        "id": 15,
        "label": "Alpine-Renault"
    },
    {
        "key": "alvis",
        "id": 16,
        "label": "Alvis"
    },
    {
        "key": "amgeneral",
        "id": 17,
        "label": "AM General"
    },
    {
        "key": "armstrongsiddeley",
        "id": 18,
        "label": "Armstrong Siddeley"
    },
    {
        "key": "astonmartin",
        "id": 19,
        "label": "Aston Martin"
    },
    {
        "key": "audi",
        "id": 20,
        "label": "Audi"
    },
    {
        "key": "austin",
        "id": 21,
        "label": "Austin"
    },
    {
        "key": "austinhealey",
        "id": 22,
        "label": "Austin Healey"
    },
    {
        "key": "bentley",
        "id": 23,
        "label": "Bentley"
    },
    {
        "key": "bmw",
        "id": 24,
        "label": "BMW"
    },
    {
        "key": "bristol",
        "id": 25,
        "label": "Bristol"
    },
    {
        "key": "buick",
        "id": 26,
        "label": "Buick"
    },
    {
        "key": "cadillac",
        "id": 27,
        "label": "Cadillac"
    },
    {
        "key": "caterham",
        "id": 28,
        "label": "Caterham"
    },
    {
        "key": "chery",
        "id": 29,
        "label": "Chery"
    },
    {
        "key": "chevrolet",
        "id": 30,
        "label": "Chevrolet"
    },
    {
        "key": "chrysler",
        "id": 31,
        "label": "Chrysler"
    },
    {
        "key": "citroen",
        "id": 32,
        "label": "Citroen"
    },
    {
        "key": "clenet",
        "id": 33,
        "label": "Clenet"
    },
    {
        "key": "crossley",
        "id": 34,
        "label": "Crossley"
    },
    {
        "key": "custom",
        "id": 35,
        "label": "Custom"
    },
    {
        "key": "daewoo",
        "id": 36,
        "label": "Daewoo"
    },
    {
        "key": "daihatsu",
        "id": 37,
        "label": "Daihatsu"
    },
    {
        "key": "daimler",
        "id": 38,
        "label": "Daimler"
    },
    {
        "key": "datsun",
        "id": 39,
        "label": "Datsun"
    },
    {
        "key": "detomaso",
        "id": 40,
        "label": "De Tomaso"
    },
    {
        "key": "dodge",
        "id": 41,
        "label": "Dodge"
    },
    {
        "key": "durant",
        "id": 42,
        "label": "Durant"
    },
    {
        "key": "elfin",
        "id": 43,
        "label": "Elfin"
    },
    {
        "key": "ferrari",
        "id": 44,
        "label": "Ferrari"
    },
    {
        "key": "fiat",
        "id": 45,
        "label": "Fiat"
    },
    {
        "key": "ford",
        "id": 46,
        "label": "Ford"
    },
    {
        "key": "fordperformancevehicles",
        "id": 47,
        "label": "Ford Performance Vehicles"
    },
    {
        "key": "foton",
        "id": 48,
        "label": "Foton"
    },
    {
        "key": "fsm",
        "id": 49,
        "label": "FSM"
    },
    {
        "key": "fuso",
        "id": 50,
        "label": "Fuso"
    },
    {
        "key": "gaz",
        "id": 51,
        "label": "Gaz"
    },
    {
        "key": "genesis",
        "id": 52,
        "label": "Genesis"
    },
    {
        "key": "gmc",
        "id": 53,
        "label": "GMC"
    },
    {
        "key": "greatwall",
        "id": 54,
        "label": "Great Wall"
    },
    {
        "key": "gwm",
        "id": 55,
        "label": "GWM"
    },
    {
        "key": "haval",
        "id": 56,
        "label": "Haval"
    },
    {
        "key": "hdt",
        "id": 57,
        "label": "HDT"
    },
    {
        "key": "hillman",
        "id": 58,
        "label": "Hillman"
    },
    {
        "key": "hino",
        "id": 59,
        "label": "Hino"
    },
    {
        "key": "holden",
        "id": 60,
        "label": "Holden"
    },
    {
        "key": "holdenspecialvehicles",
        "id": 61,
        "label": "Holden Special Vehicles"
    },
    {
        "key": "honda",
        "id": 62,
        "label": "Honda"
    },
    {
        "key": "humber",
        "id": 63,
        "label": "Humber"
    },
    {
        "key": "hummer",
        "id": 64,
        "label": "Hummer"
    },
    {
        "key": "hyundai",
        "id": 65,
        "label": "Hyundai"
    },
    {
        "key": "infiniti",
        "id": 66,
        "label": "INFINITI"
    },
    {
        "key": "isuzu",
        "id": 67,
        "label": "Isuzu"
    },
    {
        "key": "iveco",
        "id": 68,
        "label": "Iveco"
    },
    {
        "key": "jaguar",
        "id": 69,
        "label": "Jaguar"
    },
    {
        "key": "jba",
        "id": 70,
        "label": "JBA"
    },
    {
        "key": "jeep",
        "id": 71,
        "label": "Jeep"
    },
    {
        "key": "jensen",
        "id": 72,
        "label": "Jensen"
    },
    {
        "key": "jmc",
        "id": 73,
        "label": "JMC"
    },
    {
        "key": "kia",
        "id": 74,
        "label": "Kia"
    },
    {
        "key": "lamborghini",
        "id": 75,
        "label": "Lamborghini"
    },
    {
        "key": "lancia",
        "id": 76,
        "label": "Lancia"
    },
    {
        "key": "landrover",
        "id": 77,
        "label": "Land Rover"
    },
    {
        "key": "ldv",
        "id": 78,
        "label": "LDV"
    },
    {
        "key": "lexus",
        "id": 79,
        "label": "Lexus"
    },
    {
        "key": "leyland",
        "id": 80,
        "label": "Leyland"
    },
    {
        "key": "lincoln",
        "id": 81,
        "label": "Lincoln"
    },
    {
        "key": "londontaxicompany",
        "id": 82,
        "label": "London Taxi Company"
    },
    {
        "key": "lotus",
        "id": 83,
        "label": "Lotus"
    },
    {
        "key": "mahindra",
        "id": 84,
        "label": "Mahindra"
    },
    {
        "key": "maserati",
        "id": 85,
        "label": "Maserati"
    },
    {
        "key": "matra",
        "id": 86,
        "label": "Matra"
    },
    {
        "key": "maybach",
        "id": 87,
        "label": "Maybach"
    },
    {
        "key": "mazda",
        "id": 88,
        "label": "Mazda"
    },
    {
        "key": "mclaren",
        "id": 89,
        "label": "McLaren"
    },
    {
        "key": "mercedes-benz",
        "id": 90,
        "label": "Mercedes-Benz"
    },
    {
        "key": "mercury",
        "id": 91,
        "label": "Mercury"
    },
    {
        "key": "mg",
        "id": 92,
        "label": "MG"
    },
    {
        "key": "mini",
        "id": 93,
        "label": "MINI"
    },
    {
        "key": "mitsubishi",
        "id": 94,
        "label": "Mitsubishi"
    },
    {
        "key": "morgan",
        "id": 95,
        "label": "Morgan"
    },
    {
        "key": "morris",
        "id": 96,
        "label": "Morris"
    },
    {
        "key": "nissan",
        "id": 97,
        "label": "Nissan"
    },
    {
        "key": "oldsmobile",
        "id": 98,
        "label": "Oldsmobile"
    },
    {
        "key": "opel",
        "id": 99,
        "label": "Opel"
    },
    {
        "key": "peugeot",
        "id": 100,
        "label": "Peugeot"
    },
    {
        "key": "plymouth",
        "id": 101,
        "label": "Plymouth"
    },
    {
        "key": "pontiac",
        "id": 102,
        "label": "Pontiac"
    },
    {
        "key": "porsche",
        "id": 103,
        "label": "Porsche"
    },
    {
        "key": "proton",
        "id": 104,
        "label": "Proton"
    },
    {
        "key": "ram",
        "id": 105,
        "label": "RAM"
    },
    {
        "key": "renault",
        "id": 106,
        "label": "Renault"
    },
    {
        "key": "riley",
        "id": 107,
        "label": "Riley"
    },
    {
        "key": "rolls-royce",
        "id": 108,
        "label": "Rolls-Royce"
    },
    {
        "key": "rover",
        "id": 109,
        "label": "Rover"
    },
    {
        "key": "saab",
        "id": 110,
        "label": "Saab"
    },
    {
        "key": "skoda",
        "id": 111,
        "label": "SKODA"
    },
    {
        "key": "smart",
        "id": 112,
        "label": "smart"
    },
    {
        "key": "ssangyong",
        "id": 113,
        "label": "SsangYong"
    },
    {
        "key": "studebaker",
        "id": 114,
        "label": "Studebaker"
    },
    {
        "key": "subaru",
        "id": 115,
        "label": "Subaru"
    },
    {
        "key": "sunbeam",
        "id": 116,
        "label": "Sunbeam"
    },
    {
        "key": "suzuki",
        "id": 117,
        "label": "Suzuki"
    },
    {
        "key": "tata",
        "id": 118,
        "label": "Tata"
    },
    {
        "key": "tesla",
        "id": 119,
        "label": "Tesla"
    },
    {
        "key": "toyota",
        "id": 120,
        "label": "Toyota"
    },
    {
        "key": "trd",
        "id": 121,
        "label": "TRD"
    },
    {
        "key": "triumph",
        "id": 122,
        "label": "Triumph"
    },
    {
        "key": "tvr",
        "id": 123,
        "label": "TVR"
    },
    {
        "key": "ultima",
        "id": 124,
        "label": "Ultima"
    },
    {
        "key": "vauxhall",
        "id": 125,
        "label": "Vauxhall"
    },
    {
        "key": "volkswagen",
        "id": 126,
        "label": "Volkswagen"
    },
    {
        "key": "volvo",
        "id": 127,
        "label": "Volvo"
    },
    {
        "key": "westfield",
        "id": 128,
        "label": "Westfield"
    },
    {
        "key": "wolseley",
        "id": 129,
        "label": "Wolseley"
    },
    {
        "key": "zastava",
        "id": 130,
        "label": "Zastava"
    },
    {
        "key": "zxauto",
        "id": 131,
        "label": "ZX Auto"
    }
]