import React, { useState, useEffect } from 'react';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  nonEmptyArray,
  bookingDateRequired,
  validateKms,
} from '../../util/validators';
import {
  AddImages,
  Form,
  Button,
  FieldTextInput,
  FieldDateInput,
  ValidationError,
} from '../../components';
import CustomRadioInput from '../../components/Custom/CustomRadioInput';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import css from './EditListingHistoryForm.module.css';
import CustomCategorySelectFieldMaybe from '../EditListingDescriptionForm/CustomCategorySelectFieldMaybe';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const identity = v => v;

const ACCEPT_IMAGES = 'image/*';
const TITLE_MAX_LENGTH = 60;

const EditListingHistoryFormComponent = props => {
  const [showToast, setShowToast] = useState(false);
  const [imageUploadRequested, setImageUploadRequested] = useState(false);

  const handleClose = () => {
    setShowToast(false);
  };
  const onImageUploadHandler = (file, type) => {
    if (file) {
      let fileName =
        file.name
          .split('.')
          .slice(0, -1)
          .join('.') || file.name + '';
      let fileExtention = `.${file.name.substring(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )}`;
      let fileNameWithoutSpecialChars = fileName.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ''
      );
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        setImageUploadRequested(true);
        props
          .onImageUpload({
            id: `${fileNameWithoutSpecialChars.concat(fileExtention)}_${Date.now()}_${type}`,
            file,
          })
          .then(() => {
            setImageUploadRequested(false);
          })
          .catch(() => {
            setImageUploadRequested(false);
          });
      }
    }
  };
  return (
    <FinalForm
      {...props}
      onImageUploadHandler={onImageUploadHandler}
      imageUploadRequested={imageUploadRequested}
      render={formRenderProps => {
        const {
          form,
          className,
          disabled,
          ready,
          dashLightImages,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          imageUploadRequested,
          updateInProgress,
          onFormChange,
          onRemoveImage,
          fetchErrors,
          initialValues,
          numberOfOwnersShow,
          nextServiceDateShow,
          values,
          numberOfOwnersOptions,
        } = formRenderProps;
        const fieldLabel = name => {
          return intl.formatMessage({ id: `EditListingHistoryForm.${name}` });
        };

        const fieldPlaceholderMessage = name => {
          return intl.formatMessage({
            id: `EditListingHistoryForm.${name}Placeholder`,
          });
        };

        const fieldRequiredMessage = name => {
          return intl.formatMessage({
            id: `EditListingHistoryForm.${name}Required`,
          });
        };

        const chooseImageText = (
          <span className={css.chooseImageText}>
            <span className={css.chooseImage}>
              <FormattedMessage id="EditListingPhotosForm.chooseImage" />
            </span>
            <span className={css.imageTypes}>
              <FormattedMessage id="EditListingPhotosForm.imageTypes" />
            </span>
          </span>
        );

        const imageRequiredMessage = intl.formatMessage({
          id: 'EditListingPhotosForm.imageRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingHistoryForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingHistoryForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingHistoryForm.showListingFailed" />
          </p>
        ) : null;

        // const nextServiceDueError =
        //   new Date(values.nextServiceDue).toLocaleDateString('en', {
        //     weekday: 'short',
        //     year: 'numeric',
        //     month: '2-digit',
        //     day: 'numeric',
        //   }) <
        //   new Date().toLocaleDateString('en', {
        //     weekday: 'short',
        //     year: 'numeric',
        //     month: '2-digit',
        //     day: 'numeric',
        //   }) ? (
        //     <p className={css.error}>
        //       <FormattedMessage id="EditListingPhotosForm.nextServiceDueError" />
        //     </p>
        //   ) : null;

        const classes = classNames(css.root, className);

        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || imageUploadRequested 
          // || nextServiceDueError;

        useEffect(() => {
          if (updated) {
            setShowToast(true);
          }
        }, [updated]);

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              {/* {nextServiceDueError} */}
              <FormSpy onChange={onFormChange} />
              <CustomRadioInput
                id="extendedWarranty"
                name="extendedWarranty"
                formName="History"
                value={initialValues.extendedWarranty}
                intl={intl}
              />

              {initialValues.extendedWarranty && initialValues.extendedWarranty === 'Yes' ? (
                <FieldDateInput
                  id="expiryDate"
                  name="expiryDate"
                  useMobileMargins={false}
                  className={css.history}
                  label={fieldLabel('expiryDate')}
                  onChange={value => {
                    onFormChange({
                      active: 'expiryDate',
                      values: { expiryDate: value },
                    });
                  }}
                  placeholderText={moment().format('ddd, MMMM D')}
                  format={identity}
                  validate={composeValidators(
                    required(fieldRequiredMessage('expiryDate')),
                    bookingDateRequired('Date is not valid')
                  )}
                  onBlur={() => {}}
                  onFocus={() => {}}
                />
              ) : null}

              <CustomRadioInput
                id="writtenOffVehicleRegister"
                name="writtenOffVehicleRegister"
                formName="History"
                value={initialValues.writtenOffVehicleRegister}
                intl={intl}
              />

              <FieldTextInput
                id="ppsr"
                name="ppsr"
                className={css.history}
                type="text"
                label={fieldLabel('ppsr')}
                placeholder={fieldPlaceholderMessage('ppsr')}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(fieldRequiredMessage('ppsr')))}
              />
              {/* <CustomRadioInput
                id="unknownOwner"
                name="unknownOwner"
                formName="History"
                value={initialValues.unknownOwner}
                onChange={value => {
                  onFormChange({
                    active: 'unknownOwner',
                    values: { unknownOwner: value },
                  });
                }}
                intl={intl}
              /> */}
              {/* {numberOfOwnersShow ? ( */}
              {/* <FieldTextInput
                id="numberOfOwners"
                name="numberOfOwners"
                className={css.numberOfOwners}
                onKeyDown={e =>
                  (e.keyCode === 69 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189 ||
                    e.keyCode === 187) &&
                  e.preventDefault()
                }
                type="number"
                label={fieldLabel('numberOfOwners')}
                placeholder={fieldPlaceholderMessage('numberOfOwners')}
                maxLength={TITLE_MAX_LENGTH}
                min={0}
              /> */}
              <CustomCategorySelectFieldMaybe
                id="numberOfOwners"
                name="numberOfOwners"
                formName="History"
                categories={numberOfOwnersOptions}
                intl={intl}
              />
              {/* ) : null} */}
              <CustomRadioInput
                id="unknownServiceDate"
                name="unknownServiceDate"
                formName="History"
                value={initialValues.unknownServiceDate}
                onChange={value => {
                  onFormChange({
                    active: 'unknownServiceDate',
                    values: { unknownServiceDate: value },
                  });
                }}
                intl={intl}
              />
              {nextServiceDateShow ? (
                <>
                  <FieldDateInput
                    id="lastServiceDate"
                    name="lastServiceDate"
                    useMobileMargins={false}
                    className={css.history}
                    onChange={value => {
                      onFormChange({
                        active: 'lastServiceDate',
                        values: { lastServiceDate: value },
                      });
                    }}
                    label={fieldLabel('lastServiceDate')}
                    placeholderText={moment().format('ddd, MMMM D')}
                    format={identity}
                    validate={composeValidators(
                      required(fieldRequiredMessage('lastServiceDate')),
                      bookingDateRequired('Date is not valid')
                    )}
                    onBlur={() => {}}
                    onFocus={() => {}}
                    maxDate={new Date()}
                  />
                  <FieldTextInput
                    id="lastServiceKms"
                    name="lastServiceKms"
                    min="0"
                    className={css.features}
                    type="number"
                    label={fieldLabel('lastServiceKms')}
                    placeholder={fieldPlaceholderMessage('lastServiceKms')}
                    maxLength={TITLE_MAX_LENGTH}
                    validate={composeValidators(
                      required(fieldRequiredMessage('lastServiceKms')),
                      validateKms(`Kilometers can't be negative or in decimal.`)
                    )}
                  />
                </>
              ) : null}
              {/* {nextServiceDateShow ? (
                <>
                  <FieldDateInput
                    id="nextServiceDue"
                    name="nextServiceDue"
                    useMobileMargins={false}
                    className={css.history}
                    label={fieldLabel('nextServiceDue')}
                    onChange={value => {
                      onFormChange({
                        active: 'nextServiceDue',
                        values: { nextServiceDue: value },
                      });
                    }}
                    placeholderText={moment().format('ddd, MMMM D')}
                    format={identity}
                    validate={composeValidators(
                      required(fieldRequiredMessage('nextServiceDue')),
                      bookingDateRequired('Date is not valid')
                    )}
                    onBlur={() => {}}
                    onFocus={() => {}}
                    minDate={new Date()}
                  />
                  <FieldTextInput
                    id="nextServiceKms"
                    name="nextServiceKms"
                    className={css.features}
                    type="number"
                    label={fieldLabel('nextServiceKms')}
                    placeholder={fieldPlaceholderMessage('nextServiceKms')}
                    maxLength={TITLE_MAX_LENGTH}
                    validate={composeValidators(
                      required(fieldRequiredMessage('nextServiceKms')),
                      validateKms(`Kilometers can't be negative or in decimal.`)
                    )}
                  />
                </>
              ) : null} */}
              <CustomRadioInput
                id="dashWarningLights"
                name="dashWarningLights"
                formName="History"
                value={initialValues.dashWarningLights}
                noMarginBottom={true}
                intl={intl}
              />

              {initialValues.dashWarningLights && initialValues.dashWarningLights === 'Yes' ? (
                <>
                  <div className={css.starRating}>
                    <h3 className={css.conditionHeading}>Dash Warning Lights Images</h3>
                  </div>
                  <AddImages
                    className={css.imagesField}
                    images={dashLightImages}
                    thumbnailClassName={css.thumbnail}
                    savedImageAltText={intl.formatMessage({
                      id: 'EditListingPhotosForm.savedImageAltText',
                    })}
                    onRemoveImage={onRemoveImage}
                  >
                    {dashLightImages && dashLightImages.length < 2 ? (
                      <Field
                        id="dashLightImages"
                        name="dashLightImages"
                        accept={ACCEPT_IMAGES}
                        form={null}
                        label={chooseImageText}
                        type="file"
                        disabled={imageUploadRequested}
                      >
                        {fieldprops => {
                          const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            form.change(`dashLightImages`, file);
                            form.blur(`dashLightImages`);
                            onImageUploadHandler(file, 'dashLightImages');
                          };
                          const inputProps = {
                            accept,
                            id: name,
                            name,
                            onChange,
                            type,
                          };
                          return (
                            <div className={css.addImageWrapper}>
                              <div className={css.aspectRatioWrapper}>
                                {fieldDisabled ? null : (
                                  <input {...inputProps} className={css.addImageInput} />
                                )}
                                <label htmlFor={name} className={css.addImage}>
                                  {label}
                                </label>
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                    ) : null}

                    <Field
                      component={props => {
                        const { input, meta } = props;
                        return (
                          <div className={css.imageRequiredWrapper}>
                            <input {...input} />
                            <ValidationError fieldMeta={meta} />
                          </div>
                        );
                      }}
                      name="dashLightImages"
                      type="hidden"
                      validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                    />
                  </AddImages>
                </>
              ) : null}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
            <Snackbar open={showToast} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Updated!
              </Alert>
            </Snackbar>
          </>
        );
      }}
    />
  );
};

EditListingHistoryFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  dashLightImages: [],
};

EditListingHistoryFormComponent.propTypes = {
  className: string,
  dashLightImages: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingHistoryFormComponent);
