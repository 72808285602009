import React, { Component, useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
// config
import config from '../../config';
// util
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
// component
import { EditListingHistoryForm } from '../../forms';
import { ListingLink } from '../../components';
// css
import css from './EditListingHistoryPanel.module.css';

const EditListingHistoryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    onImageUpload,
    onUpdateImageOrder,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onRemoveImage,
    errors,
  } = props;

  let images = props.images;
  const extraImages = [];

  const getImageArr = (images = [], type = '', ids = []) => {
    let imgArr = [];
    if (images && Array.isArray(images) && images.length > 0) {
      imgArr = images.filter(imgObj => {
        if (imgObj && imgObj.id) {
          if (typeof imgObj.id === 'string' && imgObj.id.split('_')[2] === type) {
            return true;
          } else if (
            typeof imgObj.id === 'object' &&
            imgObj.id.uuid &&
            ids &&
            ids.length > 0 &&
            ids.includes(imgObj.id.uuid)
          ) {
            return true;
          } else {
            extraImages.push(imgObj);
          }
        }
        return false;
      });
    }
    return imgArr;
  };

  const getImageIds = (images = []) => {
    let ids = [];
    if (images && images.length > 0 && Array.isArray(images)) {
      images.forEach(imgObj => {
        if (imgObj && imgObj.imageId && imgObj.imageId.uuid) {
          ids.push(imgObj.imageId.uuid);
        } else if (imgObj && imgObj.id && imgObj.id.uuid) {
          ids.push(imgObj.id.uuid);
        }
      });
    }
    return ids;
  };

  const removeImages = () => { };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const dashLightImageIds = publicData.dashLightImageIds ?? null;
  const [featuresState, setFeaturesState] = useState({});
  // const [numberOfOwnersShow, setnumberOfOwnersShow] = useState(
  //   featuresState.unknownOwner
  //     ? featuresState.unknownOwner === 'No'
  //     : publicData.unknownOwner
  //     ? publicData.unknownOwner === 'No'
  //     : true
  // );
  const [nextServiceDateShow, setnextServiceDateShow] = useState(
    featuresState.unknownServiceDate
      ? featuresState.unknownServiceDate === 'Yes'
      : publicData.unknownServiceDate
        ? publicData.unknownServiceDate === 'Yes'
        : false
  );
  const dashLightImages = getImageArr(images, 'dashLightImages', dashLightImageIds);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingHistoryPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingHistoryPanel.createListingTitle" />
  );

  const onFormChange = value => {
    if (value.active && value.values[value.active] != featuresState[value.active]) {
      // if (value.active === 'unknownOwner') {
      //   setnumberOfOwnersShow(value.values[value.active] === 'Yes' ? false : true);
      // }
      if (value.active === 'unknownServiceDate') {
        setnextServiceDateShow(value.values[value.active] === 'Yes' ? true : false);
      }
      setFeaturesState({
        ...featuresState,
        [value.active]: value.values[value.active],
      });
    }
  };
  const initialValues = {
    extendedWarranty: featuresState.extendedWarranty
      ? featuresState.extendedWarranty
      : publicData.extendedWarranty,
    expiryDate: featuresState.expiryDate
      ? featuresState.expiryDate
      : publicData.expiryDate ? new Date(publicData.expiryDate) : undefined,
    writtenOffVehicleRegister: featuresState.writtenOffVehicleRegister
      ? featuresState.writtenOffVehicleRegister
      : publicData.writtenOffVehicleRegister,
    ppsr: featuresState.ppsr ? featuresState.ppsr : publicData.ppsr,
    unknownOwner: featuresState.unknownOwner
      ? featuresState.unknownOwner
      : publicData.unknownOwner
        ? publicData.unknownOwner
        : 'No',
    numberOfOwners: featuresState.numberOfOwners
      ? featuresState.numberOfOwners
      : publicData.numberOfOwners,
    unknownServiceDate: featuresState.unknownServiceDate
      ? featuresState.unknownServiceDate
      : publicData.unknownServiceDate
        ? publicData.unknownServiceDate
        : 'No',
    lastServiceDate: featuresState.lastServiceDate
      ? featuresState.lastServiceDate
      : publicData.lastServiceDate ? new Date(publicData.lastServiceDate) : undefined,

    // nextServiceDue: featuresState.nextServiceDue
    //   ? featuresState.nextServiceDue
    //   : publicData?.nextServiceDue ? new Date(publicData.nextServiceDue) : undefined,
    dashWarningLights: featuresState.dashWarningLights
      ? featuresState.dashWarningLights
      : publicData.dashWarningLights,
    lastServiceKms: featuresState.lastServiceKms
      ? featuresState.lastServiceKms
      : publicData.lastServiceKms,
    // nextServiceKms: featuresState.nextServiceKms
    //   ? featuresState.nextServiceKms
    //   : publicData.nextServiceKms,
    dashLightImages: dashLightImages,
  };
  const numberOfOwnersOptions = findOptionsForSelectFilter('numberOfOwners', config.custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingHistoryForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        dashLightImages={dashLightImages}
        onSubmit={values => {
          const {
            extendedWarranty,
            expiryDate,
            writtenOffVehicleRegister,
            ppsr,
            numberOfOwners,
            unknownOwner,
            unknownServiceDate,
            lastServiceDate,
            // nextServiceDue,
            dashWarningLights,
            lastServiceKms,
            // nextServiceKms,
          } = values;
          let { dashLightImages } = values;

          dashLightImages = dashWarningLights === 'Yes' ? dashLightImages : [];
          const updateValues = {
            images: [...extraImages, ...dashLightImages],
            publicData: {
              extendedWarranty: extendedWarranty ?? null,
              dashLightImageIds: dashWarningLights ? getImageIds(dashLightImages) : [],
              expiryDate: expiryDate ? expiryDate.toDateString() : null,
              writtenOffVehicleRegister: writtenOffVehicleRegister ?? null,
              ppsr,
              unknownOwner: unknownOwner ?? null,
              numberOfOwners: numberOfOwners ?? null,
              unknownServiceDate: unknownServiceDate ?? null,
              lastServiceDate: lastServiceDate ? lastServiceDate.toDateString() : null,
              // nextServiceDue: nextServiceDue ? nextServiceDue.toDateString() : null,
              dashWarningLights: dashWarningLights ?? null,
              lastServiceKms: lastServiceKms ? Number(lastServiceKms) : null,
              // nextServiceKms: nextServiceKms ? Number(nextServiceKms) : null,
            },
          };

          onSubmit(updateValues);
        }}
        onImageUpload={onImageUpload}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        onFormChange={onFormChange}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        // numberOfOwnersShow={numberOfOwnersShow}
        nextServiceDateShow={nextServiceDateShow}
        numberOfOwnersOptions={numberOfOwnersOptions}
      />
    </div>
  );
};

EditListingHistoryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  images: [],
};

EditListingHistoryPanel.propTypes = {
  className: string,
  rootClassName: string,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingHistoryPanel;
