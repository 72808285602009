import React from 'react';
import PropTypes from 'prop-types';

import css from './Keywords.module.css';

const Keywords = props => {
  const { words, onKeywordClick } = props;
  return (
    <section className={css.keywordsContainer}>
      {words && Array.isArray(words) ? (
        <ul>
          {words.map((word, keywordIndex) => (
            <li key={keywordIndex} onClick={() => onKeywordClick(word)}>
              {word}
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  );
};

Keywords.defaultProps = {
  words: [],
  onKeywordClick: () => null,
};

Keywords.propTypes = {
  words: PropTypes.array.isRequired,
  onKeywordClick: PropTypes.func.isRequired,
};

export default Keywords;
