import React, { Component } from 'react';
import {
  arrayOf,
  func,
  node,
  number,
  object,
  shape,
  string,
  oneOfType,
} from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
// util
import { FormattedMessage } from '../../util/reactIntl';
// component
import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
// css
import css from './SelectSingleFilterPopup.module.css';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.returnParamOptionForYear = this.returnParamOptionForYear.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  returnParamOptionForYear(option, queryParamName) {
    const urlParams = new URLSearchParams(window.location.search);
    const modelYearParam = urlParams.get('pub_modelYear');
    if (modelYearParam) {
      const modelYearArr = modelYearParam.split(',').filter(Boolean);
      if (modelYearParam[modelYearParam.length - 1] === ',') {
        if (queryParamName === 'pub_modelYear_to') {
          option = option ? modelYearParam + option : modelYearParam;
        } else {
          option = option ? option + ',' : null;
        }
      } else if (modelYearParam[0] === ',') {
        if (queryParamName === 'pub_modelYear_from') {
          option = option ? option + modelYearParam : modelYearParam;
        } else {
          option = option ? ',' + option : null;
        }
      } else if (modelYearArr.length === 2) {
        let fromYear = modelYearArr[0],
          toYear = modelYearArr[1];
        if (queryParamName === 'pub_modelYear_from') {
          fromYear = option ? option : '';
        } else {
          toYear = option ? option : '';
        }
        option = `${fromYear},${toYear}`;
      } else {
        if (queryParamName === 'pub_modelYear_from') {
          option = option ? option + ',' : null;
        } else {
          option = option ? ',' + option : null;
        }
      }
    } else {
      if (queryParamName === 'pub_modelYear_from') {
        option = option ? option + ',' : null;
      } else {
        option = option ? ',' + option : null;
      }
    }
    return option;
  }

  selectOption(queryParamName, option) {
    this.setState({ isOpen: false });
    if (
      queryParamName === 'pub_modelYear_from' ||
      queryParamName === 'pub_modelYear_to'
    ) {
      option = this.returnParamOptionForYear(option, queryParamName);
      queryParamName = 'pub_modelYear';
    }
    this.props.onSelect({ [queryParamName]: option });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
    } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames]
        ? initialValues[queryParamNames]
        : null;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={menuLabelClass}>
          {menuLabel} <ExpandMoreIcon className={css.expandMoreIcon} />{' '}
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key={'clearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => this.selectOption(queryParamName, null)}
            >
              <FormattedMessage id={'SelectSingleFilter.popupClear'} />
            </button>
          </MenuItem>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected
              ? css.menuItemBorderSelected
              : css.menuItemBorder;

            return (
              <MenuItem key={`${option.key}_${option.id ?? ''}`}>
                <button
                  className={css.menuItem}
                  onClick={() => this.selectOption(queryParamName, option.key)}
                >
                  <span className={menuItemBorderClass} />
                  {option.label}
                </button>
              </MenuItem>
            );
          })}
        </MenuContent>
      </Menu>
    );
  }
}

SelectSingleFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectSingleFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: oneOfType([string.isRequired, number.isRequired]),
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default SelectSingleFilterPopup;
