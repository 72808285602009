import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
// util
import { LISTING_STATE_DRAFT } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
// component
import { ListingLink } from '../../components';
import { EditListingPhotosForm } from '../../forms';
// css
import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
    } = this.props;

    let images = this.props.images;
    const extraImages = [];

    const getImageArr = (images = [], type = '', ids = []) => {
      let imgArr = [];
      if (images && Array.isArray(images) && images.length > 0) {
        imgArr = images.filter(imgObj => {
          if (imgObj && imgObj.id) {
            if (
              typeof imgObj.id === 'string' &&
              imgObj.id.split('_')[2] === type
            ) {
              return true;
            } else if (
              typeof imgObj.id === 'object' &&
              imgObj.id.uuid &&
              ids &&
              ids.length > 0 &&
              ids.includes(imgObj.id.uuid)
            ) {
              return true;
            } else {
              extraImages.push(imgObj);
            }
          }
          return false;
        });
      }
      return imgArr;
    };

    const getImageIds = (images = []) => {
      let ids = [];
      if (images && images.length > 0 && Array.isArray(images)) {
        images.forEach(imgObj => {
          if (imgObj && imgObj.imageId && imgObj.imageId.uuid) {
            ids.push(imgObj.imageId.uuid);
          } else if (imgObj && imgObj.id && imgObj.id.uuid) {
            ids.push(imgObj.id.uuid);
          }
        });
      }
      return ids;
    };

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;
    const listingImageIds = publicData.listingImageIds ?? null;

    const isPublished =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    images = getImageArr(images, 'addImage', listingImageIds);
    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { images } = values;
            const updatedValues = {
              images: [...extraImages, ...images],
              publicData: {
                listingImageIds: getImageIds(images),
              },
            };

            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          listing={listing}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
