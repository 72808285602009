import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// css
import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: April 10, 2021</p>

      <ol className={classNames(css.orderedList, css.levelOne)} type="1">
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Introduction
          <p>
            Welcome to{' '}
            <a href="mailto:www.wholesaleitauto.com.au">
              www.wholesaleitauto.com.au
            </a>
            . These Terms of Use and the Privacy Policy posted on our site set out
            the terms on which we offer you access and use our site and services.
            When accessing or using our services you agree to comply with the full
            Terms of use. The Terms of Use set out below take effect from 22 April
            2021.
          </p>
        </li>

        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Your Account
          <p>
            To access and use the Services provided by Wholesale It, you will be
            required to register with us and set up an account with personal
            information such as but not limited to: contact name, business trading
            name, LMCT number, email address, phone number and password. The
            information including the email address you register with will be your
            email address, and you are solely responsible for maintaining the
            confidentiality of your password. You are solely responsible for all
            activities that happen under your Account. If you believe your Account
            has been compromised contact us immediately at{' '}
            <a href="mailto:info@wholesaleitauto.com.au">
              info@wholesaleitauto.com.au
            </a>
            .
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Using Wholesale IT
          <p>
            The Company does not permit those under 18 to use the Service. You agree
            that you will only post vehicles that you legally own and abide by state
            laws. The Company is not responsible for the content of the Service's
            users. You expressly understand and agree that You are solely
            responsible for the Content and for all activity that occurs under your
            account, whether done so by You or any third person using Your account.
          </p>
          <p>
            You may not transmit any Content that is unlawful, offensive, upsetting,
            intended to disgust, threatening, libellous, defamatory, obscene, or
            otherwise objectionable. Examples of such objectionable Content include,
            but are not limited to, the following:
          </p>
          <ul className={css.unorderedList}>
            <li>Unlawful or promoting unlawful activity.</li>
            <li>
              Defamatory, discriminatory, or mean-spirited content, including
              references or commentary about religion, race, sexual orientation,
              gender, national/ethnic origin, or other targeted groups.
            </li>
            <li>
              Spam, machine – or randomly – generated, constituting unauthorized or
              unsolicited advertising, chain letters, any other form of unauthorized
              solicitation, or any form of lottery or gambling.
            </li>
            <li>
              Containing or installing any viruses, worms, malware, trojan horses,
              or other content that is designed or intended to disrupt, damage, or
              limit the functioning of any software, hardware or telecommunications
              equipment or to damage or obtain unauthorized access to any data or
              other information of a third person.
            </li>
            <li>
              Infringing on any proprietary rights of any party, including patent,
              trademark, trade secret, copyright, right of publicity or other
              rights.
            </li>
            <li>
              Impersonating any person or entity including the Company and its
              employees or representatives.
            </li>
            <li>Violating the privacy of any third person.</li>
            <li>False information and features.</li>
          </ul>
          <p>
            The Company reserves the right, but not the obligation, to, in its sole
            discretion, determine whether or not any Content is appropriate and
            complies with this Terms, refuse or remove this Content. The Company
            further reserves the right to make formatting and edits and change the
            manner any Content. The Company can also limit or revoke the use of the
            Service if You post such objectionable Content. As the Company cannot
            control all content posted by users and/or third parties on the Service,
            you agree to use the Service at your own risk. You understand that by
            using the Service You may be exposed to content that You may find
            offensive, indecent, incorrect or objectionable, and You agree that
            under no circumstances will the Company be liable in any way for any
            content, including any errors or omissions in any content, or any loss
            or damage of any kind incurred as a result of your use of any content.
          </p>
        </li>

        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Termination of Use
          <p>
            Wholesale It aims to provide a safe environment for its users, and we
            work closely to maintain ethical practices. You are solely responsible
            for the information you present on your listings and the information you
            provide to Wholesale It. We may terminate or suspend Your access
            immediately, without prior notice or liability, for any reason
            whatsoever, including without limitation if You breach these Terms and
            Conditions.
          </p>
          <p>
            Upon termination, Your right to use the Service will cease immediately.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Payment and Fees
          <p>
            Using the services provided by Wholesale It are generally free including
            the ability to browse listings, make bids and sign up. Fees are only
            charged to the seller on the sale of an item once you have accepted a
            bid. These fees are quoted in Australian dollars and are subject to
            change. Fees and are non-refundable once the sale has been confirmed.
          </p>
          <p>
            These fees are required to be payable immediately upon selection and
            confirmation of a bid and are subject to a one-time payment. Payment can
            be made through various payment methods we have available, such as Visa,
            MasterCard, AMEX. Only once the transaction is completed and funds
            received will the information relevant to the buyer be released. If your
            account is past due, we may collect fees owed using collection methods
            such as third-party debt collection services.
          </p>
          <p>
            Payment cards (credit cards or debit cards) are subject to validation
            checks and authorization by Your card issuer. If we do not receive the
            required authorization, we will not be liable for any delay or
            non-delivery of Your Order.
          </p>
          <p>
            The Company reserves the right to revise its prices at any time prior to
            accepting an Order.
          </p>
          <p>
            Upon acceptance of an offer the seller agrees to send an invoice to the
            buyer within 48 hours to the email provided in the platform.
          </p>
          <p>
            The buyer has 48 hours to provide a copy of the receipt of payment from
            the time of receiving the invoice to the seller, unless direct contact
            is made and alternative arrangements are made that suit the requirements
            of both parties.
          </p>
          <p>
            Users accept by using the Wholesale It platform that all vehicles are to
            be paid in full before delivery is taken.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Content
          <p>
            Our Service allows You to post Content. You are responsible for the
            Content that You post to the Service, including its legality,
            reliability, and appropriateness.
          </p>
          <p>
            By posting Content to the Service, You grant Us the right and license to
            use, modify, publicly perform, publicly display, reproduce, and
            distribute such Content on and through the Service. You retain any and
            all of Your rights to any Content You submit, post or display on or
            through the Service and You are responsible for protecting those rights.
            You agree that this license includes the right for Us to make Your
            Content available to other users of the Service, who may also use Your
            Content subject to these Terms.
          </p>
          <p>
            You represent and warrant that: (i) the Content is Yours (You own it) or
            You have the right to use it and grant Us the rights and license as
            provided in these Terms, and (ii) the posting of Your Content on or
            through the Service does not violate the privacy rights, publicity
            rights, copyrights, contract rights or any other rights of any person.
            Although regular backups of Content are performed, the Company do not
            guarantee there will be no loss or corruption of data.
          </p>
          <p>
            Corrupt or invalid backup points may be caused by, without limitation,
            Content that is corrupted prior to being backed up or that changes
            during the time a backup is performed.
          </p>
          <p>
            The Company will provide support and attempt to troubleshoot any known
            or discovered issues that may affect the backups of Content. But You
            acknowledge that the Company has no liability related to the integrity
            of Content or the failure to successfully restore Content to a usable
            state.
          </p>
          <p>
            You agree to maintain a complete and accurate copy of any Content in a
            location independent of the Service.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Intellectual Property
          <p>
            The Service and its original content (excluding Content provided by You
            or other users), features and functionality are and will remain the
            exclusive property of the Company and its licensors.
          </p>
          <p>
            The Service is protected by copyright, trademark, and other laws of both
            the Country and foreign countries.
          </p>
          <p>
            Our trademarks and trade dress may not be used in connection with any
            product or service without the prior written consent of the Company.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Limitation of Liability
          <p>
            To the maximum extent permitted by applicable law, in no event shall the
            Company be liable for any special, incidental, indirect, or
            consequential damages whatsoever (including, but not limited to, damages
            for loss of profits, loss of data or other information, for business
            interruption, for personal injury, loss of privacy arising out of or in
            any way related to the use of or inability to use the Service,
            third-party software and/or third-party hardware used with the Service,
            or otherwise in connection with any provision of this Terms), even if
            the Company or any supplier has been advised of the possibility of such
            damages and even if the remedy fails of its essential purpose.
          </p>
          <p>
            The Service is provided to You "AS IS" and "AS AVAILABLE" and as such
            you agree not to hold Wholesale It or subsidiaries responsible for any
            information listed by users or their actions. As majority of the content
            on the Services is produced by other users, we do not guarantee the
            accuracy of listings or the quality, safety, or legality of what is
            offered and with all faults and defects without warranty of any kind. To
            the maximum extent permitted under applicable law, the Company, on its
            own behalf and on behalf of its Affiliates and its and their respective
            licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to the
            Service, including all implied warranties of merchantability, fitness
            for a particular purpose, title and non-infringement, and warranties
            that may arise out of course of dealing, course of performance, usage or
            trade practice. Without limitation to the foregoing, the Company
            provides no warranty or undertaking, and makes no representation of any
            kind that the Service will meet Your requirements, achieve any intended
            results, be compatible or work with any other software, applications,
            systems or services, operate without interruption, meet any performance
            or reliability standards or be error free or that any errors or defects
            can or will be corrected.
          </p>
          <p>
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied: (i) as to the operation or availability of the
            Service, or the information, content, and materials or products included
            thereon; (ii) that the Service will be uninterrupted or error-free;
            (iii) as to the accuracy, reliability, or currency of any information or
            content provided through the Service; or (iv) that the Service, its
            servers, the content, or e-mails sent from or on behalf of the Company
            are free of viruses, scripts, trojan horses, worms, malware, timebombs
            or other harmful components.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Links to other websites
          <p>
            Our Service may contain links to third-party web sites or services that
            are not owned or controlled by the Company.
          </p>
          <p>
            The Company has no control over, and assumes no responsibility for, the
            content, privacy policies, or practices of any third-party web sites or
            services. You further acknowledge and agree that the Company shall not
            be responsible or liable, directly, or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use of
            or reliance on any such content, goods or services available on or
            through any such web sites or services.
          </p>
          <p>
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Release
          <p>
            If you have a dispute with another Wholesale It users you release us
            (subsidiaries, directors and employees) or every and any claims and
            damages.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Personal Information
          <p>
            If You wish to place an Order for Goods available on the Service, You
            may be asked to supply certain information relevant to Your Order
            including, without limitation, Your name, Your email, Your phone number,
            Your credit card number, the expiration date of Your credit card, Your
            billing address, and Your shipping information.
          </p>
          <p>
            You represent and warrant that: (i) You have the legal right to use any
            credit or debit card(s) or other payment method(s) in connection with
            any Order; and that (ii) the information You supply to us is true,
            correct, and complete.
          </p>
          <p>
            By submitting such information, You grant us the right to provide the
            information to payment processing third parties for purposes of
            facilitating the completion of Your Order.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Severability
          <p>
            If any provision of these Terms is held to be unenforceable or invalid,
            such provision will be changed and interpreted to accomplish the
            objectives of such provision to the greatest extent possible under
            applicable law and the remaining provisions will continue in full force
            and effect.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Changes to these Terms and Conditions
          <p>
            We reserve the right, at Our sole discretion, to modify or replace these
            Terms at any time. If a revision is material, we will make reasonable
            efforts to provide at least 30 days' notice prior to any new terms
            taking effect. What constitutes a material change will be determined at
            Our sole discretion.
          </p>
          <p>
            By continuing to access or use Our Service after those revisions become
            effective, You agree to be bound by the revised terms. If You do not
            agree to the new terms, in whole or in part, please stop using the
            website and the Service.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Intellectual Property
          <p>
            Wholesale It respects the intellectual property rights of others. It is
            Our policy to respond to any claim that Content posted on the Service
            infringes a copyright or other intellectual property infringement of any
            person.
          </p>
          <p>
            If You are a copyright owner, or authorized on behalf of one, and You
            believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement that is taking place through the
            Service, You must submit Your notice in writing to the attention of our
            copyright agent via email at{' '}
            <a href="mailto:tom.busler@wholesaleitauto.com.au">
              tom.busler@wholesaleitauto.com.au
            </a>{' '}
            and include in Your notice a detailed description of the alleged
            infringement.
          </p>
          <p>
            You may be held accountable for damages (including costs and attorneys'
            fees) for misrepresenting that any Content is infringing Your copyright.
          </p>
        </li>
        <li className={classNames(css.listItem, css.listItemLevelOne)}>
          Contact Us
          <p>
            If you have any questions about these Terms and Conditions, you can
            contact us
          </p>
          <p>
            By email:{' '}
            <a href="mailto:info@wholesaleitauto.com.au">
              info@wholesaleitauto.com.au
            </a>
          </p>
        </li>
      </ol>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
