import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
// components
import { ExternalLink } from '../../components';
// assets
import image from '../../assets/background-1440.jpg';
// css
import css from './BlogCard.module.css';

export const BlogCard = props => {
  const { className, blog, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  let publishDate = null;

  try {
    publishDate = blog.pubDate
      ? moment(new Date(blog.pubDate)).format('MMM DD, yyyy')
      : moment(new Date()).format('MMM DD, yyyy');
  } catch (err) {
    publishDate = moment(+new Date()).format('MMM DD, yyyy');
  }

  return (
    <ExternalLink
      className={`${classes} ${css.blogCard}`}
      href={props.blog && props.blog.link}
    >
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <img
            className={css.rootForImage}
            alt={'Blog Image'}
            src={blog.articleImg}
            onError={e => {
              e.target.src = image;
            }}
          />
        </div>
        <div className={css.blogDetails}>
          <p>{blog.category ? blog.category : ''}</p>
          <h3>{blog.title ? blog.title : ''}</h3>
          {blog.authorName && !blog.authorName.includes('nonym') ? (
            <p className={css.author}>
              {publishDate} by{' '}
              <span className={css.authorName}>
                {blog.authorName ? blog.authorName : 'Anonymous'}
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </ExternalLink>
  );
};

BlogCard.defaultProps = {};

BlogCard.propTypes = {};

export default BlogCard;
