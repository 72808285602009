import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// config
import config from '../../config';
// util
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import {
  unitDivisor,
  convertUnitToSubUnit
} from '../../util/currency';
// component
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
// css
import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
          convertUnitToSubUnit(
            unformattedValue,
            unitDivisor(currencyConfig.currency)
          ),
          currencyConfig.currency
        );
  } catch (e) {
    return null;
  }
};

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const [featuresState, setFeaturesState] = useState({});
  const { price, metadata, publicData } = currentListing.attributes;
  let { instantPrice } = publicData;

  instantPrice = getPrice(instantPrice / 100, config.currencyConfig);
  const getSelectedOption = (selectedOption = '', options = '') => {
    let ret = 'Not Provided';
    if (selectedOption && options && options.length > 0) {
      ret = options.find(x => x.key === selectedOption);
      ret = ret ? ret.label : 'Not Provided';
    }
    return ret;
  };
  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const gotListingType = publicData.listingType ? true : false;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const onFormChange = value => {
    if (
      value.active &&
      value.values[value.active] &&
      value.values[value.active] != featuresState[value.active]
    ) {
      setFeaturesState({
        ...featuresState,
        [value.active]: value.values[value.active],
      });
    }
  };

  const listingTypeOptions = findOptionsForSelectFilter(
    'listingType',
    config.custom.filters
  );
  const priceCurrencyValid =
    price instanceof Money ? price.currency === config.currency : true;
  const instancePriceCurrencyValid =
    instantPrice instanceof Money
      ? instantPrice.currency === config.currency
      : true;
  const form =
    priceCurrencyValid && instancePriceCurrencyValid ? (
      <EditListingPricingForm
        className={css.form}
        initialValues={{
          price: featuresState.price ? featuresState.price : price,
          instantPrice: featuresState.instantPrice
            ? featuresState.instantPrice
            : instantPrice,
          listingType: featuresState.listingType
            ? featuresState.listingType
            : publicData.listingType,
          selectedListingType: publicData.listingType
            ? getSelectedOption(publicData.listingType, listingTypeOptions)
            : '',
        }}
        onSubmit={values => {
          const { price, listingType, instantPrice } = values;
          const listingT = listingType ? listingType : publicData.listingType;

          onSubmit({
            price: listingT !== 'valuation' ? price : null,
            publicData: {
              listingType: listingT,
              instantPrice:
                listingT !== 'valuation' && instantPrice
                  ? instantPrice.amount
                  : null,
            },
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        metadata={metadata}
        gotListingType={gotListingType}
        listingTypeOptions={listingTypeOptions}
        onFormChange={onFormChange}
        fetchErrors={errors}
      />
    ) : (
      <div className={css.priceCurrencyInvalid}>
        <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
      </div>
    );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
