import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Snackbar, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// config
import config from '../../config';
// util
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter, findOptionsForSelectFilterDependent } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
// component
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import {
  requestFetchCarData,
  stolenCarCheck,
  clearCarData,
} from '../EditListingFeaturesPanel/EditListingFeaturesPanel.duck';
// css
import css from './EditListingDescriptionPanel.module.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    zIndex: '2',
  },
}));

const EditListingDescriptionPanelComponent = props => {
  const classesMUI = useStyles();
  const [showError, setShowError] = useState(false);
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    editlistingData,
    marketPlaceData,
    fetchCarData,
    stolenCheck,
    clearData,
    errors,
  } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchCarDataError(null);
    setShowError(false);
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const [carVerified, setCarVerified] = useState(false);
  useEffect(() => {
    if (publicData && publicData.vin && !carVerified) {
      setCarVerified(true);
    }
  }, [publicData]);

  const [carVerifiedValue, setCarVerifiedValue] = useState(publicData.vin ?? '');
  const [carVerifiedRegistrationValue, setCarVerifiedRegistrationValue] = useState(
    publicData.registration ?? ''
  );
  const [carData, setCarData] = useState(marketPlaceData.carData);
  const [initialVin, setVin] = useState(publicData.vin ? publicData.vin : '');
  const [initialRegistration, setRegistration] = useState(
    publicData.registration ? publicData.registration : ''
  );
  const [registrationState, setRegistrationState] = useState(
    publicData.registrationState ? publicData.registrationState : ''
  );
  const [carModel, setCarModel] = useState(publicData.carModel ?? '');
  const [carBadge, setCarBadge] = useState(publicData.carBadge ?? '');
  const { fetchingCarData, fetchingStolenCarCheckData } = editlistingData;
  const [showLoading, setLoading] = useState(false);
  const [featuresState, setFeaturesState] = useState(publicData);
  const [colour, setColour] = useState(
    featuresState.colour
      ? featuresState.colour
      : publicData.colour
      ? publicData.colour
      : carData && carData.colour
      ? carData.colour
      : ''
  );
  const [interiorcolour, setInteriorcolour] = useState(
    featuresState.interiorcolour
      ? featuresState.interiorcolour
      : publicData.interiorcolour
      ? publicData.interiorcolour
      : ''
  );
  const [isRegistered, setisRegistered] = useState(
    featuresState.isRegistered
      ? featuresState.isRegistered
      : publicData.isRegistered
      ? publicData.isRegistered
      : 'Yes'
  );
  const [registrationExpiryShow, setregistrationExpiryShow] = useState(featuresState.isRegistered
    ? featuresState.isRegistered === 'Yes'
    : publicData.isRegistered
    ? publicData.isRegistered === 'Yes' : true);
  const [fetchCarDataError, setFetchCarDataError] = useState(
    editlistingData.fetchCarDataError ? editlistingData.fetchCarDataError.message : ''
  );
  const getSelectedOption = (selectedOption = '', options = '') => {
    let ret = 'Not Provided';
    if (selectedOption && options && options.length > 0) {
      ret = options.find(x => x.key === selectedOption);
      ret = ret ? ret.label : 'Not Provided';
    }
    return ret;
  };

  const getSelectedState = (state, type = false) => {
    if (!type) {
      switch (state) {
        case '1':
          return 'NSW';
        case '2':
          return 'QLD';
        case '3':
          return 'SA';
        case '4':
          return 'TAS';
        case '5':
          return 'VIC';
        case '6':
          return 'WA';
        case '7':
          return 'ACT';
        case '8':
          return 'NT';
        default:
          return false;
      }
    } else {
      switch (type) {
        case 'NSW':
          return '1';
        case 'QLD':
          return '2';
        case 'SA':
          return '3';
        case 'TAS':
          return '4';
        case 'VIC':
          return '5';
        case 'WA':
          return '6';
        case 'ACT':
          return '7';
        case 'NT':
          return '8';
        default:
          return false;
      }
    }
  };

  const checkStolenCar = data => {
    if (data && data.registration) {
      const registration = data.registration.plate,
        state = data.registration.state;
      stolenCheck({ registration, state });
    } else {
      setFetchCarDataError(`We couldn't verify your car. Please try again later.`);
      setShowError(true);
    }
  };

  useEffect(() => {
    if (
      marketPlaceData.carData &&
      typeof marketPlaceData.carData === 'object' &&
      Object.keys(marketPlaceData.carData).length > 0 &&
      (initialVin || initialRegistration) &&
      !carVerified
    ) {
      setCarData(marketPlaceData.carData);
      if (marketPlaceData.carData.model && marketPlaceData.carData.make) {
        setCarModel(
          `${(marketPlaceData.carData.make + '').replace(/\s/g, '').toLowerCase()}_${(
            marketPlaceData.carData.model + ''
          )
            .replace(/\s/g, '')
            .toLowerCase()}`
        );
      }
      setCarVerifiedValue('');
      setCarVerifiedRegistrationValue('');
      setCarVerified(false);
      checkStolenCar(marketPlaceData.carData);
    }
  }, [marketPlaceData.carData]);

  useEffect(() => {
    if (
      marketPlaceData.stolenCarCheckData &&
      typeof marketPlaceData.stolenCarCheckData === 'object' &&
      Object.keys(marketPlaceData.stolenCarCheckData).length > 0
    ) {
      if (marketPlaceData.stolenCarCheckData.stolen) {
        setCarVerifiedValue('');
        setCarVerifiedRegistrationValue('');
        setCarVerified(false);
        setCarData({});
        setCarModel('');
        setCarBadge('');
        setFetchCarDataError(
          `Sorry but we can't continue with this car. Car was reported stolen on ${marketPlaceData.stolenCarCheckData.stolen[0].reported_date}`
        );
      } else {
        setCarVerified(true);
        setCarVerifiedValue(initialVin ? initialVin : carData.vin ? carData.vin : '');
        setCarVerifiedRegistrationValue(
          initialRegistration
            ? initialRegistration
            : carData.registration && carData.registration.plate
            ? carData.registration.plate
            : ''
        );
        setFetchCarDataError(null);
      }
    }
  }, [marketPlaceData.stolenCarCheckData]);

  useEffect(() => {
    if (fetchingCarData || fetchingStolenCarCheckData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetchingCarData, fetchingStolenCarCheckData]);

  useEffect(() => {
    if (fetchCarDataError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [fetchCarDataError]);

  useEffect(() => {
    if (
      (!publicData &&
        (marketPlaceData.carData &&
          typeof marketPlaceData.carData === 'object' &&
          Object.keys(marketPlaceData.carData).length > 0)) ||
      (marketPlaceData.stolenCarCheckData &&
        typeof marketPlaceData.stolenCarCheckData === 'object' &&
        Object.keys(marketPlaceData.stolenCarCheckData).length > 0)
    ) {
      clearData();
    }
  }, []);

  useEffect(() => {
    if (editlistingData.fetchCarDataError && editlistingData.fetchCarDataError.message) {
      setFetchCarDataError(editlistingData.fetchCarDataError.message);
    }
  }, [editlistingData.fetchCarDataError]);

  const verifyCar = value => {
    if (value && (value.vin || (value.registration && value.state))) {
      let valueObj = value.vin
        ? { vin: value.vin }
        : {
            registration: value.registration,
            state: getSelectedState(value.state),
          };
      fetchCarData(valueObj);
      if (value.vin) {
        setVin(value.vin);
      } else {
        setRegistration(value.registration);
        setRegistrationState(value.state);
      }
    }
  };

  const onFormChange = value => {
    if (value.active === 'initialVin') {
      const currentVin = value.values.initialVin;
      if (currentVin != carVerifiedValue) {
        setRegistration('');
        setRegistrationState('');
        setFetchCarDataError(null);
        setCarVerified(false);
      } else if (carVerifiedValue && currentVin === carVerifiedValue) {
        setCarVerified(true);
      }
      setVin(currentVin);
    } else if (value.active === 'initialRegistration') {
      const currentRegistration = value.values.initialRegistration;

      if (currentRegistration != carVerifiedRegistrationValue) {
        setVin('');
        setFetchCarDataError(null);
        setCarVerified(false);
      } else if (
        carVerifiedRegistrationValue &&
        currentRegistration === carVerifiedRegistrationValue
      ) {
        setCarVerified(true);
      }
      setRegistration(currentRegistration);
    } else if (value.active === 'initialRegistrationState') {
      const currentRegistrationState = value.values.initialRegistrationState;
      setRegistrationState(currentRegistrationState);
    } else if (value.active && value.values[value.active] != featuresState[value.active]) {
      if (value.active === 'make') {
        setCarModel('');
        setCarBadge('');
        setFeaturesState({
          ...featuresState,
          carModel: '',
          carBadge: '',
          [value.active]: value.values[value.active],
        });
      } else if (value.active === 'carModel') {
        setCarBadge('');
        setCarModel(value.values[value.active]);
        setFeaturesState({
          ...featuresState,
          carBadge: '',
          [value.active]: value.values[value.active],
        });
      } else {
        if (value.active === 'vin' && value.values[value.active] != carVerifiedValue) {
          setVin(value.values[value.active]);
          setRegistration('');
          setRegistrationState('');
          setFetchCarDataError(null);
          setCarVerified(false);
        } else if (
          value.active === 'registration' &&
          value.values[value.active] != carVerifiedRegistrationValue
        ) {
          setRegistration(value.values[value.active]);
          setVin('');
          setRegistrationState('');
          setFetchCarDataError(null);
          setCarVerified(false);
        } else if (value.active === 'colour') {
          setColour(value.values[value.active]);
        } else if (value.active === 'interiorcolour') {
          setInteriorcolour(value.values[value.active]);
        } else if (value.active === 'isRegistered') {
          setregistrationExpiryShow(value.values[value.active] === 'Yes' ? true : false);
          setisRegistered(value.values[value.active]);
        }
        setFeaturesState({
          ...featuresState,
          [value.active]: value.values[value.active],
        });
      }
    }
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : carVerified ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.createListingTitle"
      values={{ action: 'Add' }}
    />
  ) : (
    <FormattedMessage
      id="EditListingDescriptionPanel.createListingTitle"
      values={{ action: 'Verify' }}
    />
  );
  const carMakes = findOptionsForSelectFilter('make', config.custom.filters);
  const transmissionOptions = findOptionsForSelectFilter('transmission', config.custom.filters);
  const bodyTypeOptions = findOptionsForSelectFilter('body-type', config.custom.filters);
  const drivingWheelsOptions = findOptionsForSelectFilter('drivingWheels', config.custom.filters);
  const interiorOptions = findOptionsForSelectFilter('interiorType', config.custom.filters);
  const registrationStateOptions = findOptionsForSelectFilter('states', config.custom.filters);
  const fuelTypeOptions = findOptionsForSelectFilter('fuelType', config.custom.filters);
  const make = featuresState.make
    ? (featuresState.make + '').replace(/\s/g, '').toLowerCase()
    : publicData.make
    ? (publicData.make + '').replace(/\s/g, '').toLowerCase()
    : carData && carData.make
    ? (carData.make + '').replace(/\s/g, '').toLowerCase()
    : '';

  const initialValues = {
    modelYear: featuresState.modelYear
      ? new Date(featuresState.modelYear + '')
      : publicData.modelYear
      ? new Date(publicData.modelYear + '')
      : new Date(),
    carModel: featuresState.carModel ? featuresState.carModel : carModel,
    carBadge: featuresState.carBadge ? featuresState.carBadge : carBadge,
    vin: carVerifiedValue ? carVerifiedValue : carData.vin ? carData.vin : '',
    make: make,
    kilometers: featuresState.kilometers ? featuresState.kilometers : publicData.kilometers,
    transmission: featuresState.transmission ? featuresState.transmission : publicData.transmission,
    colour: colour,
    bodyType: featuresState.bodyType ? featuresState.bodyType : publicData.bodyType,
    registration: carVerifiedRegistrationValue
      ? carVerifiedRegistrationValue
      : carData.registration && carData.registration.plate
      ? carData.registration.plate
      : '',
    registrationState: featuresState.registrationState
      ? featuresState.registrationState
      : publicData.registrationState
      ? publicData.registrationState
      : carData && carData.registration && carData.registration.state
      ? getSelectedState(null, carData.registration.state)
      : '',
    registrationExpiry: featuresState.registrationExpiry
      ? new Date(featuresState.registrationExpiry)
      : new Date(publicData.registrationExpiry),
    enginenumber: featuresState.enginenumber
      ? featuresState.enginenumber
      : publicData.enginenumber
      ? publicData.enginenumber
      : carData && carData.engine_number
      ? carData.engine_number
      : '',
    buildDate: featuresState.buildDate
      ? new Date(featuresState.buildDate)
      : new Date(publicData.buildDate),
    complianceDate: featuresState.complianceDate
      ? new Date(featuresState.complianceDate)
      : new Date(publicData.complianceDate),
    fuelType: featuresState.fuelType ? featuresState.fuelType : publicData.fuelType,
    wheelSize: featuresState.wheelSize ? featuresState.wheelSize : publicData.wheelSize,
    drivingWheels: featuresState.drivingWheels
      ? featuresState.drivingWheels
      : publicData.drivingWheels,
    interiorType: featuresState.interiorType ? featuresState.interiorType : publicData.interiorType,
    interiorcolour: interiorcolour,
    isRegistered: featuresState.isRegistered ? featuresState.isRegistered : publicData.isRegistered,
    initialVin: initialVin ? initialVin : '',
    initialRegistration: initialRegistration ? initialRegistration : '',
    initialRegistrationState: registrationState ? registrationState : '',
  };
  let carModels = findOptionsForSelectFilterDependent('model', config.custom.filters, make);
  let carBadges = findOptionsForSelectFilterDependent('badge', config.custom.filters, carModel);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        onFormChange={onFormChange}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            modelYear,
            carModel,
            carBadge,
            vin,
            make,
            kilometers,
            transmission,
            colour,
            bodyType,
            registration,
            registrationState,
            registrationExpiry,
            enginenumber,
            buildDate,
            complianceDate,
            fuelType,
            wheelSize,
            drivingWheels,
            interiorType,
            interiorcolour,
            isRegistered,
          } = values;
          const updateValues = {
            title: `${getSelectedOption(make, carMakes)} ${getSelectedOption(carModel, carModels)}`,
            description: '',
            // change this VIN to vin when blueflag account verified
            publicData: {
              modelYear:
                modelYear && modelYear instanceof Date ? modelYear.getFullYear() : modelYear,
              carModel,
              carBadge,
              vin,
              make: make.replace(/\s/g, '').toLowerCase(),
              kilometers: Number(kilometers),
              transmission,
              colour: colour ?? null,
              bodyType,
              registration,
              registrationState,
              registrationExpiry: registrationExpiry.toDateString(),
              enginenumber: enginenumber ?? null,
              buildDate: buildDate.toDateString(),
              complianceDate: complianceDate.toDateString(),
              fuelType,
              wheelSize: wheelSize ?? null,
              drivingWheels,
              interiorType,
              interiorcolour: interiorcolour ?? null,
              isRegistered: isRegistered ?? null,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        carMakes={carMakes}
        carModels={carModels}
        carBadges={carBadges}
        bodyTypeOptions={bodyTypeOptions}
        transmissionOptions={transmissionOptions}
        vin={initialVin}
        registration={initialRegistration}
        registrationState={registrationState}
        registrationStateOptions={registrationStateOptions}
        drivingWheelsOptions={drivingWheelsOptions}
        interiorOptions={interiorOptions}
        fuelTypeOptions={fuelTypeOptions}
        verifyCar={verifyCar}
        carVerified={carVerified}
        showLoading={showLoading}
        colour={colour}
        interiorcolour={interiorcolour}
        isRegistered={isRegistered}
        registrationExpiryShow={registrationExpiryShow}
      />
      <div className={classesMUI.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showError}
          autoHideDuration={6000}
          onClose={handleClose}
          key={'bottom' + 'center'}
        >
          <Alert onClose={handleClose} severity="error">
            {fetchCarDataError}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

EditListingDescriptionPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  return {
    marketPlaceData: state.marketplaceData,
    editlistingData: state.EditListingFeaturesPanel,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCarData: params => dispatch(requestFetchCarData(params)),
  stolenCheck: params => dispatch(stolenCarCheck(params)),
  clearData: params => dispatch(clearCarData()),
});

const EditListingDescriptionPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditListingDescriptionPanelComponent);

export default EditListingDescriptionPanel;
