/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// route
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
// util
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge
} from '../../components';
import { Modal } from '../../components';
import LmctVerification from '../ModalMissingInformation/LmctNotVerified';
// css
import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    getNotificationError,
    getNotificationInProgress,
    notifications,
    notificationCountFirebase,
    unSeenNotifications,
    allNotifications,
    allNotificationsCount
  } = props;
  const [isLMCTMobileVerifyOpen, setIsLMCTMobileVerifyOpen] = useState(false);

  const user = ensureCurrentUser(currentUser);
  const userId = user.id && user.id.uuid ? user.id.uuid : '';

  const lmctUnverified =
    !!currentUser &&
    !!currentUser.id &&
    !!currentUser.attributes.profile &&
    !!currentUser.attributes.profile.publicData &&
    !currentUser.attributes.profile.publicData.lmctVerified;

  const showLmctMobileVerifyModal = e => {
    e.preventDefault();
    setIsLMCTMobileVerifyOpen(true);
  };

  const handleisLMCTMobileVerifyClose = () => {
    setIsLMCTMobileVerifyOpen(false);
  };
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.signupOrLogin"
          values={{ signup, login }}
        />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          {lmctUnverified ? (
            <a
              className={css.createNewListingLink}
              onClick={showLmctMobileVerifyModal}
            >
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </a>
          ) : (
            <NamedLink
              className={css.createNewListingLink}
              name="NewListingPage"
            >
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          )}
        </div>
      </div>
    );
  }
  const notificationCountBadge =
  allNotificationsCount !== null && allNotificationsCount >= 0 ? (
      <NotificationBadge
        className={css.notificationBadge}
        count={notificationCount}
        notificationCount={notificationCount}
        getNotificationError={getNotificationError}
        getNotificationInProgress={getNotificationInProgress}
        notifications={notifications}
        notificationCountFirebase={notificationCountFirebase}
        unSeenNotifications={unSeenNotifications}
        allNotifications={allNotifications}
        allNotificationsCount={allNotificationsCount}
      />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage
            id="TopbarMobileMenu.greeting"
            values={{ displayName }}
          />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink className={css.inbox} name="dashboard">
          <span>Dashboard</span>
        </NamedLink>
        <NamedLink className={css.inbox} name="BlogsPage">
          <span>News</span>
        </NamedLink>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            currentPageClass('ManageListingsPage')
          )}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            currentPageClass('ProfileSettingsPage')
          )}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            currentPageClass('AccountSettingsPage')
          )}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      <div className={css.footer}>
        {lmctUnverified ? (
          <a
            className={css.createNewListingLink}
            onClick={showLmctMobileVerifyModal}
          >
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </a>
        ) : (
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        )}
      </div>
      <Modal
        id="FooterLMCTVerify"
        isOpen={isLMCTMobileVerifyOpen}
        onClose={handleisLMCTMobileVerifyClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <LmctVerification />
      </Modal>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  notificationCountFirebase: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  notificationCountFirebase: number.isRequired,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default TopbarMobileMenu;
