import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import { BlogCard } from '../../components';
// css
import css from './BlogsSearchResultsPanel.module.css';

const BlogsSearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    pathname,
    blogs,
    totalLength,
    showMore,
    handleShowMore,
    sortBlogsByTime,
    sort,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <section className={css.filterSection}>
        {blogs && typeof blogs.length == 'number' ? (
          <p>
            Showing {blogs.length <= totalLength ? blogs.length : 0} of {totalLength}
          </p>
        ) : null}
        <p onClick={() => sortBlogsByTime()}>{`Sort(${sort ? 'asc' : 'desc'})`}</p>
      </section>
      <ul className={css.listingCards}>
        {blogs.map((blog, blogIndex) => (
          <li className={css.liWidth} key={blogIndex}>
            <BlogCard pathname={pathname} blog={blog} />
          </li>
        ))}
      </ul>
      {showMore ? (
        <button className={css.showMore} onClick={handleShowMore}>
          Show more
        </button>
      ) : null}
    </div>
  );
};

BlogsSearchResultsPanel.defaultProps = {
  blogs: {},
  showMore: false,
  handleShowMore: () => {},
};

const { array, bool, number, func } = PropTypes;

BlogsSearchResultsPanel.propTypes = {
  blogs: array.isRequired,
  showMore: bool.isRequired,
  handleShowMore: func.isRequired,
  totalLength: number.isRequired,
};

export default BlogsSearchResultsPanel;
