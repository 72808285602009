import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
// route
import routeConfiguration from '../../routeConfiguration';
// util
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { pathByRouteName } from '../../util/routes';
// component
import { Modal } from '../../components';
import EmailReminder from './EmailReminder';
import StripeAccountReminder from './StripeAccountReminder';
import LmctVerification from './LmctNotVerified';
// css
import css from './ModalMissingInformation.module.css';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'StripePayoutPage',
];

const LMCT_VERIFICATION = 'LMCT_VERIFICATION';
const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';
const STRIPE_ACCOUNT_DEFAULT_CARD = 'STRIPE_ACCOUNT_DEFAULT_CARD';

class ModalMissingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMissingInformationReminder: props.showMissingInformation
        ? props.showMissingInformation
        : null,
      hasSeenMissingInformationReminder: false,
    };
    this.handleMissingInformationReminder = this.handleMissingInformationReminder.bind(this);
  }

  componentDidUpdate() {
    const {
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      location,
      stripeCustomerFetched,
      path,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const stripeCustomer = currentUser && currentUser.stripeCustomer;
    // if (path === '/l/:slug/:userid/:id') {
    //   if (stripeCustomer) {
    //     this.handleMissingInformationReminder(
    //       user,
    //       currentUserHasListings,
    //       currentUserHasOrders,
    //       location,
    //       stripeCustomer
    //     );
    //   }
    // } else {
    this.handleMissingInformationReminder(
      user,
      currentUserHasListings,
      currentUserHasOrders,
      location
    );
    // }
  }
  handleMissingInformationReminder(
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    newLocation
  ) {
    const { stripeAccount, stripeCustomerFetched, listing } = this.props;
    const routes = routeConfiguration();
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );

    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    const notRemindedYet =
      !this.state.showMissingInformationReminder && !this.state.hasSeenMissingInformationReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted && showOnPathChange) {
      // Emails are sent when order is initiated
      // Customer is likely to get email soon when she books something
      // Provider email should work - she should get an email when someone books a listing
      const hasOrders = currentUserHasOrders === true;
      const hasListingsOrOrders = currentUserHasListings || hasOrders;

      const emailUnverified = !!currentUser.id && !currentUser.attributes.emailVerified;
      const emailVerificationNeeded = hasListingsOrOrders && emailUnverified;

      // stripe verified
      const stripeConnected =
        currentUser && currentUser.id && currentUser.attributes.stripeConnected;
      const getStripeAccountData = stripeAccount =>
        (stripeAccount && stripeAccount.attributes && stripeAccount.attributes.stripeAccountData) ||
        null;

      const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
      const hasRequirements = (stripeAccountData, requirementType) =>
        stripeAccountData != null &&
        stripeAccountData.requirements &&
        Array.isArray(stripeAccountData.requirements[requirementType]) &&
        stripeAccountData.requirements[requirementType].length > 0;

      const requirementsMissing =
        stripeAccount &&
        (hasRequirements(stripeAccountData, 'past_due') ||
          hasRequirements(stripeAccountData, 'currently_due'));

      const savedCountry = stripeAccountData ? stripeAccountData.country : null;
      const showVerificationNeeded = stripeConnected && requirementsMissing;
      const stripeAccountMissing = !!currentUser.id && !currentUser.attributes.stripeConnected;
      const stripeAccountNeeded = currentUserHasListings && stripeAccountMissing;
      const stripeAccountNeededForSeller = stripeAccountMissing;
      const stripeCustomerMissing = !!currentUser.id && !currentUser.stripeCustomer;
      const defaultPaymentMethodMissing = !currentUser?.stripeCustomer?.defaultPaymentMethod;
      // stripe verified
      // default card
      let defaultCardNeeded = false;
      let isOwnListing = null;
      let stripeCustomerDefaultPaymentNeeded = false;
      if (listing && listing?.author?.id?.uuid && currentUser?.id?.uuid && listing?.attributes?.publicData?.listingType === 'bid') {
        const author_id = listing?.author?.id?.uuid;
        const current_user_id = currentUser?.id?.uuid;
        if (author_id === current_user_id) {
          stripeCustomerDefaultPaymentNeeded = false
        } else {
          stripeCustomerDefaultPaymentNeeded = defaultPaymentMethodMissing;
        }
      }
      // const stripeCustomerDefaultPaymentNeeded = !isOwnListing && defaultPaymentMethodMissing;
      const stripeCustomer = currentUser && currentUser.stripeCustomer;
      if (
        this.props.path === '/l/:slug/:userid/:id' &&
        !isOwnListing &&
        stripeCustomerFetched
      ) {
        let defaultCardMissing = false;
        if (stripeCustomer) {
          const defaultPaymentMethod = stripeCustomer && stripeCustomer.defaultPaymentMethod;
          defaultCardMissing = !(
            stripeCustomerFetched &&
            stripeCustomer.attributes.stripeCustomerId &&
            defaultPaymentMethod &&
            defaultPaymentMethod.id
          );
        } else {
          defaultCardMissing = true;
        }
        defaultCardNeeded = defaultCardMissing;
      }
      // default card
      // email veirification
      const emailVerificationNeededForTxn = emailUnverified;
      // email veirification
      // lmct verification
      const lmctUnverified =
        !!currentUser &&
        !!currentUser.id &&
        !!currentUser.attributes.profile &&
        !!currentUser.attributes.profile.publicData &&
        !currentUser.attributes.profile.publicData.lmctVerified;
      // lmct verification
      if (emailVerificationNeeded) {
        this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      } else if (lmctUnverified && this.props.path === '/l/:slug/:userid/:id') {
        this.setState({ showMissingInformationReminder: LMCT_VERIFICATION });
      } else if (emailVerificationNeededForTxn && this.props.path === '/l/:slug/:userid/:id') {
        this.setState({ showMissingInformationReminder: EMAIL_VERIFICATION });
      }
      // else if ((stripeAccountNeeded || showVerificationNeeded || stripeAccountNeededForSeller )&& this.props.path === '/l/:slug/:userid/:id') {
      //   this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT });
      // }
      else if (stripeCustomerDefaultPaymentNeeded && this.props.path === '/l/:slug/:userid/:id') {
        this.setState({ showMissingInformationReminder: STRIPE_ACCOUNT_DEFAULT_CARD });
      } else {
      }
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      currentUser,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onManageDisableScrolling,
      onResendVerificationEmail,
      stripeAccountFetched,
      stripeAccount,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    let content = null;

    const currentUserLoaded = user && user.id;
    if (currentUserLoaded) {
      if (this.state.showMissingInformationReminder === EMAIL_VERIFICATION) {
        content = (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        );
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT) {
        content = <StripeAccountReminder className={classes} />;
      } else if (this.state.showMissingInformationReminder === STRIPE_ACCOUNT_DEFAULT_CARD)
        content = <StripeAccountReminder className={classes} type={STRIPE_ACCOUNT_DEFAULT_CARD} />;
      else if (this.state.showMissingInformationReminder === LMCT_VERIFICATION) {
        content = <LmctVerification />;
      }
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalMissingInformation.closeVerifyEmailReminder" />
    );

    return (
      <React.Fragment>
        {currentUser?.attributes?.profile?.publicData?.isTutorialViewed ? (
          <Modal
            id="MissingInformationReminder"
            containerClassName={containerClassName}
            isOpen={!!this.state.showMissingInformationReminder}
            onClose={() => {
              this.setState({
                showMissingInformationReminder: null,
                hasSeenMissingInformationReminder: true,
              });
            }}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={closeButtonMessage}
          >
            {content}
          </Modal>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,
  path: string,
  stripeCustomerFetched: bool.isRequired,
  stripeCustomer: propTypes.currentUser,
};

ModalMissingInformation.displayName = 'ModalMissingInformation';

export default ModalMissingInformation;
