import React, { Component, useEffect } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError } from '../../components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import css from './EditListingPhotosForm.module.css';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false, showToast: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
    this.handleClose = this.handleClose.bind(this);
  }
  // call on dash warning lights in form edit listing
  onImageUploadHandler(file, type) {
    if (file) {
      let fileName =
        file.name
          .split('.')
          .slice(0, -1)
          .join('.') || file.name + '';
      let fileExtention = `.${file.name.substring(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )}`;
      let fileNameWithoutSpecialChars = fileName.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ''
      );
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        this.setState({ imageUploadRequested: true });
        this.props
          .onImageUpload({
            id: `${fileNameWithoutSpecialChars.concat(
              fileExtention
            )}_${Date.now()}_${type}`,
            file,
          })
          .then(() => {
            this.setState({ imageUploadRequested: false });
          })
          .catch(() => {
            this.setState({ imageUploadRequested: false });
          });
      }
    }
  }

  handleClose = () => {
    this.setState({ showToast: false });
  };

  render() {
    const { listing } = this.props;
    const { publicData } = listing.attributes;
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const {
            publishListingError,
            showListingsError,
            updateListingError,
            uploadImageError,
          } = fetchErrors || {};

          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;
          // const nextServiceDueError =  new Date(publicData.nextServiceDue).toLocaleDateString('en', {
          //   weekday: 'short',
          //   year: 'numeric',
          //   month: '2-digit',
          //   day: 'numeric',
          // }) <
          // new Date().toLocaleDateString('en', {
          //   weekday: 'short',
          //   year: 'numeric',
          //   month: '2-digit',
          //   day: 'numeric',
          // }) ? (
          //   <p className={css.error}>
          //     <FormattedMessage id="EditListingPhotosForm.nextServiceDueError" />
          //   </p>
          // ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(
            this.submittedImages
          );
          const imageArrayHasSameImages = isEqual(
            imageIdsFromProps,
            imageIdsFromPreviousSubmit
          );
          const pristineSinceLastSubmit =
            submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            // nextServiceDueError !== null ? true : false ||
            ready;

          const classes = classNames(css.root, className);

          useEffect(() => {
            if (updated) {
              this.setState({ showToast: true });
            }
          }, [updated]);

          return (
            <>
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedImages = images;
                  handleSubmit(e);
                }}
              >
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                  </p>
                ) : null}
                <AddImages
                  className={css.imagesField}
                  images={images}
                  showBanner={true}
                  thumbnailClassName={css.thumbnail}
                  savedImageAltText={intl.formatMessage({
                    id: 'EditListingPhotosForm.savedImageAltText',
                  })}
                  onRemoveImage={onRemoveImage}
                >
                  <Field
                    id="addImage"
                    name="addImage"
                    accept={ACCEPT_IMAGES}
                    form={null}
                    label={chooseImageText}
                    type="file"
                    disabled={imageUploadRequested}
                  >
                    {fieldprops => {
                      const {
                        accept,
                        input,
                        label,
                        disabled: fieldDisabled,
                      } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`addImage`, file);
                        form.blur(`addImage`);
                        onImageUploadHandler(file, 'addImage');
                      };
                      const inputProps = {
                        accept,
                        id: name,
                        name,
                        onChange,
                        type,
                      };
                      return (
                        <div className={css.addImageWrapper}>
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input
                                {...inputProps}
                                className={css.addImageInput}
                              />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  </Field>

                  <Field
                    component={props => {
                      const { input, meta } = props;
                      return (
                        <div className={css.imageRequiredWrapper}>
                          <input {...input} />
                          <ValidationError fieldMeta={meta} />
                        </div>
                      );
                    }}
                    name="images"
                    type="hidden"
                    validate={composeValidators(
                      nonEmptyArray(imageRequiredMessage)
                    )}
                  />
                </AddImages>
                {uploadImageFailed}

                {publishListingFailed}
                {showListingFailed}
                {/* {nextServiceDueError} */}
                <span className={css.chooseImageTip}>
                  Tip: Choose 2-3 best photos of your car from different angles
                  in a good light that really show off your car.
                </span>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </Form>
              <Snackbar
                open={this.state.showToast}
                autoHideDuration={3000}
                onClose={this.handleClose}
              >
                <Alert onClose={this.handleClose} severity="success">
                  Updated!
                </Alert>
              </Snackbar>
            </>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
