import * as importedSdk from 'sharetribe-flex-sdk';
import * as importedIntegrationSdk from 'sharetribe-flex-integration-sdk';

let exportSdk, exportIntegrationSdk;

const isServer = () => typeof window === 'undefined';

if (isServer()) {
  // Use eval to skip webpack from bundling SDK in Node
  // eslint-disable-next-line no-eval
  exportSdk = eval('require')('sharetribe-flex-sdk');
  exportIntegrationSdk = eval('require')('sharetribe-flex-integration-sdk');
} else {
  exportSdk = importedSdk;
  exportIntegrationSdk = importedIntegrationSdk;
}

const { createInstance, types, transit, util } = exportSdk;

export { createInstance, types, transit, util, exportIntegrationSdk};
