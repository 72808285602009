import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
// config
import config from '../../config';
// util
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
// component
import { NamedLink, ResponsiveImage } from '../../components';
// css
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    lmctUnverified,
    setActiveListing,
    isAuthenticated,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const userid = author?.id?.uuid;
  const sortImageArr = (images = [], ids = []) => {
    let imgArr = [];
    if (images && Array.isArray(images) && images.length > 0) {
      imgArr = images.sort((a, b) => (a.id && a.id.uuid && ids.includes(a.id.uuid) ? -1 : 1));
    }
    return imgArr;
  };

  const listingImageIds =
    publicData && publicData.listingImageIds ? publicData.listingImageIds : null;

  let imagesMain =
    listing.images && listing.images.length > 0
      ? sortImageArr(listing.images, listingImageIds)
      : null;

  let newData = [];
  const imagesData = imagesMain;
  listingImageIds.map(listingImageId => {
    imagesData.map(image => {
      if (listingImageId === image.id.uuid) {
        newData.push(image);
      }
    });
  });
  // console.log('newData', newData);
  const filterArray = (arr1, arr2) => {
    const filtered = arr1.filter(el => {
      return arr2.indexOf(el.id.uuid) === -1;
    });
    return filtered;
  };
  const filterArrayData = filterArray(imagesData, listingImageIds);
  // console.log('filterArrayData', filterArrayData);
  imagesMain = newData.concat(filterArrayData);
  const firstImage = imagesMain && imagesMain.length > 0 ? imagesMain[0] : null;

  const { formattedPrice, priceTitle } = price
    ? priceData(price, intl)
    : { formattedPrice: null, priceTitle: null };
  const listingType = publicData.listingType;
  return (
    <NamedLink
      className={`${classes} listingcard`}
      name="ListingPage"
      params={{ id, userid, slug }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        {listingType === 'valuation' ? (
          <div className={css.imageBadgeWrapper}>
            <span className={css.badge}>Valuation</span>
          </div>
        ) : null}
      </div>
      <div className={css.info}>
        {isAuthenticated && !lmctUnverified && formattedPrice ? (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
        ) : null}
        <div className={css.mainInfo}>
          <div className={css.title}>
            {publicData && publicData.modelYear ? <span>{`${publicData.modelYear} `}</span> : null}
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {/* <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div> */}
        </div>
        {publicData && publicData.kilometers ? (
          <div className={css.sideInfo}>
            <p>{`${publicData.kilometers.toLocaleString()} km`}</p>
          </div>
        ) : null}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  isAuthenticated: false,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  isAuthenticated: bool.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
