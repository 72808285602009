import React, { Component } from 'react';
import { string, bool, arrayOf, array, func, number } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import NotificationsHandlers from '../../util/notifications';
import { required, composeValidators, biddingValue } from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import css from './BookingDatesForm.module.css';
import { currencyConfig } from '../../util/test-data';
import { FieldCurrencyInput } from '../../examples';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      openDialog: false,
      bidPlaced: false,
      bid: '',
      userId: '',
      userName: '',
      bidError: null,
      showLoader: false,
      closeForm: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.bidModalContent = this.bidModalContent.bind(this);
    this.handleBidSubmit = this.handleBidSubmit.bind(this);
    this.notificationsHandlers = new NotificationsHandlers();
  }

  setUser = () => {
    const user = ensureCurrentUser(this.props.currentUser);
    if (user) {
      const userId = user.id && user.id.uuid ? user.id.uuid : '';
      const userName =
        user.attributes && user.attributes.profile ? user.attributes.profile.abbreviatedName : 'AN';
      if (userId) this.setState({ userId, userName });
    }
  };

  componentDidUpdate = () => {
    if (!this.state.userId) {
      this.setUser();
    }
  };

  handleDialogOpen = () => {
    this.setState({ openDialog: true });
  };

  handleDialogClose = () => {
    const { onLoadData } = this.props;
    this.setState({
      openDialog: false,
      bidError: false,
      // bidPlaced: false
    });
    // onLoadData()
    // this.setState({ bidPlaced: false, bidError: false });
  };

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    this.setState({ bidPlaced: false });
    const bid = e.bid || '';

    if (!bid) {
      return false;
    } else {
      this.setState(
        {
          bid,
        },
        () => {
          this.handleDialogOpen();
        }
      );
    }
  }

  handleBidSubmit(form) {
    if (!this.state.userId || !this.state.bid) {
      return false;
    }

    const bidObj = {
      userId: this.state.userId,
      userName: this.state.userName,
      bid: this.state.bid,
      time: +new Date(),
      buyerStatus: 1,
      sellerStatus: 1,
    };

    const formattedBid = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(this.state.bid);

    this.setState({ showLoader: true });
    this.props.onSubmit(
      bidObj,
      (error = false) => {
        if (!error) {
          // end this callback after all bid api run properly
          this.setState({ bidPlaced: true, closeForm: true, showLoader: false, bidError: null });
          if (this.props.authorId && this.props.hostingTitle)
            this.notificationsHandlers.setNotification(this.props.authorId, {
              title: 'Bid Placed',
              type: 'bidPlaced',
              listingId: this.props.match.params.id,
              userId: this.props.match.params.userid,
              slug: this.props.match.params.slug,
              // message: `A user has placed a bid of ${formattedBid} on ${this.props.hostingTitle}`,
              message: `You have a bid for ${this.props.hostingTitle}. The buyer is waiting for your repsonse. Approve Bid to accept this offer`
            });
        } else {
          this.setState({
            bidError: error,
            showLoader: false,
            bidPlaced: false,
          });
        }
        form.change('bid', undefined);
        form.resetFieldState('bid');
      },
      'bid'
    );
  }

  bidModalContent = (bidAmount = '') => {
    const formattedBidAmount = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(bidAmount);
    return this.state.bidPlaced ? (
      <>
        <div className={css.bidPlacedDialogContent}>
          <h4>Great!</h4>
          <p>We'll notify you if the dealer accepts your bid.</p>
        </div>
      </>
    ) : (
      <>
        <h2 className={css.bidConfirmation}>{`Are you sure you want to bid ${
          bidAmount ? formattedBidAmount : ''
        }?`}</h2>
        {this.state.bidError ? <p className={classNames(css.bidConfirmation)}>Something went wrong.</p> : null}
      </>
    );
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      highestBid,
      placedBy,
      priceValue,
      metadata,
      buyNowData,
      hasDefaultPaymentMethod,
      emailUnverified,
      stripeAccountMissing,
      showVerificationNeeded,
      exisitingTxDeclined,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const { buyerStatus, id } = buyNowData ?? {};
    const bidAccepted =
      metadata && metadata.biddingStatus && metadata.biddingStatus == 2 ? true : false;
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
    const currencyConfigAUD = {
      ...currencyConfig,
      currency: 'EUR',
    };
    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            form,
            lmctUnverified,
          } = fieldRenderProps;

          const currentUser = this.props.currentUser;

          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredBid',
          });

          const validateBidMessage = intl.formatMessage({
            id: 'BookingDatesForm.validateBid',
          });
          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const formateBidAmount = value => {
            if (value === undefined) return '';
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, '');
            return new Intl.NumberFormat('en-AU', {
              style: 'currency',
              currency: 'AUD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(onlyNums);
          };
          const parseBidAmount = value => {
            if (value === undefined) return '';
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, '');
            return onlyNums;
          };
          const getCurrentBid = () => {
            const currentBid =
              metadata && metadata.bidding && metadata.bidding[currentUser?.id?.uuid];
            return currentBid?.bid
              ? new Intl.NumberFormat('en-AU', {
                  style: 'currency',
                  currency: 'AUD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(currentBid.bid)
              : null;
          };
          return (
            <>
              <Form
                onSubmit={handleSubmit}
                className={classes}
                enforcePagePreloadFor="CheckoutPage"
                initialvalues={{ bid: null }}
              >
                {loadingSpinnerMaybe}
                {bookingInfoErrorMaybe}
                {!isOwnListing && !bidAccepted && !lmctUnverified && !buyerStatus ? (
                  <>
                    <FieldTextInput
                      className={css.bidInput}
                      type="text"
                      id={`${formId}.bid`}
                      name="bid"
                      placeholder="$"
                      onKeyDown={e =>
                        (e.keyCode === 69 ||
                          e.keyCode === 190 ||
                          e.keyCode === 189 ||
                          e.keyCode === 187) &&
                        e.preventDefault()
                      }
                      validate={composeValidators(
                        required(requiredMessage),
                        biddingValue(validateBidMessage, priceValue, highestBid)
                      )}
                      parse={parseBidAmount}
                      format={formateBidAmount}
                    />
                    {/* <FieldCurrencyInput
                      id="price"
                      name="price"
                      // className={css.bidInput}
                      label={'Price*'}
                      placeholder={'Enter price'}
                      currencyConfig={config.currencyConfig}
                      validate={composeValidators(
                        required(requiredMessage),
                        biddingValue(validateBidMessage, priceValue, highestBid)
                      )}
                      // disabled={true}
                    /> */}
                    {/* bidnow button */}
                    <div className={css.submitButtonClasses}>
                      <PrimaryButton
                        disabled={
                          !hasDefaultPaymentMethod ||
                          emailUnverified ||
                          // stripeAccountMissing ||
                          // showVerificationNeeded ||
                          exisitingTxDeclined
                        }
                        type="submit"
                        className={css.bidNowButton}
                      >
                        <FormattedMessage id="BookingDatesForm.requestToBook" />
                      </PrimaryButton>
                    </div>
                  </>
                ) : null}
                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : bidAccepted || buyerStatus
                        ? 'BookingDatesForm.youCantBidAnymore'
                        : lmctUnverified
                        ? 'BookingDatesForm.lmctUnverified'
                        : !hasDefaultPaymentMethod
                        ? 'BookingDatesForm.defaultCardIsNotAdded'
                        : emailUnverified
                        ? 'BookingDatesForm.emailUnverified'
                        // : stripeAccountMissing
                        // ? 'BookingDatesForm.payoutDetailsMissiong'
                        // : showVerificationNeeded
                        // ? 'BookingDatesForm.stripeVerificationNeeded'
                        : exisitingTxDeclined
                        ? 'BookingDatesForm.exisitingTxDeclined'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
                {getCurrentBid() ? (
                  <div className={css.bidPlacedSubTitle}>
                    <span>{`Your current Bid ${getCurrentBid()}`}</span>
                  </div>
                ) : null}

                {highestBid && placedBy ? (
                  <>
                    <div className={css.bidPlacedSubTitle}>
                      <h3>Current highest bid</h3>
                      <span>
                        {new Intl.NumberFormat('en-AU', {
                          style: 'currency',
                          currency: 'AUD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(highestBid)}
                      </span>
                    </div>
                    <div className={css.bidPlacedSubTitle}>
                      <h3>Placed By:</h3>
                      {this.state.userId && this.state.userId === placedBy ? (
                        <span>You</span>
                      ) : (
                        <span>Someone else</span>
                      )}
                      <span className={css.separator}>•</span>
                      <NamedLink
                        className={css.seeBiddingListLink}
                        name="CommonList"
                        params={{
                          id: this.props.match.params.id,
                          slug: this.props.match.params.slug,
                          userid: this.props.match.params.userid,
                          listType: 'bids',
                        }}
                      >
                        <span>
                          <FormattedMessage id="BookingPanel.seeBiddingList" />
                        </span>
                      </NamedLink>
                    </div>
                  </>
                ) : null}
              </Form>
              <Dialog
                open={this.state.openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.state.showLoader ? null : this.handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>{this.bidModalContent(this.state.bid)}</DialogContent>
                <DialogActions className={css.dialogActionButtonsCenter}>
                  {!this.state.bidPlaced ? (
                    <>
                      <Button
                        onClick={this.state.showLoader ? null : this.handleDialogClose}
                        color="primary"
                        className={classNames(css.actionBtn, css.cancelActionBtn)}
                        disabled={this.state.showLoader}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classNames(css.actionBtn, css.primaryActionBtn, css.btn_main)}
                        onClick={() => (this.state.showLoader ? null : this.handleBidSubmit(form))}
                        color="primary"
                      >
                        {this.state.showLoader ? (
                          <span className={classNames(css.loader, css.threeDots)}></span>
                        ) : (
                          <span>Yes, I'm sure</span>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={classNames(css.actionBtn, css.primaryActionBtn)}
                      onClick={this.handleDialogClose}
                      color="primary"
                    >
                      Okay
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  authorId: string.isRequired,
  hostingTitle: string.isRequired,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  priceValue: number.isRequired,
  isOwnListing: bool,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  hasDefaultPaymentMethod: bool,
  emailUnverified: bool,
  stripeAccountMissing: bool,
  showVerificationNeeded: bool,
  exisitingTxDeclined: bool,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
