import React from 'react';
import classNames from 'classnames';
// util
import { isListingAvailableForAcceptBid } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
// component
import { PrimaryButton } from '../../components';
// css
import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    transaction,
    listing,
  } = props;
  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    declineSaleError.apiErrors &&
    declineSaleError.apiErrors[0] &&
    declineSaleError.apiErrors[0].meta &&
    declineSaleError.apiErrors[0].meta.stripeCode === 'charge_disputed' ? (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.declineSaleFailedDisputed" />
      </p>
    ) : (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.declineSaleFailed" />
      </p>
    )
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {/* <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton> */}
        {isListingAvailableForAcceptBid(listing) ? (
          <PrimaryButton
            inProgress={acceptInProgress}
            disabled={buttonsDisabled}
            onClick={onAcceptSale}
          >
            <FormattedMessage id="TransactionPanel.approveBidButton" />
          </PrimaryButton>
        ) : <FormattedMessage id="TransactionPanel.notAvailableForApproval" />}
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
