import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-melbourne',
    predictionPlace: {
      address: 'Melbourne, Victoria, Australia',
      bounds: new LatLngBounds(new LatLng(-37.5112737225, 145.512528832), new LatLng(-38.433859306, 144.593741856)),
    },
  },
  {
    id: 'default-sydney',
    predictionPlace: {
      address: 'Sydney, New South Wales, Australia',
      bounds: new LatLngBounds(new LatLng(-33.578140996,151.343020992), new LatLng(-34.118344992,150.520928608)),
    },
  },
  {
    id: 'default-brisbane',
    predictionPlace: {
      address: 'Brisbane, Queensland, Australia',
      bounds: new LatLngBounds(new LatLng(-26.996844991,153.31787024), new LatLng(-27.767436998,152.668522848)),
    },
  },
  {
    id: 'default-adelaide',
    predictionPlace: {
      address: 'Adelaide, South Australia, Australia',
      bounds: new LatLngBounds(new LatLng(-34.652564053,138.780189824), new LatLng(-35.348970061,138.44212992)),
    },
  },
  {
    id: 'default-perth',
    predictionPlace: {
      address: 'Perth, Western Australia, Australia',
      bounds: new LatLngBounds(new LatLng(-31.6244855145,116.239023008), new LatLng(-32.675715325,115.617614368)),
    },
  },
];
export default defaultLocations;
