/**
 * A text field with phone number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
import React from 'react';
// util
import {
  verifyPhoneNumber,
  composeValidators,
  required,
} from '../../util/validators';
// component
import { FieldTextInput } from '../../components';
import { format, parse } from './fiFormatter';

const FieldPhoneNumberInput = props => {
  const inputProps = {
    type: 'text',
    format: format,
    parse: parse,
    ...props,
  };

  return (
    <FieldTextInput
      validate={
        props.validaterequired
          ? composeValidators(
              required('Phone number is required'),
              verifyPhoneNumber()
            )
          : verifyPhoneNumber()
      }
      {...inputProps}
    />
  );
};

export default FieldPhoneNumberInput;
