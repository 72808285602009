/**
 * Slice a local number that is in the form
 * of 04 aaaa bbbb
 */
const sliceLocal = numbers => {
  if (numbers.length <= 2) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 2)} ${numbers.slice(2, 6)}`;
  }
  return `${numbers.slice(0, 2)} ${numbers.slice(2, 6)} ${numbers.slice(
    6,
    10
  )}`;
};

/**
 * Slice an international phone number i.e.
 * the part that is followed after a '+' or '00'.
 */
const sliceInternational = (numbers, plus = false) => {
  if (numbers.length <= 2) return numbers;
  if (numbers.length <= 3)
    return `${numbers.slice(0, 2)} ${numbers.slice(2, 3)}`;
  if (numbers.length <= 7)
    return `${numbers.slice(0, 2)} ${numbers.slice(2, 3)} ${numbers.slice(
      3,
      7
    )}`;
  if (numbers.length >= 3)
    return `${numbers.slice(0, 2)} ${numbers.slice(2, 3)} ${numbers.slice(
      3,
      7
    )} ${numbers.slice(7, 11)}`;
};

/**
 * Format a phone number in an Australian
 * mobile phone number format.
 *
 * Uses one of the following formats:
 *
 * +61 4 aaaa bbbb
 * 04 aaaa bbbb
 * 05 aaaa bbbb
 */
export const format = value => {
  if (!value) {
    return '';
  }
  if (typeof value === 'number') value += '';
  const leadingPlus = value.match(/^\+/g);
  const numbers = value.replace(/[^\d]/g, '');

  if (leadingPlus) {
    const plus = leadingPlus[0];
    const sliced = sliceInternational(numbers, true);
    return `${plus}${sliced}`;
  }

  return sliceLocal(numbers);
};

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => (value ? value.replace(/\s/g, '') : '');
