import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import css from './BuyNowForm.module.css';
import {
  txIsTransitionExpire
} from '../../util/transaction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BuyNowFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        submitInProgress,
        invalid,
        formattedInstantPrice,
        price,
        instantPrice,
        title,
        isOwnListing,
        currentUser,
        metadata,
        buyNowData,
        lmctUnverified,
        hasDefaultPaymentMethod,
        emailUnverified,
        stripeAccountMissing,
        showVerificationNeeded,
        exisitingTxDeclined,
      } = fieldRenderProps;

      const { buyerStatus, id } = buyNowData ?? {};
      const bidAccepted =
        metadata && metadata.biddingStatus && metadata.biddingStatus == 2 ? true : false;
      const classes = classNames(rootClassName || css.root, className);
      const [state, setState] = useState({
        openDialog: false,
        bought: false,
        buyError: null,
        showLoader: false,
        userId: '',
      });
      const submitDisabled = invalid || disabled || submitInProgress;

      const handleDialogOpen = () => {
        setState({ ...state, openDialog: true });
      };

      const handleDialogClose = () => {
        setState({
          ...state,
          openDialog: false,
          bought: false,
          buyError: false,
        });
      };

      useEffect(() => {
        if (currentUser) {
          const user = ensureCurrentUser(currentUser);
          if (user) {
            const userId = user.id && user.id.uuid ? user.id.uuid : '';
            if (userId) setState({ ...state, userId });
          }
        }
      }, [currentUser]);

      const buyNowModalContent = () => {
        return state.bought ? (
          <>
            <div className={css.valuationPlacedDialogContent}>
              <h4>Great!</h4>
              <p>Payment for instant buy has been confirmed.</p>
            </div>
          </>
        ) : (
          <>
            <h2 className={css.valuationConfirmation}>{`Buy ${title} for ${
              formattedInstantPrice ? formattedInstantPrice : ''
            }?`}</h2>
            {state.buyError ? (
              <p className={classNames(css.valuationError, css.valuationConfirmation)}>
                Something went wrong.
              </p>
            ) : null}
          </>
        );
      };

      const handleBuySubmit = () => {
        if (!state.userId || !instantPrice) {
          return false;
        }

        const dataObj = {
          userId: state.userId,
          userName: state.userName,
          price: instantPrice,
          time: +new Date(),
          buyerStatus: 1,
          sellerStatus: 1,
        };

        setState({
          ...state,
          showLoader: true,
        });
        props.onSubmit(
          dataObj,
          (error = false) => {
            // console.log('error', error);
            // change this call back to end when listing marked closed
            if (!error) {
              setState({
                ...state,
                showLoader: false,
                bought: true,
                buyError: null,
              });
            } else {
              setState({
                ...state,
                buyError: error,
                showLoader: false,
                bought: false,
              });
            }
          },
          'buyNow'
        );
      };

      return (
        <>
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();
              if (!isOwnListing && buyerStatus && buyerStatus === 1 && state.userId === id) {
                handleBuySubmit();
              } else {
                handleDialogOpen();
              }
            }}
          >
            {!isOwnListing && !lmctUnverified && !buyerStatus && !bidAccepted ? (
              <>
                {/* buynow button */}
                <div className={css.submitButtonClasses}>
                  <PrimaryButton
                    disabled={
                      !hasDefaultPaymentMethod || emailUnverified
                      // ||
                      // stripeAccountMissing ||
                      // showVerificationNeeded
                      // || exisitingTxDeclined
                    }
                    type="submit"
                    className={css.valuationNowButton}
                  >
                    <FormattedMessage id="BookingDatesForm.buyNow" />
                  </PrimaryButton>
                </div>
              </>
            ) : txIsTransitionExpire(props.transaction) && !isOwnListing ? (
              <>
                <div className={css.submitButtonClasses}>
                  <PrimaryButton
                    disabled={
                      !hasDefaultPaymentMethod || emailUnverified
                      // ||
                      // stripeAccountMissing ||
                      // showVerificationNeeded
                      // || exisitingTxDeclined
                    }
                    type="submit"
                    className={css.valuationNowButton}
                  >
                    <FormattedMessage id="BookingDatesForm.buyNow" />
                  </PrimaryButton>
                </div>
              </>
            ) : buyerStatus === 1 || buyerStatus === 0 ? (
              <div className={css.submitButtonClasses}>
                <PrimaryButton
                  disabled={
                    !hasDefaultPaymentMethod || emailUnverified
                    // ||
                    // stripeAccountMissing ||
                    // showVerificationNeeded
                    // || exisitingTxDeclined
                  }
                  type="submit"
                  className={css.valuationNowButton}
                >
                  <FormattedMessage id="BookingDatesForm.buyNow" />
                </PrimaryButton>
              </div>
            ) : null}
            {isOwnListing ||
            lmctUnverified ||
            buyerStatus ||
            bidAccepted ||
            !hasDefaultPaymentMethod ||
            emailUnverified ? (
              // ||
              // showVerificationNeeded
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    // isOwnListing && buyerStatus != 3
                    //   ? 'BookingDatesForm.ownListingBuyNow'
                    //   : lmctUnverified
                    //   ? 'BookingDatesForm.lmctUnverified'
                    //   : bidAccepted
                    //   ? 'BookingDatesForm.youWontBeChargedInfoBuyNow'
                    //   : buyerStatus && buyerStatus != 3 && txIsCompleted(props.transaction)
                    //   ? state.userId === id
                    //     ? 'BookingDatesForm.paymentPending'
                    //     : 'BookingDatesForm.youWontBeChargedInfoBuyNowSold'
                    //   : !hasDefaultPaymentMethod
                    //   ? 'BookingDatesForm.defaultCardIsNotAdded'
                    //   : emailUnverified
                    //   ? 'BookingDatesForm.emailUnverified'
                    //   // : stripeAccountMissing
                    //   // ? 'BookingDatesForm.payoutDetailsMissiong'
                    //   // : showVerificationNeeded
                    //   // ? 'BookingDatesForm.stripeVerificationNeeded'
                    //   : // : exisitingTxDeclined
                    //     // ? 'BookingDatesForm.exisitingTxDeclined'
                    //     'BookingDatesForm.youWontBeChargedInfoBuyNow'
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : lmctUnverified
                      ? 'BookingDatesForm.lmctUnverified'
                      : emailUnverified
                      ? 'BookingDatesForm.emailUnverified'
                      : !hasDefaultPaymentMethod
                      ? 'BookingDatesForm.defaultCardIsNotAdded'
                      : bidAccepted
                      ? 'BookingDatesForm.youWontBeChargedInfoBuyNow'
                      : (buyerStatus === 1 || buyerStatus === 0) && id === currentUser?.id?.uuid
                      ? 'BookingDatesForm.buyNowNotCompleted'
                      : buyerStatus && id === currentUser?.id?.uuid && (buyerStatus !== 0 || buyerStatus !== 1 || buyerStatus !== 3)
                      ? 'BookingDatesForm.buyNowSomethingWentWrong'
                      : 'BookingDatesForm.youWontBeChargedInfoBuyNow'
                  }
                />
              </p>
            ) : null}
          </Form>
          <Dialog
            open={state.openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={state.showLoader ? null : handleDialogClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>{buyNowModalContent()}</DialogContent>
            <DialogActions className={css.dialogActionButtonsCenter}>
              {!state.bought ? (
                <>
                  <Button
                    onClick={state.showLoader ? null : handleDialogClose}
                    color="primary"
                    className={classNames(css.actionBtn, css.cancelActionBtn)}
                    disabled={state.showLoader}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classNames(css.actionBtn, css.primaryActionBtn, css.btn_main)}
                    onClick={() => (state.showLoader ? null : handleBuySubmit())}
                    color="primary"
                  >
                    {state.showLoader ? (
                      <span className={classNames(css.loader, css.threeDots)}></span>
                    ) : (
                      <span>Yes, I'm sure</span>
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  className={classNames(css.actionBtn, css.primaryActionBtn)}
                  onClick={handleDialogClose}
                  color="primary"
                >
                  Okay
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      );
    }}
  />
);

BuyNowFormComponent.defaultProps = { className: null, rootClassName: null };

const { bool, func, string } = PropTypes;

BuyNowFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOwnListing: bool,
  onSubmit: func.isRequired,
  hasDefaultPaymentMethod: bool,
  emailUnverified: bool,
  stripeAccountMissing: bool,
  showVerificationNeeded: bool,
  exisitingTxDeclined: bool,
};

const BuyNowForm = compose(injectIntl)(BuyNowFormComponent);
BuyNowForm.displayName = 'BuyNowForm';

export default BuyNowForm;
