import React from 'react';
import { bool } from 'prop-types';
// component
import DistanceFilterPopup from './DistanceFilterPopup';

const DistanceFilter = props => {
  const { showAsPopup, ...rest } = props;
  return <DistanceFilterPopup {...rest} />
};

DistanceFilter.defaultProps = {
  showAsPopup: false,
};

DistanceFilter.propTypes = {
  showAsPopup: bool,
};

export default DistanceFilter;
