import React from 'react';
import { bool } from 'prop-types';
const { types } = require('sharetribe-flex-sdk');
// util
import { isMetadataTxn } from '../../util/data';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
// css
import css from './BookingBreakdown.module.css';

const { Money } = types;

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    // <FormattedMessage id={providerTotalMessageId} />
    <FormattedMessage id="BookingBreakdown.total" />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  // const totalPrice = isProvider
  //   ? transaction.attributes.payoutTotal
  //   : transaction.attributes.payinTotal;
  let totalPrice = isProvider
  ? transaction.attributes.payinTotal
  : transaction.attributes.payinTotal;
  // transaction is updated before
  if(isMetadataTxn(transaction)) {
    totalPrice = new Money(transaction.attributes.metadata.amount*100*0.02, "AUD")
  }

  const formattedTotalPrice = formatMoney(intl, totalPrice);
  
  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
