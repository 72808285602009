import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FieldTextInput, PrimaryButton } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, FieldCurrencyInput } from '../../components';
import CustomCategorySelectFieldMaybe from '../EditListingDescriptionForm/CustomCategorySelectFieldMaybe';
import config from '../../config';

import css from './ConvertListingForm.module.css';

// ConvertListingForm component
const ConvertListingFormComponent = props => {
  const {
    onChange,
    onSubmit,
    onCancel,
    onFormChange,
    onClear,
    path,
    ...rest
  } = props;

  const handleSubmit = values => {
    const { ...restValues } = values;
    return onSubmit({
      ...restValues,
    });
  };

  const formCallbacks = { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          initialValues,
          listingTypeOptions,
          className,
        } = formRenderProps;
        const classes = classNames(css.root, className);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              handleSubmit(e);
            }}
          >
            <FormSpy onChange={onFormChange} />

            <div className={css.convertListingFormField}>
              <CustomCategorySelectFieldMaybe
                id="listingType"
                name="listingType"
                categories={listingTypeOptions}
                intl={intl}
                path={path}
              />
              {initialValues.price ? (
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.priceInput}
                  label={'Price*'}
                  placeholder={'Enter price'}
                  currencyConfig={config.currencyConfig}
                  validate={required('You need to add a valid price.')}
                  disabled={true}
                />
              ) : null}
              <FieldCurrencyInput
                id="instantPrice"
                name="instantPrice"
                className={css.instantPriceInput}
                label={'Instant Buy Price'}
                placeholder={'What do you want to sell it at?'}
                currencyConfig={config.currencyConfig}
                // validate={required('You need to add a valid price.')}
              />
            </div>
            <p className={css.listingTypeWarn}>
              Note - Once you have converted this listing, valuations for this
              listing will be deleted and you won't be able to reconvert this
              listing again.
            </p>
            <div className={css.bottomWrapper}>
              <PrimaryButton type="submit" className={css.convertButton}>
                Convert
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

ConvertListingFormComponent.defaultProps = {
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

ConvertListingFormComponent.propTypes = {
  id: string.isRequired,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,

  // form injectIntl
  intl: intlShape.isRequired,
};

const ConvertListingForm = injectIntl(ConvertListingFormComponent);

export default ConvertListingForm;
