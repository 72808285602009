import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// util
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
// component
import { ListingLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
// css
import css from './EditListingLocationPanel.module.css';
const { LatLng } = sdkTypes;
class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing, currentUser } = this.props;
    const currentListing = ensureOwnListing(listing);
    const currentUserAttributes =
      (currentUser && currentUser.attributes) ?? null;
    const userLocationPresent =
      (currentUserAttributes &&
        currentUserAttributes.profile &&
        currentUserAttributes.profile.protectedData &&
        currentUserAttributes.profile.protectedData.location) ??
      null;

    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData &&
      publicData.location &&
      publicData.location.address &&
      geolocation;
    const location =
      publicData && publicData.location ? publicData.location : {};
    const { address, displayAddress } = location;
    const signUpGeolocation = userLocationPresent
      ? new LatLng(
          userLocationPresent.origin.lat,
          userLocationPresent.origin.lng
        )
      : null;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, displayAddress },
          }
        : userLocationPresent
        ? {
            search: userLocationPresent.address,
            selectedPlace: {
              address: userLocationPresent.address,
              origin: signUpGeolocation,
              displayAddress: userLocationPresent.displayAddress,
            },
          }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { location } = values;
            const {
              selectedPlace: { address, origin, displayAddress },
            } = location;

            const updateValues = {
              geolocation: origin,
              publicData: {
                location: { address, displayAddress },
              },
            };
            this.setState({
              initialValues: {
                location: {
                  search: address,
                  selectedPlace: { address, origin, displayAddress },
                },
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  const user = state.user;
  const { currentUser } = user;

  return { currentUser };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditListingLocationPanel);
