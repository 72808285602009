import {
  addCarData,
  addStolenCheckData,
  clearCarDataMain,
} from '../../ducks/marketplaceData.duck';
// util
import { storableError } from '../../util/errors';

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});

const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => error => ({
  type: actionType,
  payload: error,
  error: true,
});

export const GET_CAR_DATA_REQUEST =
  'app/EditListingFeaturesPanel.duck/GET_CAR_DATA_REQUEST';
export const GET_CAR_DATA_SUCCESS =
  'app/EditListingFeaturesPanel.duck/GET_CAR_DATA_SUCCESS';
export const GET_CAR_DATA_ERROR =
  'app/EditListingFeaturesPanel.duck/GET_CAR_DATA_ERROR';
export const STOLEN_CAR_CHECK_REQUEST =
  'app/EditListingFeaturesPanel.duck/STOLEN_CAR_CHECK_REQUEST';
export const STOLEN_CAR_CHECK_SUCCESS =
  'app/EditListingFeaturesPanel.duck/STOLEN_CAR_CHECK_SUCCESS';
export const STOLEN_CAR_CHECK_ERROR =
  'app/EditListingFeaturesPanel.duck/STOLEN_CAR_CHECK_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  fetchingCarData: null,
  fetchCarDataError: null,
  fetchingStolenCarCheckData: null,
  fetchStolenCarCheckDataError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GET_CAR_DATA_REQUEST:
      return { ...state, fetchingCarData: true, fetchCarDataError: null };
    case GET_CAR_DATA_SUCCESS:
      return { ...state, fetchingCarData: false, fetchCarDataError: null };
    case GET_CAR_DATA_ERROR:
      return { ...state, fetchingCarData: false, fetchCarDataError: payload };
    case STOLEN_CAR_CHECK_REQUEST:
      return {
        ...state,
        fetchingStolenCarCheckData: true,
        fetchStolenCarCheckDataError: null,
      };
    case STOLEN_CAR_CHECK_SUCCESS:
      return {
        ...state,
        fetchingStolenCarCheckData: false,
        fetchStolenCarCheckDataError: null,
      };
    case STOLEN_CAR_CHECK_ERROR:
      return {
        ...state,
        fetchingStolenCarCheckData: false,
        fetchStolenCarCheckDataError: payload,
      };
    default:
      return state;
  }
}

export const getCarData = requestAction(GET_CAR_DATA_REQUEST);
export const getCarDataSuccess = successAction(GET_CAR_DATA_SUCCESS);
export const getCarDataError = errorAction(GET_CAR_DATA_ERROR);

export const stoleCarCheck = requestAction(STOLEN_CAR_CHECK_REQUEST);
export const stolenCheckDataSuccess = successAction(STOLEN_CAR_CHECK_SUCCESS);
export const stolenCheckError = errorAction(STOLEN_CAR_CHECK_ERROR);

// ================ Thunk ================ //

export function requestFetchCarData(params) {
  return dispatch => {
    dispatch(getCarData(params));
    const urlParam = params.vin
      ? `vin=${params.vin}`
      : `plate=${params.registration}&state=${params.state}`;
    const getCarURL = process.env.REACT_APP_GET_CAR_DATA_API + urlParam;
    return fetch(getCarURL, {
      method: 'GET',
      headers: {
        Authorization: process.env.REACT_APP_BLUE_FLAG_API_KEY,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.result) {
          dispatch(addCarData(response));
          dispatch(getCarDataSuccess(response));
          return response;
        } else {
          throw new Error(
            response.message
              ? response.message
              : 'Please enter correct VIN/Registration Details.'
          );
        }
      })
      .catch(e => {
        dispatch(getCarDataError(storableError(e)));
      });
  };
}

// ================ Thunk ================ //

export function stolenCarCheck(params) {
  return dispatch => {
    dispatch(stoleCarCheck(params));
    const stolenCheckURL = `${process.env.REACT_APP_GET_CAR_STOLEN_CHECK_API}?plate=${params.registration}&state=${params.state}`;
    return fetch(stolenCheckURL, {
      method: 'GET',
      headers: {
        Authorization: process.env.REACT_APP_BLUE_FLAG_API_KEY,
      },
    })
      .then(response => response.json())
      .then(response => {
        dispatch(addStolenCheckData(response));
        dispatch(stolenCheckDataSuccess(response));
        return response;
      })
      .catch(e => dispatch(stolenCheckError(storableError(e))));
  };
}

export function clearCarData() {
  return dispatch => {
    dispatch(clearCarDataMain());
  };
}
