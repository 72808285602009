import {make} from './util/make';
import {model} from './util/model';
import {badges} from './util/badges';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * {
 * id:"test",
 * label:"Keyword",
 * type:"KeywordFilter",
 * group:"primary",
 * queryParamNames:"name"
 * }
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
let filterYearOptions = [];
try {
  let currentYear = Number(new Date().getFullYear());
  for (let i = currentYear - 30; i <= currentYear; i++) {
    filterYearOptions.push({
      key: i,
      label: `${i}`,
    });
  }
} catch (err) {
  filterYearOptions = [
    { key: 2011, label: '2011' },
    { key: 2012, label: '2012' },
    { key: 2013, label: '2013' },
    { key: 2014, label: '2014' },
    { key: 2015, label: '2015' },
    { key: 2016, label: '2016' },
    { key: 2017, label: '2017' },
    { key: 2018, label: '2018' },
    { key: 2019, label: '2019' },
    { key: 2020, label: '2020' },
    { key: 2021, label: '2021' },
  ];
}

export const filters = [
  {
    id: 'make',
    label: 'Make',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_make'],
    config: {
      options: make,
         },
  },
  {
    id: 'model',
    label: 'Model',
    type: 'SelectCustomModelFilter',
    group: 'primary',
    queryParamNames: ['pub_carModel'],
    config: {
      options: model  
    }
  },
  {
    id: 'badge',
    label: 'Badge',
    type: 'SelectCustomBadgeFilter',
    group: 'primary',
    queryParamNames: ['pub_carBadge'],
    config: {
        options: badges
    }
  },
  {
    id: 'from-year',
    label: 'From Year',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_modelYear_from'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: filterYearOptions,
    },
  },
  {
    id: 'to-year',
    label: 'To Year',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_modelYear_to'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: filterYearOptions,
    },
  },
  {
    id: 'body-type',
    label: 'Body Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_bodyType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'hatchback',
          label: 'Hatchback',
        },
        {
          key: 'coupe',
          label: 'Coupe',
        },
        {
          key: 'suv',
          label: 'Suv',
        },
        {
          key: 'minivan',
          label: 'Minivan',
        },
        {
          key: 'truck',
          label: 'Ute',
        },
        {
          key: 'stationwagon',
          label: 'StationWagon',
        },
        {
          key: 'sedan',
          label: 'Sedan',
        },
        {
          key: 'convertible',
          label: 'Convertible',
        },
        {
          key: 'van',
          label: 'Van',
        },
      ],
    },
  },
  {
    id: 'kilometer',
    label: 'Kilometers',
    type: 'DistanceFilter',
    group: 'primary',
    queryParamNames: ['pub_kilometers'],
    config: {},
  },
  {
    id: 'transmission',
    label: 'Transmission',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_transmission'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'manualtransmission', label: 'Manual transmission' },
        { key: 'automatictransmission', label: 'Automatic transmission' },
        // {
        //   key: 'continuouslyvariabletransmission',
        //   label: 'Continuously variable transmission',
        // },
        // {
        //   key: 'semiautomatic',
        //   label: 'Semi-automatic and dual-clutch transmissions',
        // },
      ],
    },
  },
  {
    id: 'fuelType',
    label: 'Fuel Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_fuelType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'petrol', label: 'Petrol' },
        { key: 'diesel', label: 'Diesel' },
        { key: 'lpg', label: 'LPG' },
        { key: 'electric', label: 'Electric' },
      ],
    },
  },
  {
    id: 'drivingWheels',
    label: 'Driving Wheels',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_drivingWheels'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '2 WD' },
        { key: '2', label: '4 WD' },
        { key: '3', label: '6 WD' },
      ],
    },
  },
  {
    id: 'interiorType',
    label: 'Interior Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_interiorType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: '1', label: 'Nylon' },
        // { key: '2', label: 'Polyester' },
        // { key: '3', label: 'Vinyl' },
        // { key: '4', label: 'Faux Leather' },
        // { key: '5', label: 'Leather' },

        { key: '1', label: 'Cloth' },
        { key: '2', label: 'Leather' },
        { key: '3', label: 'Partial Leather' },
      ],
    },
  },
  {
    id: 'numberOfOwners',
    label: 'How many previous owners',
    type: '',
    group: 'primary',
    queryParamNames: ['pub_numberOfOwners'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5+', label: '5+' },
        { key: 'unknown', label: 'Unknown' },
      ],
    },
  },
  {
    id: 'listingType',
    label: 'Listing Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_listingType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'bid', label: 'Auction/Buy Now' },
        { key: 'valuation', label: 'Valuation Required' },
      ],
    },
  },
  {
    id: 'states',
    label: 'State of Registration',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_registrationState'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1', label: 'NSW | New South Wales' },
        { key: '2', label: 'QLD | Queensland' },
        { key: '3', label: 'SA | South Australia' },
        { key: '4', label: 'TAS | Tasmania' },
        { key: '5', label: 'VIC | Victoria' },
        { key: '6', label: 'WA | Western Australia' },
        { key: '7', label: 'ACT | Australian Capital Territory' },
        { key: '8', label: 'NT | Northern Territory' },
      ],
    },
  },
  {
    id: 'keywordType',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {},
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};

export const wordsBuyer = [
  'Car as described', 'Great communication', 'Quick process', 'Invoiced in timely manner', 'Highly recommend', 'Car not as described', 'Thank you'
];

export const wordsSeller = [
  'Great communication', 'Fast payment', 'Excellent experience', 'Could have been better', 'Vehicle picked-up quickly', 'Hassle-free transaction', 'Thank you'
]

export const wordsDefault = [
  'Great communication', 'Excellent experience', 'Could have been better', 'Hassle-free transaction', 'Quick process', 'Highly recommend', 'Thank you'
]