import React, { Component, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
// util
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
// component
import { EditListingConditionForm } from '../../forms';
import { ListingLink } from '../../components';
// css
import css from './EditListingConditionPanel.module.css';

const EditListingConditionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    images,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const [rating, setRating] = useState(publicData.ratings ?? {});
  const frontWheelIds = publicData.frontWheelIds ?? null;
  const rearWheelIds = publicData.rearWheelIds ?? null;
  const panelWorkIds = publicData.panelWorkIds ?? null;
  const painWorkIds = publicData.painWorkIds ?? null;
  const interiorIds = publicData.interiorIds ?? null;
  const windscreenIds = publicData.windscreenIds ?? null;
  const mechanicalIds = publicData.mechanicalIds ?? null;
  const listingImageIds = publicData.listingImageIds ?? null;
  const dashLightImageIds = publicData.dashLightImageIds ?? null;

  const onRatingChange = (newRating, name) => {
    setRating({ ...rating, [name]: newRating });
  };

  const getImageArr = (images = [], type = '', ids = []) => {
    let imgArr = [];
    if (images && Array.isArray(images) && images.length > 0) {
      imgArr = images.filter(imgObj => {
        if (imgObj && imgObj.id) {
          if (
            typeof imgObj.id === 'string' &&
            imgObj.id.split('_')[2] === type
          ) {
            return true;
          } else if (
            typeof imgObj.id === 'object' &&
            imgObj.id.uuid &&
            ids &&
            ids.length > 0 &&
            ids.includes(imgObj.id.uuid)
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return imgArr;
  };

  const getImageIds = (images = []) => {
    let ids = [];
    if (images && images.length > 0 && Array.isArray(images)) {
      images.forEach(imgObj => {
        if (imgObj && imgObj.imageId && imgObj.imageId.uuid) {
          ids.push(imgObj.imageId.uuid);
        } else if (imgObj && imgObj.id && imgObj.id.uuid) {
          ids.push(imgObj.id.uuid);
        }
      });
    }
    return ids;
  };
  const frontWheelImage = getImageArr(images, 'frontWheelImage', frontWheelIds);
  const rearWheelImage = getImageArr(images, 'rearWheelImage', rearWheelIds);
  const panelWorkImage = getImageArr(images, 'panelWorkImage', panelWorkIds);
  const paintWorkImage = getImageArr(images, 'paintWorkImage', painWorkIds);
  const interiorImage = getImageArr(images, 'interiorImage', interiorIds);
  const windscreenImage = getImageArr(images, 'windscreenImage', windscreenIds);
  const mechanicalImage = getImageArr(images, 'mechanicalImage', mechanicalIds);
  const mainImages = getImageArr(images, 'addImage', listingImageIds);
  const dashLightImages = getImageArr(
    images,
    'dashLightImages',
    dashLightImageIds
  );
  const extraImages = [...mainImages, ...dashLightImages];
  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingConditionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingConditionPanel.createListingTitle" />
  );
    
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingConditionForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={{
          frontWheelImage,
          rearWheelImage,
          panelWorkImage,
          paintWorkImage,
          interiorImage,
          windscreenImage,
          mechanicalImage,
        }}
        frontWheelImage={frontWheelImage}
        rearWheelImage={rearWheelImage}
        panelWorkImage={panelWorkImage}
        paintWorkImage={paintWorkImage}
        interiorImage={interiorImage}
        windscreenImage={windscreenImage}
        mechanicalImage={mechanicalImage}
        onImageUpload={onImageUpload}
        ratings={rating}
        onRatingChange={onRatingChange}
        onSubmit={values => {
          const {
            frontWheelImage,
            rearWheelImage,
            panelWorkImage,
            paintWorkImage,
            interiorImage,
            windscreenImage,
            mechanicalImage,
          } = values;

          const updatedValues = {
            images: [
              ...frontWheelImage,
              ...rearWheelImage,
              ...panelWorkImage,
              ...paintWorkImage,
              ...interiorImage,
              ...windscreenImage,
              ...mechanicalImage,
              ...extraImages,
            ],
            publicData: {
              frontWheelIds: getImageIds(frontWheelImage),
              rearWheelIds: getImageIds(rearWheelImage),
              panelWorkIds: getImageIds(panelWorkImage),
              painWorkIds: getImageIds(paintWorkImage),
              interiorIds: getImageIds(interiorImage),
              windscreenIds: getImageIds(windscreenImage),
              mechanicalIds: getImageIds(mechanicalImage),
              ratings: rating,
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </div>
  );
};

EditListingConditionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingConditionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingConditionPanel;
