import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { number, string, func, shape } from 'prop-types';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsHandlers from '../../util/notifications';
import moment from 'moment';
import classNames from 'classnames';
import { Menu, MenuLabel, MenuContent, MenuItem } from '..';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { setNotification, getNotification } from './Notifications.duck';
import InfiniteScroll from 'react-infinite-scroller';
import css from './notifications.module.css';
import { REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import {
  getNextNotificationSuccess,
  readBulkNotificationSuccess,
  readNotificationSuccess,
} from '../../ducks/Auth.duck';

const formatTime = (timestamp = '') => {
  // let date = new Date(timestamp);
  // if (date instanceof Date && !isNaN(date)) {
  //   let isToday =
  //     +new Date().setHours(0, 0, 0, 0) === +date.setHours(0, 0, 0, 0);
  //   if (!isToday) {
  //     date = moment(timestamp).format('hh:mm a');
  //   } else {
  //     let difference = +new Date() - +new Date(timestamp);
  //     const value = Math.floor(difference / (1000 * 60 * 60 * 24));
  //     if (value <= 7) {
  //       let addS = value === 1 ? 's' : '';
  //       date = `${value} day${addS} ago`
  //     } else if (value > 7 && value < 31)
  //     date = `${Math.floor(difference / (1000 * 60 * 60 * 24))} days ago`;
  //     date = moment(timestamp).fromNow();
  //   }
  //   return date;
  // }
  if (timestamp) return moment(timestamp).fromNow();
  return '';
};

const NotificationsComponent = ({
  userId,
  history,
  notificationData,
  fetchingNotifications,
  fetchingNotificationsError,
  setNotification,
  getNotification,
  notificationCount,
  getNotificationError,
  getNotificationInProgress,
  notifications,
  notificationCountFirebase,
  unSeenNotifications,
  allNotifications,
  allNotificationsCount,
  seenIds,
}) => {
  const [state, setState] = useState({
    userId: '',
    count: 0,
    notificationData: {},
    notificationOpen: false,
    readEvent: true,
  });
  const notificationRef = useRef(null);
  const dispatch = useDispatch();
  const manageNotifications = new NotificationsHandlers({ dispatch });

  const getUnreadCount = (notifications = {}) => {
    let count = 0,
      unSeenNoti = {};
    for (const notificationId in notifications) {
      if (!notifications[notificationId].isSeen) {
        count++;
        unSeenNoti[notificationId] = { ...notifications[notificationId] };
      }
    }

    return count;
  };

  const notificationClasses = classNames(css.count, {
    invisible: notificationCountFirebase === 0,
  });

  const handleNotificationHover = (data = {}) => {
    if (userId && !data.isSeen && data.id) {
      dispatch(readNotificationSuccess({ userId, notification_id: data.id }));
      // manageNotifications.readNotification(userId, data.id, changeStatusToSeen(data));
    }
  };

  const changeStatusToSeen = (notificationData = {}) => {
    if (notificationData && typeof notificationData === 'object') {
      notificationData.isSeen = true;
      return notificationData;
    }
    return null;
  };

  const handleNotificationClick = (notificationData = null) => {
    if (notificationData && notificationData.type && history && history.push) {
      switch (notificationData.type) {
        case 'bidPlaced':
          if (notificationData.userId && notificationData.listingId && notificationData.slug)
            history.push(
              createResourceLocatorString(
                'CommonList',
                routeConfiguration(),
                {
                  id: notificationData.listingId,
                  userid: notificationData.userId,
                  slug: notificationData.slug,
                  listType: 'bids',
                },
                {}
              )
            );
          break;
        case '':
          break;
        case 'reviewAdded':
          let type = REVIEW_TYPE_OF_PROVIDER;
          const searchStr = 'A review has been added by a seller for';
          if (notificationData.message.search(searchStr) !== -1) {
            type = REVIEW_TYPE_OF_CUSTOMER;
          }
          if (notificationData.userId)
            history.push(
              createResourceLocatorString(
                'ProfilePage',
                routeConfiguration(),
                {
                  id: notificationData.userId,
                },
                { type }
              )
            );
          break;
        case 'bidAccepted':
          if (notificationData.transaction_id && notificationData.url_type) {
            const componentType =
              notificationData.url_type === 'order' ? 'OrderDetailsPage' : 'SaleDetailsPage';
            history.push(
              createResourceLocatorString(
                componentType,
                routeConfiguration(),
                {
                  id: notificationData.transaction_id,
                },
                {}
              )
            );
          }
          break;
        default:
          return null;
      }
    }
    return null;
  };

  // useEffect(() => {
  //   if (userId && userId != state.userId) {
  //     manageNotifications.getNotifications(userId, setNotification);
  //     setState({ ...state, userId });
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   if (notificationData) {
  //     const count = typeof notificationData === 'object' ? getUnreadCount : 0;
  //     setState({
  //       ...state,
  //       count,
  //       notificationData: notificationData ?? {},
  //     });
  //   }
  // }, [notificationData]);
  const readNotificationHandler = () => {
    setState({ ...state, readEvent: true });
    notifications && notifications.length > 0
      ? notifications.map(notification => {
          if (seenIds.includes(notification.id)) {
            manageNotifications.readNotification(userId, notification.id, notification);
          }
        })
      : null;
  };
  useEffect(() => {
    let ticking = false;
    if (
      notificationRef &&
      notificationRef.current &&
      state.readEvent &&
      seenIds &&
      seenIds.length > 0
    ) {
      addEventListener('click', function(e) {
        if (!ticking) {
          window.requestAnimationFrame(function() {
            setState({ ...state, readEvent: false });
            if (
              notificationRef?.current?.props?.children &&
              !Array.isArray(notificationRef?.current?.props?.children)
            ) {
              readNotificationHandler();
            }
            ticking = false;
          });
          ticking = true;
        }
      });
      addEventListener('touchmove', function(e) {
        if (!ticking) {
          window.requestAnimationFrame(function() {
            setState({ ...state, readEvent: false });
            if (
              notificationRef?.current?.props?.children &&
              !Array.isArray(notificationRef?.current?.props?.children)
            ) {
              readNotificationHandler();
            }
            ticking = false;
          });
          ticking = true;
        }
      });
    }
    return () => {
      if (notificationRef && notificationRef.current && state.readEvent) {
        removeEventListener('click', function(e) {});
        removeEventListener('touchmove', function(e) {});
      }
    };
  }, [userId, readNotificationHandler, notificationRef, state]);
  return (
    <InfiniteScroll
      ref={notificationRef}
      pageStart={0}
      loadMore={() => {
        dispatch(getNextNotificationSuccess());
      }}
      hasMore={allNotificationsCount === 0 ? false : true}
      className={css.notificationMenu}
    >
      <Menu className={css.notificationMenu}>
        <MenuLabel
          className={css.notificationMenuLabel}
          isOpenClassName={css.notificationMenuIsOpen}
          onClick={() => {
            setState({ ...state, notificationOpen: true });
            readNotificationHandler();
          }}
        >
          <NotificationsIcon
            className={css.notificationIcon}
            onClick={() => {
              setState({ ...state, notificationOpen: true });
              readNotificationHandler();
            }}
          />
          {notificationCountFirebase ? (
            <span className={notificationClasses}>{notificationCountFirebase}</span>
          ) : null}
        </MenuLabel>
        <MenuContent className={css.notificationMenuContent} isOpen={state?.notificationOpen}>
          <MenuItem key="notificationsHeader" className={css.notificationHeader}>
            <h3>Notifications</h3>
          </MenuItem>
          {notifications &&
          typeof notifications === 'object' &&
          Object.values(notifications).length > 0 ? (
            Object.values(notifications).map((data, notificationIndex) => (
              <MenuItem
                key={notificationIndex}
                className={classNames(css.notificationItem, {
                  [css.notificationUnread]: !data.isSeen,
                })}
              >
                <div
                  className={css.notificationDetail}
                  onMouseDown={() => handleNotificationClick(data.notification)}
                  onMouseEnter={() => handleNotificationHover(data)}
                  onMouseOver={() => handleNotificationHover(data)}
                  onTouchStart={() => handleNotificationHover(data)}
                  onTouchMove={() => handleNotificationHover(data)}
                >
                  <section className={css.notificationLeftContainer}>
                    <h5>{`${data?.notification?.title}`}</h5>
                    <p className={css.notificationTime}>{formatTime(data.createdAt)}</p>
                  </section>
                  <section className={css.notificationRightContainer}>
                    <p className={css.notificationMsg}>{data?.notification?.message}</p>
                  </section>
                </div>
              </MenuItem>
            ))
          ) : (
            <MenuItem key="noNotifications" className={css.noNotifications}>
              {'No Notifications Found'}
            </MenuItem>
          )}
        </MenuContent>
      </Menu>
    </InfiniteScroll>
  );
};

NotificationsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  count: 0,
  userId: '',
};

NotificationsComponent.propTypes = {
  count: number,
  userId: string.isRequired,
  className: string,
  rootClassName: string,
  setNotification: func.isRequired,
  getNotification: func.isRequired,
  notificationCountFirebase: number.isRequired,
  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    notificationData,
    fetchingNotifications,
    fetchingNotificationsError,
  } = state.Notifications;
  return {
    notificationData,
    fetchingNotifications,
    fetchingNotificationsError,
  };
};

const mapDispatchToProps = dispatch => ({
  getNotification: () => dispatch(getNotification()),
  setNotification: (notifications = {}) => dispatch(setNotification(notifications)),
});

const Notifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsComponent);

export default Notifications;
