import BlogHandlers from '../../util/blogs';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const GET_BLOGS = 'app/Blogs/GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'app/Blogs/GET_BLOGS_SUCCESS';
export const GET_BLOGS_ERROR = 'app/Blogs/GET_BLOGS_ERROR';
export const GET_BLOG_DATA = 'app/Blogs/GET_BLOG_DATA';
export const GET_BLOG_DATA_SUCCESS = 'app/Blogs/GET_BLOG_DATA_SUCCESS';
export const GET_BLOG_DATA_ERROR = 'app/Blogs/GET_BLOG_DATA_ERROR';
export const SET_BLOGS = 'app/BLOGS/SET_BLOGS';
export const SET_BLOG_DATA = 'app/BLOGS/SET_BLOG_DATA';

// ================ Reducer ================ //

const initialState = {
  blogsData: null,
  fetchingBlogs: false,
  fetchingBlogsError: false,
  blogData: null,
  fetchingBlogData: false,
  fetchingBlogDataError: false,
};

export default function blogsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_BLOGS:
      return { ...state, blogsData: { ...payload } };
    case SET_BLOG_DATA:
      return { ...state, blogData: { ...payload } };
    case GET_BLOGS:
      return {
        ...state,
        fetchingBlogs: true,
        fetchingBlogsError: null,
      };
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        fetchingBlogsError: null,
        fetchingBlogs: false,
      };
    case GET_BLOGS_ERROR:
      return {
        ...state,
        fetchingBlogs: false,
        fetchingBlogsError: payload.error
          ? payload.error
          : 'Something went wrong while fetching blogs',
      };
    case GET_BLOG_DATA:
      return {
        ...state,
        fetchingBlogData: true,
        fetchingBlogDataError: null,
      };
    case GET_BLOG_DATA_SUCCESS:
      return {
        ...state,
        fetchingBlogDataError: null,
        fetchingBlogData: false,
      };
    case GET_BLOG_DATA_ERROR:
      return {
        ...state,
        fetchingBlogData: false,
        fetchingBlogDataError: payload.error
          ? payload.error
          : 'Something went wrong while fetching the blog',
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const settingBlogs = blogs => ({
  type: SET_BLOGS,
  payload: blogs,
});

const settingBlogData = blogData => ({
  type: SET_BLOG_DATA,
  payload: blogData,
});

const gettingBlogs = () => ({
  type: GET_BLOGS,
});

const gettingBlogData = () => ({
  type: GET_BLOG_DATA,
});

export const showBlogsError = e => ({
  type: GET_BLOGS_ERROR,
  error: true,
  payload: e,
});
export const showBlogDataError = e => ({
  type: GET_BLOG_DATA_ERROR,
  error: true,
  payload: e,
});
export const fetchBlogsSuccess = () => ({
  type: GET_BLOGS_SUCCESS,
});
export const fetchBlogDataSuccess = () => ({
  type: GET_BLOG_DATA_SUCCESS,
});
export const getBlogs = () => dispatch => {
  dispatch(gettingBlogs());
};

export const getBlogData = () => dispatch => {
  dispatch(gettingBlogData());
};

export const setBlogs = blogs => dispatch => {
  dispatch(settingBlogs(blogs));
};

export const setBlogData = blogData => dispatch => {
  dispatch(settingBlogData(blogData));
};

export const loadData = () => dispatch => {
  const BlogHandlersInstance = new BlogHandlers();
  dispatch(getBlogs());
  return BlogHandlersInstance.getBlogFeed(dispatch)
    .then(() => {
      dispatch(fetchBlogsSuccess());
    })
    .catch(e => {
      dispatch(showBlogsError(storableError(e)));
    });
};

export const loadBlogData = (params, search) => dispatch => {
  const BlogHandlersInstance = new BlogHandlers();
  const id = params.id ?? null;
  if (!id) {
    return dispatch(
      showBlogDataError("We couldn't fetch blog data. Id not found.")
    );
  }
  dispatch(getBlogData());
  return BlogHandlersInstance.getBlogData(dispatch, id)
    .then(() => {
      dispatch(fetchBlogDataSuccess());
    })
    .catch(e => {
      dispatch(showBlogDataError(storableError(e)));
    });
};
