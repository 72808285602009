import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
// css
import css from './CustomRadioInput.module.css';
import radiocss from '../../../components/EditListingHistoryPanel/EditListingHistoryPanel.module.css';

const CustomRadioInput = props => {
  const { noMarginBottom, name, id, intl, formName, value, onChange } = props;
  const inputLabel = intl.formatMessage({
    id: `EditListing${formName}Form.${name}`,
  });
  const classes = classNames(noMarginBottom ? css.noMarginBottom : '', css.features);

  return (
    <div className={classes}>
      <label htmlFor={`${id}.section`}>{inputLabel}</label>
      {/* <div className={css.radioInputOptions} id={`${id}.section`}>
        <FieldRadioButton
          id={`${id}_1`}
          name={name}
          label="Yes"
          value="Yes"
          checked={value === 'Yes' ? true : false}
          className={css.marginRight}
          style={{width: '100%',
            height: '100%'}}
          // onChange={(e) => {
          //   e.preventDefault();
          // }}
        />
        <FieldRadioButton
          id={`${id}_2`}
          name={name}
          label="No"
          value="No"
          checked={value === 'No' || !value ? true : false}
          style={{width: '100%',
          height: '100%'}}
          // onChange={(e) => {
          //   e.preventDefault();
          // }}
        />
      </div>
     */}
      <div className={radiocss.radioButtonsWrapper}>
        <div className={radiocss.radioButtonBox}>
          <Field name={name} value={value} defaultValue={value}>
            {({ input: { value, onChange, ...input } }) => (
              <>
                <input
                  {...input}
                  id={`yes${name}`}
                  type="radio"
                  value={`Yes`}
                  defaultValue={value}
                  defaultChecked={value === 'Yes' ? true : false}
                  name={name}
                  onChange={onChange}
                  className={radiocss.radioButtonInput}
                />
                <label htmlFor={`yes${name}`}>Yes</label>
              </>
            )}
          </Field>
        </div>
        <div className={radiocss.radioButtonBox}>
          <Field name={name} value={value} defaultValue={value}>
            {({ input: { value, onChange, ...input } }) => (
              <>
                <input
                  {...input}
                  id={`no${name}`}
                  type="radio"
                  value={`No`}
                  defaultValue={value}
                  defaultChecked={value === 'No' || !value ? true : false}
                  name={name}
                  onChange={onChange}
                  className={radiocss.radioButtonInput}
                />
                <label htmlFor={`no${name}`}>No</label>
              </>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default CustomRadioInput;
