import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// util
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
// component
import { EditListingFeaturesForm } from '../../forms';
import { requestFetchCarData } from './EditListingFeaturesPanel.duck';
import { ListingLink } from '../../components';
// css
import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'amenities';

const EditListingFeaturesPanelComponent = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    marketPlaceData,
    EditListingFeaturesPanel,
    fetchCarData,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const [featuresState, setFeaturesState] = useState({});
  const [fetchCarDataError, setFetchCarDataError] = useState(
    EditListingFeaturesPanel.fetchCarDataError
  );

  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const onFormChange = value => {
    if (value.active) {
      setFeaturesState({
        ...featuresState,
        [value.active]: value.values[value.active],
      });
    }
  };

  const initialValues = {
    tintedWindow: featuresState.tintedWindow
      ? featuresState.tintedWindow
      : publicData.tintedWindow,
    aftermarketWheels: featuresState.aftermarketWheels
      ? featuresState.aftermarketWheels
      : publicData.aftermarketWheels,
    towBar: featuresState.towBar ? featuresState.towBar : publicData.towBar,
    bluetooth: featuresState.bluetooth
      ? featuresState.bluetooth
      : publicData.bluetooth,
    cargoBlind: featuresState.cargoBlind
      ? featuresState.cargoBlind
      : publicData.cargoBlind,
    heatedSeats: featuresState.heatedSeats
      ? featuresState.heatedSeats
      : publicData.heatedSeats,
    bullBar: featuresState.bullBar ? featuresState.bullBar : publicData.bullBar,
    numberOfKeys: featuresState.numberOfKeys
      ? featuresState.numberOfKeys
      : publicData.numberOfKeys,
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {true ? (
        <EditListingFeaturesForm
          className={css.form}
          name={FEATURES_NAME}
          initialValues={initialValues}
          onSubmit={values => {
            const {
              tintedWindow,
              aftermarketWheels,
              towBar,
              bluetooth,
              cargoBlind,
              heatedSeats,
              bullBar,
              numberOfKeys,
            } = values;

            const updatedValues = {
              publicData: {
                tintedWindow: tintedWindow ?? 'No',
                aftermarketWheels: aftermarketWheels ?? 'No',
                towBar: towBar ?? 'No',
                bluetooth: bluetooth ?? 'No',
                cargoBlind: cargoBlind ?? 'No',
                heatedSeats: heatedSeats ?? 'No',
                bullBar: bullBar ?? 'No',
                numberOfKeys,
              },
            };
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onFormChange={onFormChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      ) : fetchCarDataError ? (
        <FormattedMessage id="EditListingFeaturesPanel.carDataFetchError" />
      ) : null}
    </div>
  );
};

EditListingFeaturesPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
  marketPlaceData: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  fetchCarData: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  marketPlaceData: object.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  return {
    marketPlaceData: state.marketplaceData,
    EditListingFeaturesPanel: state.EditListingFeaturesPanel,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCarData: params => dispatch(requestFetchCarData(params)),
});

const EditListingFeaturesPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditListingFeaturesPanelComponent);

export default EditListingFeaturesPanel;
