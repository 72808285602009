import React, { useRef } from 'react';
import classNames from 'classnames';
import { Button } from '..';
import { PDFExport } from '@progress/kendo-react-pdf';
import moment from 'moment';
// util
import {
  TRANSITION_ACCEPT,
  TRANSITION_COMPLETE,
  TRANSITION_EXPIRE_BOOKING_TIME,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
} from '../../util/transaction';
// css
import css from './TransactionPanel.module.css';
import '../../styles/invoicePdf.css';

// Functional component as a helper to build BookingBreakdown
const Invoice = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    bookingSubTitle,
    formatFee,
    card,
    showCard,
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
  } = props;
  const pdfExportComponent = useRef(null);
  // const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const loaded =
    transaction &&
    transaction.attributes &&
    transaction.attributes.lastTransition &&
    (transaction.attributes.lastTransition === TRANSITION_ACCEPT ||
      transaction.attributes.lastTransition === TRANSITION_COMPLETE ||
      transaction.attributes.lastTransition === TRANSITION_EXPIRE_BOOKING_TIME ||
      transaction.attributes.lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER ||
      transaction.attributes.lastTransition === TRANSITION_REVIEW_2_BY_PROVIDER ||
      transaction.attributes.lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER ||
      transaction.attributes.lastTransition === TRANSITION_REVIEW_2_BY_CUSTOMER ||
      transaction.attributes.lastTransition === TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD ||
      transaction.attributes.lastTransition === TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD ||
      transaction.attributes.lastTransition === TRANSITION_EXPIRE_REVIEW_PERIOD);
  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);
  const handleExportWithComponent = event => {
    pdfExportComponent.current.save();
  };

  return loaded ? (
    // listingTitle === 'Deleted listing' ? (
    //   <DetailCardHeadingsMaybe
    //     showDetailCardHeadings={true}
    //     listingTitle={listingTitle}
    //     subTitle={bookingSubTitle}
    //     location={location}
    //     geolocation={geolocation}
    //     showAddress={false}
    //   />
    // ) 
    // : (
      <>
        {' '}
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          fileName={`invoice_of${transaction.listing.attributes.title}.pdf`}
        >
          <div className={classes}>
            <div className={css.invoiceDataWrapper}>
              <div className={css.nameWrapper}>
                <h2 className={css.carTitle}>{transaction.listing.attributes.title}</h2>
                <p className={css.price}>{bookingSubTitle}</p>
              </div>
              <div className={css.invoiceBodyWrapper}>
                <dl className={css.invoiceDescriptiveList}>
                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Date</dt>
                    <dd>{moment(transaction.attributes.createdAt).format('MMM DD, hh:mm A')}</dd>
                  </div>
                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Transaction Id</dt>
                    <dd>{transaction.id.uuid}</dd>
                  </div>
                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Product Name</dt>
                    <dd>{transaction.listing.attributes.title}</dd>
                  </div>
                  {/* <div className={css.invoiceDescriptiveListItem}>
            <dt>Amount Breakdown</dt>
          </div> */}
                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Total Amount</dt>
                    <dd>{formatFee}</dd>
                  </div>
                  {formatFee ? (
                    <div className={css.invoiceDescriptiveListItem}>
                      <dt>Wholesale It fee*</dt>
                      <dd>{formatFee}</dd>
                    </div>
                  ) : null}
                  {showCard ? (
                    <>
                      <div className={css.invoiceDescriptiveListItem}>
                        <dt>Payment Method</dt>
                        <dd>{card && card.brand}</dd>
                      </div>
                      <div className={css.invoiceDescriptiveListItem}>
                        <dt>
                          Card Last Digits
                          {/* <span className={css.optional}>(optional)</span> */}
                        </dt>
                        <dd>{`**** **** **** ${card && card.last4Digits}`}</dd>
                      </div>
                    </>
                  ) : null}

                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Business Name</dt>
                    <dd>Wholesale It</dd>
                  </div>
                  <div className={css.invoiceDescriptiveListItem}>
                    <dt>Location</dt>
                    <dd>AB-2, New York, USA</dd>
                  </div>
                </dl>
              </div>
            </div>
            {/* <BookingBreakdown
      className={breakdownClasses}
      userRole={transactionRole}
      unitType={config.bookingUnitType}
      transaction={transaction}
      booking={transaction.booking}
      dateType={DATE_TYPE_DATE}
    /> */}
          </div>
        </PDFExport>
        <div className={css.invoiceDownload}>
          {' '}
          <Button className={css.readBtn} onClick={handleExportWithComponent}>
            Download Invoice
          </Button>
        </div>
      </>
    // )
  ) : null;
};

export default Invoice;
