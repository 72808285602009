import firebase from '../firebase';
import { setBlogData, setBlogs } from '../containers/BlogsPage/BlogsPage.duck';

class BlogHandlers {
  constructor() {
    this.db = firebase.database();
    this.getBlogFeed = this.getBlogFeed.bind(this);
    this.getBlogData = this.getBlogData.bind(this);
  }

  getBlogFeed = (dispatch = () => {}) => {
    return new Promise((resolve, reject) => {
      try {
        const blogsRef = this.db.ref('/newsfeed/');
        blogsRef.on('value', snapshot => {
          const blogsData = snapshot.val();
          dispatch(setBlogs(blogsData));
          resolve(blogsData);
        });
      } catch (e) {
        reject(e);
        // handle error
      }
    });
  };

  getBlogData = (dispatch = () => {}, blogId) => {
    return new Promise((resolve, reject) => {
      try {
        if (!blogId) throw new Error('blogId is required');
        const blogRef = this.db.ref('newsfeed/' + blogId + '/');
        blogRef.on('value', snapshot => {
          const blogData = snapshot.val();
          dispatch(setBlogData(blogData));
          resolve(blogData);
        });
      } catch (e) {
        reject(e);
        // handle error
      }
    });
  };
}

export default BlogHandlers;
