import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput } from '../../components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CustomCategorySelectFieldMaybe from '../EditListingDescriptionForm/CustomCategorySelectFieldMaybe';
import css from './EditListingPricingForm.module.css';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => {
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => {
    setShowToast(false);
  };
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          metadata,
          listingTypeOptions,
          gotListingType,
          initialValues,
          updateInProgress,
          onFormChange,
          fetchErrors,
        } = formRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const translationKey = isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const biddingStarted =
          metadata &&
          metadata.bidding &&
          typeof metadata.bidding === 'object' &&
          Object.values(metadata.bidding).length > 0
            ? true
            : false;
        const buyPlaced =
          metadata &&
          metadata.buynow &&
          typeof metadata.buynow === 'object' &&
          Object.values(metadata.buynow).length > 0
            ? true
            : false;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || biddingStarted || buyPlaced;
        const { updateListingError, showListingsError } = fetchErrors || {};

        useEffect(() => {
          if (updated) {
            setShowToast(true);
          }
        }, [updated]);
        const formateAmount = value => {
          if (value === undefined) return '';
          if (!value) return value;
          const onlyNums = value.replace(/[^\d]/g, '');
          return formatMoney(intl, onlyNums);
        };
        const parseAmount = value => {
          if (value === undefined) return '';
          if (!value) return value;
          const onlyNums = value.replace(/[^\d]/g, '');
          return onlyNums;
        };
        return (
          <>
            <Form onSubmit={handleSubmit} className={classes}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.updateFailed" />
                </p>
              ) : null}
              {showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                </p>
              ) : null}
              <FormSpy onChange={onFormChange} />

              {!gotListingType ? (
                <CustomCategorySelectFieldMaybe
                  id="listingType"
                  name="listingType"
                  disabled={true}
                  categories={listingTypeOptions}
                  intl={intl}
                />
              ) : (
                <FieldTextInput
                  id="selectedListingType"
                  name="selectedListingType"
                  label="Listing Type"
                  type="text"
                  className={css.listingTypeDisabled}
                  disabled={true}
                />
              )}
              {initialValues.listingType ? (
                <section className={css.listingTypeContainer}>
                  <p className={css.listingInfo}>
                    {initialValues.listingType === 'bid' ? (
                      <>
                        <span>
                          <strong>{` Auction type - `}</strong> Users will bid on the vehicle. Bids will
                          start from the base price set by you below.{' '}
                        </span>
                        <span>
                          <strong>{` Buy Now - `}</strong> User will be able to directly purchase the
                          vehicle at the price set by you below.
                        </span>
                      </>
                    ) : (
                      <span>
                        <strong> {` Valuation Type - `} </strong> You will be asking other users of the
                        platform to evaluate your vehicle and provide a valuation of your vehicle.
                        This option won't allow others to purchase the vehicle. You will have relist
                        the vehicle with other options.
                      </span>
                    )}
                  </p>
                  <p className={css.listingTypeWarn}>
                    Note - Once you have saved the listing type, you won't be able to change it for
                    the current listing.
                  </p>
                </section>
              ) : null}
              {initialValues.listingType && initialValues.listingType !== 'valuation' ? (
                <>
                  <FieldCurrencyInput
                    id="price"
                    name="price"
                    className={css.priceInput}
                    label={pricePerUnitMessage}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    validate={priceValidators}
                    disabled={biddingStarted || buyPlaced}
                      // parse={parseAmount}
                      // format={formateAmount}
                  />
                   {/* <FieldTextInput
                    className={css.priceInput}
                    type="text"
                    id={`price`}
                    name="price"
                    // placeholder={pricePlaceholderMessage}
                    onKeyDown={e =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189 ||
                        e.keyCode === 187) &&
                      e.preventDefault()
                    }
                    // validate={priceValidators}
                    disabled={biddingStarted || buyPlaced}
                      parse={parseAmount}
                      format={formateAmount}
                  /> */}
                  <FieldCurrencyInput
                    id="instantPrice"
                    name="instantPrice"
                    className={css.instantPriceInput}
                    label={'Instant Buy Price'}
                    placeholder={'What do you want to sell it at?'}
                    currencyConfig={config.currencyConfig}
                    // validate={priceValidators}
                    disabled={biddingStarted || buyPlaced}
                    // parse={parseAmount}
                    // format={formateAmount}
                  />
                </>
              ) : null}
              {biddingStarted ? (
                <p className={css.noUpdate}>
                  You can't update the price anymore because bidding has already started.
                </p>
              ) : buyPlaced ? (
                <p className={css.noUpdate}>
                  You can't update the price anymore because the buy process has already begun.
                </p>
              ) : null}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
            <Snackbar open={showToast} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Updated!
              </Alert>
            </Snackbar>
          </>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
