import React from 'react';
import classNames from 'classnames';
// css
import css from './customLoader.module.css';

const Loader = () => {
  return (
    <div className={`${css.container} ${css.loaderContainer}`}>
      <h3 className={css.loader_main}>
        Loading Listings
        <span className={classNames(css.loader, css.threeDots)}></span>
      </h3>
    </div>
  );
};

export default Loader;
