import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// config
import config from '../../config';
import css from './Logo.module.css';
// assets
import IconLogo from './IconLogo';
import LogoImage from '../Logo/logo.svg';

const Logo = props => {
  const { className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop') {
    return (
      <img
        className={className}
        src={LogoImage}
        alt={config.siteTitle}
        {...rest}
      />
    );
  }

  return <IconLogo className={mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
