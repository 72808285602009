import firebase from 'firebase';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_KEY,
  authDomain: 'spatial-cargo-308802.firebaseapp.com',
  projectId: 'spatial-cargo-308802',
  storageBucket: 'spatial-cargo-308802.appspot.com',
  messagingSenderId: '723131686964',
  appId: '1:723131686964:web:c41d7387d8576219c7ea61',
  measurementId: 'G-53DEYSXW91',
  databaseURL: 'https://spatial-cargo-308802-default-rtdb.firebaseio.com/',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
