import { getNotificationRequest, getNotificationSuccess, readNotificationRequest } from '../ducks/Auth.duck';
import firebase from '../firebase';

class NotificationsHandlers {
  constructor(props = null) {
    this.db = firebase.database();
    this.getNotifications = this.getNotifications.bind(this);
    this.setNotification = this.setNotification.bind(this);
    this.readNotification = this.readNotification.bind(this);
    this.dispatch = props?.dispatch ? props?.dispatch : null
  }

  getNotifications = (userId, setNotification = () => {}) => {
    this.dispatch ? this.dispatch(getNotificationRequest()) : null
    return new Promise((resolve, reject) => {
      try {
        if (!userId) throw new Error('UserId is required');
        const notiRef = this.db.ref('notifications/' + userId + '/');
        notiRef.on('value', snapshot => {
          let notifications = snapshot.val();
          if (notifications && typeof notifications === 'object') {
            notifications = Object.keys(notifications)
              .sort((a, b) =>
                notifications[a].createdAt > notifications[b].createdAt
                  ? -1
                  : notifications[b].createdAt > notifications[a].createdAt
                  ? 1
                  : 0
              )
              .map(key => notifications[key]);
          }
          setNotification(notifications);
          this.dispatch ? notifications && Array.isArray(notifications) ? this.dispatch(getNotificationSuccess(notifications)) : null : null
          resolve(true);
        });
      } catch (e) {
        reject(false);
        // handle error
      }
    });
  };

  setNotification = (userId, notification = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!userId || !Object.keys(notification).length)
          throw new Error('User Id and Notification data are Required');
        let newNotificationsKey = this.db
          .ref('notifications/' + userId + '/')
          .push().key;
        let updates = {},
          notificationData = {
            id: newNotificationsKey,
            createdAt: Date.now(),
            isSeen: false,
            notification,
          };
        updates[
          '/notifications/' + userId + '/' + newNotificationsKey
        ] = notificationData;
        this.db.ref().update(updates, function(error) {
          if (error) {
            // The write failed...
            reject(error);
          } else {
            // Data saved successfully!
            resolve(true);
          }
        });
      } catch (e) {
        reject(false);
        //handle error
      }
    });
  };

  readNotification = (userId, notificationId = '', notificationData = {}) => {
    this.dispatch ? this.dispatch(readNotificationRequest()) : null
    return new Promise(async (resolve, reject) => {
      try {
        if (!userId || !notificationId || !notificationData)
          throw new Error('User Id and Notification are Required');
        let updates = {};
        updates[
          '/notifications/' + userId + '/' + notificationId
        ] = notificationData;
        this.db.ref().update(updates, function(error) {
          if (error) {
            // The write failed...
            reject(error);
          } else {
            // Data saved successfully!
            resolve(true);
          }
        });
      } catch (e) {
        reject(false);
        //handle error
      }
    })
  }
}

export default NotificationsHandlers;
