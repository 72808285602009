import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { fetchTransaction } from '../containers/TransactionPage/TransactionPage.duck';
import { sanitizeEntity } from './sanitize';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_PROPOSE_BOOKING,
  TRANSITION_REQUEST_CARD_PAYMENT,
  txIsAccepted,
  txIsCompleted,
  txIsDelivered,
  txIsTransitionExpire,
  UPDATE_TRANSACTION,
} from './transaction';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = {
    id: null,
    type: 'currentUser',
    attributes: { profile: {} },
    profileImage: {},
  };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;
  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const getBuyerId = data => {
  // data = listing
  if (data?.attributes?.publicData?.listingType === 'bid' && isBuyNow(data?.attributes?.metadata)) {
    return Object.keys(getMetadata(data)).find(element => {
      return (
        getMetadata(data)[element].buyerStatus < 3 && getMetadata(data)[element].sellerStatus < 3
      );
    });
  } else {
    return Object.keys(getMetadata(data)).find(
      element =>
        getMetadata(data)[element].buyerStatus < 3 && getMetadata(data)[element].sellerStatus < 3
    );
  }
};
// transaction related data modulation
export const isMetadata = data => {
  // data = listing
  let obj = null;
  if (data?.attributes?.publicData?.listingType === 'bid' && isBuyNow(data?.attributes?.metadata)) {
    obj = data?.attributes?.metadata?.buynow;
  } else {
    obj = data?.attributes?.metadata?.bidding;
  }
  return obj !== null && obj !== undefined && Object.keys(obj).length !== 0;
};
export const isMetadataTxn = data => {
  // data = listing
  let obj = data.attributes.metadata;
  return obj !== null && obj !== undefined && Object.keys(obj).length !== 0;
};
export const isBuyNow = listing_metadata => {
  return (
    listing_metadata && Object.keys(listing_metadata).find(meta => meta === 'buynow') === 'buynow'
  );
};
export const isListingAvailableForAcceptBid = listing => {
  const metdata = listing && listing.attributes && listing.attributes.metadata;
  const state = listing && listing.attributes && listing.attributes.state;
  if (state && metdata) {
    if (state === 'closed') {
      return false;
    } else if (state === 'published') {
      return true;
    }
  } else {
    return false;
  }
  // return bool
};
export const getMetadata = data => {
  // data = listing
  if (data?.attributes?.publicData?.listingType === 'bid' && isBuyNow(data?.attributes?.metadata)) {
    return data?.attributes?.metadata?.buynow;
  } else {
    return data?.attributes?.metadata?.bidding;
  }
};
export const getTansactionId = data => {
  let transactionId = null;
  const author_id = data.listing.author.id.uuid;
  const current_user_id = data.currentUser.id.uuid;
  if (isMetadata(data.listing)) {
    let buyerId = getBuyerId(data.listing);
    if (author_id === current_user_id) {
      let metaData = getMetadata(data.listing);
      transactionId = metaData[buyerId].buyer_transaction_id;
    }
  }
  return transactionId;
};
export const getLatestTransactionId = (values, dispatch) => {
  // values = metadata.bidding
  const getLatestTransaction = Object.keys(values)
    .map(e =>
      (values[e].buyer_transaction_id && values[e].sellerStatus === 2) ||
      (values[e].buyer_transaction_id && values[e].sellerStatus === 1)
        ? values[e]
        : null
    )
    .filter(e => e !== null);
  return getLatestTransaction[0];
};
export const calculateCommissionAmount = value => {
  if (value * 0.02 <= 40000) {
    return value * 0.02;
  }
  return 40000;
};
export const getAmount = data => {
  // data = listing
  let amount = calculateCommissionAmount(data.attributes.publicData.price);
  if (isMetadata(data)) {
    if (
      data?.attributes?.publicData?.listingType === 'bid' &&
      isBuyNow(data?.attributes?.metadata)
    ) {
      amount = calculateCommissionAmount(data.attributes.publicData.instantPrice);
    } else {
      let buyer_id = Object.keys(getMetadata(data)).find(
        element =>
          getMetadata(data)[element].sellerStatus === 2 &&
          getMetadata(data)[element].buyerStatus === 3
      );
      if (buyer_id) {
        amount = calculateCommissionAmount(getMetadata(data)[buyer_id].bid);
      }
    }
  }
  return amount;
};
export const getActionText = (
  data,
  author_id,
  current_user_id,
  biddingStatus,
  transaction,
  listing = null,
  acceptingBid = null
) => {
  // data = bid object
  const buyerStatus = data.buyerStatus;
  const sellerStatus = data.sellerStatus;
  let action = '';
  switch (buyerStatus) {
    case 1:
      // 1:- user is author and payment expired for seller
      // 2:- user is author and no bid has been accepted yet
      if (
        author_id === current_user_id &&
        txIsTransitionExpire(transaction) &&
        isListingAvailableForAcceptBid(listing)
      ) {
        action = 'Accept bid';
      } else if (
        author_id === current_user_id &&
        biddingStatus !== 2 &&
        isListingAvailableForAcceptBid(listing)
      ) {
        action =
          acceptingBid !== null && acceptingBid !== data.buyer_transaction_id ? null : 'Accept bid';
      } else if (!isListingAvailableForAcceptBid(listing)) {
        action = null;
      } else {
        action = null;
      }
      break;
    case 2:
      if (author_id !== current_user_id) {
        action = 'Pay';
      } else {
        action = null;
      }
      break;
    case 3:
      if (sellerStatus === 3) {
        action = null;
      } else if (
        sellerStatus === 2 &&
        !txIsTransitionExpire(transaction) &&
        author_id === current_user_id
      ) {
        action = 'Pay';
      } else if (txIsTransitionExpire(transaction)) {
        action = null;
      } else {
        action = null;
      }
      break;
    case 4:
      action = null;
    default:
      break;
  }
  return action;
};
export const getStatus = (data, transaction, listing = null, isAnyBidAccepted = null) => {
  // data = bid object
  const buyerStatus = data.buyerStatus;
  const sellerStatus = data.sellerStatus;
  let status = '';
  switch (buyerStatus) {
    case 1:
      // if (listing?.attributes?.state && !isListingAvailableForAcceptBid(listing)) {
      //   status = 'Listing closed';
      // } 
      // else 
      // if (txIsAccepted(transaction) || txIsDelivered(transaction)) {
      //   status = 'Complete';
      // } else 
      if (
        new Date(new Date(data.timeStamp).setDate(new Date(data.timeStamp).getDate() + 2)) <
        new Date()
      ) {
        status = 'Payment expired';
      } else if (isAnyBidAccepted) {
        status = 'Payment declined';
      }
      else {
        status = 'Pending';
      }
      break;
    case 2:
      // if (listing?.attributes?.state && !isListingAvailableForAcceptBid(listing)) {
      //   status = 'Listing closed';
      // } 
      status = 'Approved';
      break;
    case 3:
      // if (listing?.attributes?.state && !isListingAvailableForAcceptBid(listing)) {
      //   status = 'Listing closed';
      // } 
      // else 
      if (sellerStatus === 3) {
        status = 'Complete';
      } else if (txIsTransitionExpire(transaction)) {
        status = 'Payment expired';
      } else {
        status = 'Payment done by buyer';
      }
      break;
    case 4:
      // if (listing?.attributes?.state && !isListingAvailableForAcceptBid(listing)) {
      //   status = 'Listing closed';
      // } 
      status = 'Payment declined';
      break;
    default:
      break;
  }
  return status;
};
export const getTotalAmount = data => {
  // data = listing
  let amount = data.attributes.publicData.price;
  if (isMetadata(data)) {
    if (
      data?.attributes?.publicData?.listingType === 'bid' &&
      isBuyNow(data?.attributes?.metadata)
    ) {
      amount = data.attributes.publicData.instantPrice / 100;
    } else {
      let buyer_id = Object.keys(getMetadata(data)).find(
        element => getMetadata(data)[element].sellerStatus >= 2
      );
      if (buyer_id) {
        amount = getMetadata(data)[buyer_id].bid;
      }
      if (data.buyerId) {
        amount =
          getMetadata(data) &&
          getMetadata(data)[data.buyerId] &&
          getMetadata(data)[data.buyerId].bid;
      }
    }
  }
  return new Money(amount * 100, 'AUD');
};
export const getUpdateListingParams = ({ type, listing, buyerId, transaction_id }) => {
  let updatingObjectName =
    listing?.attributes?.publicData?.listingType === 'bid' &&
    isBuyNow(listing?.attributes?.metadata)
      ? 'buynow'
      : 'bidding';
  if (type === 'buyer') {
    return {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            ...getMetadata(listing)[buyerId],
            buyerStatus: 3,
            buyer_transaction_id: transaction_id,
          },
        },
      },
      id: listing.id,
    };
  } else if (type === 'sellerDecline') {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            ...getMetadata(listing)[buyerId],
            sellerStatus: 4,
            buyerStatus: 4,
            seller_transaction_id: transaction_id,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 4;
    }
    return sellerData;
  } else if (type === UPDATE_TRANSACTION) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            buyerStatus: 1,
            bid: listing.bid,
            buyer_transaction_id: transaction_id,
            id: buyerId,
            timeStamp: +new Date(),
            sellerStatus: 1,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 1;
      sellerData.metadata[updatingObjectName][buyerId].bid = listing.bid;
    } else {
      sellerData.metadata[updatingObjectName][buyerId].price = listing.price;
    }
    return sellerData;
  } else if (type === TRANSITION_REQUEST_CARD_PAYMENT) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            buyerStatus: 1,
            bid: listing.bid,
            buyer_transaction_id: transaction_id,
            id: buyerId,
            timeStamp: +new Date(),
            sellerStatus: 1,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 1;
      sellerData.metadata[updatingObjectName][buyerId].bid = listing.bid;
    } else {
      sellerData.metadata[updatingObjectName][buyerId].price = listing.price;
    }
    return sellerData;
  } else if (type === `${TRANSITION_REQUEST_CARD_PAYMENT}-after-initiate`) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            buyerStatus: 0,
            buyer_transaction_id: transaction_id,
            id: buyerId,
            timeStamp: listing.time,
            bid: listing.bid,
            sellerStatus: 0,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 1;
    }
    return sellerData;
  } else if (type === TRANSITION_CONFIRM_PAYMENT) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            buyerStatus: 1,
            buyer_transaction_id: transaction_id,
            id: buyerId,
            timeStamp: listing.time,
            bid: listing.bid,
            sellerStatus: 1,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 1;
    }
    return sellerData;
  } else if (type === TRANSITION_PROPOSE_BOOKING) {
    return {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            ...getMetadata(listing)[buyerId],
            buyerStatus: 1,
            buyer_transaction_id: transaction_id,
            id: buyerId,
            timeStamp: listing.time,
            bid: listing.bid,
            sellerStatus: 1,
          },
        },
      },
      id: listing.id,
    };
  } else if (type === TRANSITION_ACCEPT) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...getMetadata(listing),
          [buyerId]: {
            ...getMetadata(listing)[buyerId],
            buyerStatus: 3,
            sellerStatus: 3,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 3;
    }
    return sellerData;
  } else if (type === TRANSITION_DECLINE) {
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...listing.metadata
        },
      },
      id: listing.id,
    };
    return sellerData;
  } else {
    const updatedMetadata = getMetadata(listing);
    Object.keys(updatedMetadata).map(user_id => {
      if (user_id !== buyerId) {
        updatedMetadata[user_id] = {
          ...updatedMetadata[user_id],
          sellerStatus: 4,
          buyerStatus: 4,
        };
      }
    });
    let sellerData = {
      metadata: {
        [updatingObjectName]: {
          ...updatedMetadata,
          [buyerId]: {
            ...getMetadata(listing)[buyerId],
            sellerStatus: 3,
            seller_transaction_id: transaction_id,
          },
        },
      },
      id: listing.id,
    };
    if (
      listing?.attributes?.publicData?.listingType === 'bid' &&
      !isBuyNow(listing?.attributes?.metadata)
    ) {
      sellerData.metadata.biddingStatus = 3;
    }
    return sellerData;
  }
};
// transaction related data modulation
