import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  bookingDateRequired,
  verifyCarData,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldDateInput } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import css from './EditListingDescriptionForm.module.css';
import CustomRadioInput from '../../components/Custom/CustomRadioInput';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const identity1 = v => v;
const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => {
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => {
    setShowToast(false);
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          carMakes,
          carModels,
          carBadges,
          className,
          disabled,
          ready,
          handleSubmit,
          onFormChange,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          transmissionOptions,
          bodyTypeOptions,
          updateInProgress,
          fetchErrors,
          registrationStateOptions,
          drivingWheelsOptions,
          interiorOptions,
          fuelTypeOptions,
          initialValues,
          verifyCar,
          carVerified,
          showLoading,
          values,
          colour,
          interiorcolour,
          isRegistered,
          registrationExpiryShow,
        } = formRenderProps;

        const { initialVin, initialRegistration, initialRegistrationState } = initialValues;
        const vinMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.vin',
        });
        const vinMessagePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.vinPlaceholder',
        });
        const vinMessageRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.vinRequired',
        });
        const fieldLabel = name => {
          return intl.formatMessage({
            id: `EditListingDescriptionForm.${name}`,
          });
        };
        const fieldPlaceholderMessage = name => {
          return intl.formatMessage({
            id: `EditListingDescriptionForm.${name}Placeholder`,
          });
        };
        const fieldRequiredMessage = name => {
          return intl.formatMessage({
            id: `EditListingDescriptionForm.${name}Required`,
          });
        };
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        useEffect(() => {
          if (updated) {
            setShowToast(true);
          }
        }, [updated]);

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <FormSpy onChange={onFormChange} />

              {!carVerified ? (
                <>
                  <FieldTextInput
                    id="initialVin"
                    name="initialVin"
                    className={css.description}
                    type="textarea"
                    label={vinMessage}
                    placeholder={vinMessagePlaceholderMessage}
                    validate={composeValidators(
                      required(vinMessageRequiredMessage),
                      verifyCarData('A vin must contain exactly 17 characters')
                    )}
                  />
                  <p className={css.choiceInbetween}>OR</p>
                  <FieldTextInput
                    id="initialRegistration"
                    name="initialRegistration"
                    className={css.features}
                    type="text"
                    label={fieldLabel('initialRegistration')}
                    placeholder={fieldPlaceholderMessage('registration')}
                    maxLength={TITLE_MAX_LENGTH}
                    validate={composeValidators(required(fieldRequiredMessage('registration')))}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="initialRegistrationState"
                    name="initialRegistrationState"
                    categories={registrationStateOptions}
                    intl={intl}
                  />
                </>
              ) : null}

              {carVerified ? (
                <>
                  <FieldTextInput
                    id="vin"
                    name="vin"
                    className={css.description}
                    type="textarea"
                    label={vinMessage}
                    placeholder={vinMessagePlaceholderMessage}
                    validate={composeValidators(
                      required(vinMessageRequiredMessage),
                      verifyCarData('A vin must contain exactly 17 characters')
                    )}
                  />
                  <FieldTextInput
                    id="registration"
                    name="registration"
                    className={css.features}
                    type="text"
                    label={fieldLabel('registration')}
                    placeholder={fieldPlaceholderMessage('registration')}
                    maxLength={TITLE_MAX_LENGTH}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="registrationState"
                    name="registrationState"
                    categories={registrationStateOptions}
                    validateIt={false}
                    intl={intl}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="make"
                    name="make"
                    categories={carMakes}
                    intl={intl}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="carModel"
                    name="carModel"
                    categories={carModels}
                    intl={intl}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="carBadge"
                    name="carBadge"
                    categories={carBadges}
                    validateIt={false}
                    intl={intl}
                  />
                  <FieldDateInput
                    id="modelYear"
                    name="modelYear"
                    useMobileMargins={false}
                    className={css.features}
                    label={fieldLabel('modelYear')}
                    placeholderText="Select model year"
                    format={identity1}
                    labelFormat={'yyyy'}
                    maxDate={new Date()}
                    validate={composeValidators(
                      required(fieldRequiredMessage('modelYear')),
                      bookingDateRequired('Date is not valid')
                    )}
                    views={['year']}
                    onChange={value => {
                      onFormChange({
                        active: 'modelYear',
                        values: { modelYear: new Date(value).getFullYear() },
                      });
                    }}
                    onBlur={() => {}}
                    onFocus={() => {}}
                  />
                  <FieldTextInput
                    id="kilometers"
                    name="kilometers"
                    className={css.features}
                    type="number"
                    label={fieldLabel('kilometers')}
                    placeholder={fieldPlaceholderMessage('kilometers')}
                    maxLength={TITLE_MAX_LENGTH}
                    validate={composeValidators(required(fieldRequiredMessage('kilometers')))}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="transmission"
                    name="transmission"
                    categories={transmissionOptions}
                    intl={intl}
                  />
                  <FieldTextInput
                    id="colour"
                    name="colour"
                    className={css.features}
                    type="text"
                    label={fieldLabel('colour')}
                    placeholder={fieldPlaceholderMessage('colour')}
                    maxLength={TITLE_MAX_LENGTH}
                    onChange={value => {
                      onFormChange({
                        active: 'colour',
                        values: { colour: value.target.value },
                      });
                    }}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="bodyType"
                    name="bodyType"
                    categories={bodyTypeOptions}
                    intl={intl}
                  />
                  <CustomRadioInput
                    id="isRegistered"
                    name="isRegistered"
                    formName="Description"
                    value={isRegistered}
                    validate={composeValidators(required(fieldRequiredMessage('isRegistered')))}
                    onChange={value => {
                      onFormChange({
                        active: 'isRegistered',
                        values: { isRegistered: value },
                      });
                    }}
                    intl={intl}
                  />
                  {registrationExpiryShow ? (
                    <FieldDateInput
                      id="registrationExpiry"
                      name="registrationExpiry"
                      useMobileMargins={false}
                      className={css.features}
                      label={fieldLabel('registrationExpiry')}
                      placeholderText={moment().format('ddd, MMMM D')}
                      format={identity1}
                      validate={composeValidators(
                        required(fieldRequiredMessage('registrationExpiry')),
                        bookingDateRequired('Date is not valid')
                      )}
                      onChange={value => {
                        onFormChange({
                          active: 'registrationExpiry',
                          values: { registrationExpiry: value },
                        });
                      }}
                      onBlur={() => {}}
                      onFocus={() => {}}
                    />
                  ) : null}
                  <FieldTextInput
                    id="enginenumber"
                    name="enginenumber"
                    className={css.features}
                    type="text"
                    label={fieldLabel('enginenumber')}
                    placeholder={fieldPlaceholderMessage('enginenumber')}
                    maxLength={TITLE_MAX_LENGTH}
                  />
                  <FieldDateInput
                    id="buildDate"
                    name="buildDate"
                    useMobileMargins={false}
                    className={css.features}
                    label={fieldLabel('buildDate')}
                    labelFormat={'MM-yyyy'}
                    views={['year', 'month']}
                    placeholderText={moment().format('MMMM YY')}
                    format={identity1}
                    validate={composeValidators(
                      required(fieldRequiredMessage('buildDate')),
                      bookingDateRequired('Date is not valid')
                    )}
                    onChange={value => {
                      onFormChange({
                        active: 'buildDate',
                        values: { buildDate: value },
                      });
                    }}
                    onBlur={() => {}}
                    onFocus={() => {}}
                    maxDate={new Date()}
                  />
                  <FieldDateInput
                    id="complianceDate"
                    name="complianceDate"
                    useMobileMargins={false}
                    className={css.features}
                    label={fieldLabel('complianceDate')}
                    labelFormat={'MM-yyyy'}
                    views={['year', 'month']}
                    placeholderText={moment().format('MMMM YY')}
                    format={identity1}
                    validate={composeValidators(
                      required(fieldRequiredMessage('complianceDate')),
                      bookingDateRequired('Date is not valid')
                    )}
                    onChange={value => {
                      onFormChange({
                        active: 'complianceDate',
                        values: { complianceDate: value },
                      });
                    }}
                    onBlur={() => {}}
                    onFocus={() => {}}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="fuelType"
                    name="fuelType"
                    categories={fuelTypeOptions}
                    intl={intl}
                  />
                  <FieldTextInput
                    id="wheelSize"
                    name="wheelSize"
                    className={css.features}
                    type="number"
                    label={fieldLabel('wheelSize')}
                    placeholder={fieldPlaceholderMessage('wheelSize')}
                    min="0"
                    maxLength={TITLE_MAX_LENGTH}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="drivingWheels"
                    name="drivingWheels"
                    categories={drivingWheelsOptions}
                    intl={intl}
                  />
                  <CustomCategorySelectFieldMaybe
                    id="interiorType"
                    name="interiorType"
                    categories={interiorOptions}
                    intl={intl}
                  />
                  <FieldTextInput
                    id="interiorcolour"
                    name="interiorcolour"
                    className={css.features}
                    type="text"
                    label={fieldLabel('interiorcolour')}
                    placeholder={fieldPlaceholderMessage('interiorcolour')}
                    maxLength={TITLE_MAX_LENGTH}
                    onChange={value => {
                      onFormChange({
                        active: 'interiorcolour',
                        values: { interiorcolour: value.target.value },
                      });
                    }}
                  />
                  {/* <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          /> */}
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </>
              ) : null}
            </Form>
            <Snackbar open={showToast} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Updated!
              </Alert>
            </Snackbar>
            {!carVerified ? (
              <Button
                className={classNames(css.verifyBtn, css.btn_main)}
                disabled={!(initialVin || (initialRegistration && initialRegistrationState))}
                onClick={() => {
                  const value = document.querySelector('#initialVin').value;
                  const registrationValue = document.querySelector('#initialRegistration').value;
                  const state = document.querySelector('input[name="initialRegistrationState"]')
                    .value;
                  verifyCar({
                    vin: value,
                    registration: registrationValue,
                    state,
                  });
                }}
              >
                {showLoading ? (
                  <span className={classNames(css.loader, css.threeDots)}></span>
                ) : (
                  <span>Verify Car</span>
                )}
              </Button>
            ) : null}
          </>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
