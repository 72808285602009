import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const GET_NOTIFICATION_REQUEST = 'app/Auth/GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'app/Auth/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'app/Auth/GET_NOTIFICATION_ERROR';

export const GET_NEXT_NOTIFICATION_REQUEST =
  'app/Auth/GET_NEXT_NOTIFICATION_REQUEST';
export const GET_NEXT_NOTIFICATION_SUCCESS =
  'app/Auth/GET_NEXT_NOTIFICATION_SUCCESS';
export const GET_NEXT_NOTIFICATION_ERROR =
  'app/Auth/GET_NEXT_NOTIFICATION_ERROR';

export const READ_NOTIFICATION_REQUEST = 'app/Auth/READ_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_SUCCESS = 'app/Auth/READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_ERROR = 'app/Auth/READ_NOTIFICATION_ERROR';

export const READ_BULK_NOTIFICATION_REQUEST =
  'app/Auth/READ_BULK_NOTIFICATION_REQUEST';
export const READ_BULK_NOTIFICATION_SUCCESS =
  'app/Auth/READ_BULK_NOTIFICATION_SUCCESS';
export const READ_BULK_NOTIFICATION_ERROR =
  'app/Auth/READ_BULK_NOTIFICATION_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
  getNotificationError: null,
  getNotificationInProgress: false,
  notifications: null,
  notificationCount: 0,
  unSeenNotifications: null,
  getNextNotificationError: null,
  getNextNotificationInProgress: false,
  allNotifications: null,
  allNotificationsCount: null,
  page: 0,
  notificationCountFirebase: 0,
  seenIds: [],
  readNotificationInProgress: false,
  readNotificationError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return {
        ...state,
        logoutInProgress: true,
        loginError: null,
        logoutError: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logoutInProgress: false,
        isAuthenticated: false,
        authScopes: [],
      };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return {
        ...state,
        signupInProgress: true,
        loginError: null,
        signupError: null,
      };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return {
        ...state,
        confirmInProgress: true,
        loginError: null,
        confirmError: null,
      };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        allNotificationsCount: 0,
        getNotificationInProgress: true,
        loginError: null,
        getNotificationError: null,
      };
    case GET_NOTIFICATION_SUCCESS:
      const unSeenNotifications = payload.filter(
        notification => notification?.isSeen !== true
      );
      const pageWiseNotifications = payload.sort((a, b) => b - a).slice(0, 10);
      return {
        ...state,
        getNotificationInProgress: false,
        notifications: pageWiseNotifications,
        allNotifications: payload,
        allNotificationsCount: payload.length,
        page: 0,
        isAuthenticated: true,
        notificationCountFirebase: unSeenNotifications.length,
        unSeenNotifications,
      };
    case GET_NOTIFICATION_ERROR:
      return {
        ...state,
        getNotificationInProgress: false,
        getNotificationError: payload,
      };

    case GET_NEXT_NOTIFICATION_REQUEST:
      return {
        ...state,
        getNextNotificationInProgress: true,
        loginError: null,
        getNextNotificationError: null,
      };
    case GET_NEXT_NOTIFICATION_SUCCESS:
      const nextPage = (state?.page + 1) * 10;
      const nextNotifications = state?.allNotifications
        .sort((a, b) => b - a)
        .slice(0, nextPage);
      return {
        ...state,
        notifications: nextNotifications,
        page: state?.page + 1,
        allNotificationsCount:
          nextNotifications.length === state.allNotificationsCount
            ? 0
            : state.allNotificationsCount,
      };
    case GET_NEXT_NOTIFICATION_ERROR:
      return {
        ...state,
        getNextNotificationInProgress: false,
        getNextNotificationError: payload,
      };

    case READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        readNotificationInProgress: true,
        loginError: null,
        readNotificationError: null,
      };
    case READ_NOTIFICATION_SUCCESS:
      const modifiedNotifications = state?.notifications.map(notification => {
        if (notification.id === payload.notification_id) {
          notification.isSeen = true;
          // const manageNotifications = new NotificationsHandlers();
          // manageNotifications.readNotification(payload.userId, notification.id);
        }
        return notification;
      });
      const unSeenModifiedNotifications = modifiedNotifications.filter(
        notification => notification?.isSeen !== true
      );
      return {
        ...state,
        notifications: modifiedNotifications,
        notificationCountFirebase: unSeenModifiedNotifications.length,
        seenIds: [...state?.seenIds, payload.notification_id],
        readNotificationInProgress: state?.seenIds.length === 0 ? false : true,
      };
    case READ_NOTIFICATION_ERROR:
      return {
        ...state,
        getNextNotificationInProgress: false,
        getNextNotificationError: payload,
      };

    case READ_BULK_NOTIFICATION_REQUEST:
      return {
        ...state,
        getNextNotificationInProgress: true,
        loginError: null,
        getNextNotificationError: null,
      };
    case READ_BULK_NOTIFICATION_SUCCESS:
      const modifiedBulkNotifications = state?.notifications;
      const unSeenModifiedBulkNotifications = modifiedBulkNotifications.filter(
        notification => notification?.isSeen !== true
      );
      return {
        ...state,
        notificationCountFirebase: unSeenModifiedBulkNotifications.length,
      };
    case READ_BULK_NOTIFICATION_ERROR:
      return {
        ...state,
        getNextNotificationInProgress: false,
        getNextNotificationError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({
  type: AUTH_INFO_SUCCESS,
  payload: info,
});

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({
  type: LOGIN_ERROR,
  payload: error,
  error: true,
});

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({
  type: LOGOUT_ERROR,
  payload: error,
  error: true,
});

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({
  type: SIGNUP_ERROR,
  payload: error,
  error: true,
});

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({
  type: CONFIRM_ERROR,
  payload: error,
  error: true,
});

export const getNotificationRequest = () => ({
  type: GET_NOTIFICATION_REQUEST,
});
export const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: payload,
});
export const getNotificationError = error => ({
  type: GET_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});
export const getNextNotificationRequest = () => ({
  type: GET_NEXT_NOTIFICATION_REQUEST,
});
export const getNextNotificationSuccess = payload => ({
  type: GET_NEXT_NOTIFICATION_SUCCESS,
  payload: payload,
});
export const getNextNotificationError = error => ({
  type: GET_NEXT_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});
export const readNotificationRequest = () => ({
  type: READ_NOTIFICATION_REQUEST,
});
export const readNotificationSuccess = payload => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: payload,
});
export const readNotificationError = error => ({
  type: READ_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});
export const readBulkNotificationRequest = () => ({
  type: READ_BULK_NOTIFICATION_REQUEST,
});
export const readBulkNotificationSuccess = payload => ({
  type: READ_BULK_NOTIFICATION_SUCCESS,
  payload: payload,
});
export const readBulkNotificationError = error => ({
  type: READ_BULK_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});
export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const {
    email,
    password,
    firstName,
    lastName,
    dealershipName,
    ...rest
  } = params;

  const createUserParams = isEmpty(rest)
    ? {
        email,
        password,
        firstName,
        lastName,
        publicData: {
          lmctVerified: false,
          isTutorialViewed: false,
          dealershipName: dealershipName ?? null,
        },
      }
    : {
        email,
        password,
        firstName,
        lastName,
        publicData: {
          lmctVerified: false,
          isTutorialViewed: false,
          dealershipName: dealershipName ?? null,
        },
        protectedData: { ...rest },
      };
  console.log('createUserParams', createUserParams);
  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};
