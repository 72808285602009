import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect, FieldTextInput } from '../../components';
import { MenuItem } from '@material-ui/core';

import css from './EditListingDescriptionForm.module.css';

const CustomCategorySelectFieldMaybe = props => {
  const { name, id, categories, intl, validateIt, path, formName } = props;
  let categoryLabel = intl.formatMessage({
    id: `EditListingDescriptionForm.${name}`,
  });
  let categoryPlaceholder = intl.formatMessage({
    id: `EditListingDescriptionForm.${name}Placeholder`,
  });
  let categoryRequired = required(
    intl.formatMessage({
      id: `EditListingDescriptionForm.${name}Required`,
    })
  );
  if(formName) {
     categoryLabel = intl.formatMessage({
      id: `EditListing${formName}Form.${name}`,
    });
     categoryPlaceholder = intl.formatMessage({
      id: `EditListing${formName}Form.${name}Placeholder`,
    });
     categoryRequired = required(
      intl.formatMessage({
        id: `EditListing${formName}Form.${name}Required`,
      })
    );
  }

  return categories ? (
    categoryLabel === 'Listing Type*' && path === "/l/:slug/:userid/:id/list/:listType" ? (
      <FieldTextInput
        className={css.category}
        name={name}
        id={id}
        label={categoryLabel}
        validate={validateIt !== false ? categoryRequired : ''}
        type="text"
      />
    ) : (
      <FieldSelect
        className={css.category}
        name={name}
        id={id}
        label={categoryLabel}
        validate={validateIt !== false ? categoryRequired : ''}
      >
        <MenuItem disabled value="">
          {categoryPlaceholder}
        </MenuItem>
        {categories.map(c => {return c.label ? (
          <MenuItem key={`${c.key}_${c.id ?? ''}`} value={c.key}>
            {c.label}
          </MenuItem>
        ) : null})}
      </FieldSelect>
    )
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
