import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// util
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { FormattedMessage } from '../../util/reactIntl';
// component
import { NamedLink } from '../../components';
// css
import css from './SectionLocations.module.css';
// assets
import helsinkiImage from './images/Car1.jpg';
import rovaniemiImage from './images/Car2.jpg';
import rukaImage from './images/Car3.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery, price, distance, city) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  const priceText = <span className={css.locationName}>{price}</span>;
  const setdistance = <span className={css.locationName}>{distance}</span>;
  const setcity = <span className={css.locationName}>{city}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>

      <div className={css.linkText}>
        <FormattedMessage id="SectionLocations.currency" />
        <FormattedMessage
          id="SectionLocations.price"
          values={{ price: priceText }}
        />

        <FormattedMessage id="SectionLocations.guide" />
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
      <FormattedMessage
        id="SectionLocations.distance"
        values={{ distance: setdistance }}
      />
      <FormattedMessage id="SectionLocations.kms" />

      <FormattedMessage
        id="SectionLocations.city"
        values={{ city: setcity }}
      />
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {/* <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div> */}
      <div className={css.locations}>
        {locationLink(
          '2003 Volkswagen Golf Sport 4th Gen Auto MY03',
          helsinkiImage,
          '?address=Helsinki%2C%20Finland&bounds=60.2978389%2C25.254484899999966%2C59.9224887%2C24.782875800000056&origin=60.16985569999999%2C24.93837910000002',
          4999, 120.000, "Melbourne, Inner Suburbs"
        )}
        {locationLink(
          '2001 Toyota Corolla Ascent Auto',
          rovaniemiImage,
          '?address=Rovaniemi%2C%20Finland&bounds=67.18452510000002%2C27.32667850000007%2C66.1553745%2C24.736871199999996&origin=66.50394779999999%2C25.729390599999988',
          8990, 120.000, "Bayside"
        )}
        {locationLink(
          '2011 Volkswagen Tiguan 103TDI 5N Auto 4MOTION MY11',
          rukaImage,
          '?address=Ruka%2C%20Finland&bounds=66.1704578%2C29.14246849999995%2C66.1614402%2C29.110453699999994&origin=66.16594940000002%2C29.12646110000003',
          17990, 120.000, "Bayside"
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
